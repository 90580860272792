import React, { useState, useEffect } from 'react'
import firebase, { firebaseAuth } from './config/constants'
import Home from './PhotoCards'
import { CircularProgress } from '@material-ui/core'

import './App.scss'

interface VIP {
	id: string;
	data: any;
}

function Verify(): JSX.Element {
	const [allVIPs, setAllVIPs] = useState<VIP[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)

	useEffect(() => {
		setIsLoading(true)
		let _ads: VIP[] = []
		firebase
			.firestore()
			.collection('users')
			.where('active', '==', false)
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					_ads.push({ id: doc.id, data: doc.data() })
				})
			})
			.then(() => {
				setAllVIPs(_ads)
			})
			.then(() => {
				setIsLoading(false)
			})
			.catch((error) => {
				firebase.analytics().logEvent('allvips_verify', {
					category: 'AllVIPs',
					action: 'Error Getting AllVIPs: ',
					error,
				})
			})
	}, [])

	useEffect(() => {
		const trace = firebase.performance().trace('Verify')
		trace.incrementMetric('Verify-View')
	}, [])

	return (
		<div className="App">
			{firebaseAuth().currentUser && !isLoading && allVIPs.length ? (
				<Home id="Home" allVIPs={allVIPs} />
			) : (
				<div id="loader">
					<CircularProgress color="secondary" />
				</div>
			)}
		</div>
	)
}

export default Verify
