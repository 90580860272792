import React, { useState } from 'react'
import DeletePicturePopUp from '../common/DeletePicturePopUp'

const TrashCan = (props) => {
	const [modalVisible, setModalVisible] = useState(false)

	const handleDeleteModal = (status) => {
		setModalVisible(status)
	}

	const handleDelete = async () => {
		// check if this uid has the authority to trash the picture
		if (1 + 1) {
			handleDeleteModal(true)
		}
	}

	return (
		<>
			<div className="delete-container" onClick={handleDelete}>
				<img src="/static/icons/delete-icon.svg" height="30" width="30" alt="Delete" />
			</div>
			<DeletePicturePopUp open={modalVisible} handleDeletePopUp={handleDeleteModal} type={'picture'} action={props.action} />
		</>
	)
}

export default TrashCan
