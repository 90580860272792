import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import firebase from '../../config/constants'
import SelectBox from './SelectBox'

const db = firebase.firestore()

const ReportButton = ({ vipId, uid, name, photo }) => {
	const [visible, setVisible] = useState(false)
	const [confirmLoading, setConfirmLoading] = useState(false)
	const [modalText, setModalText] = useState('')
	const [reportDetails, setReportDetails] = React.useState('')
	const [reportType, setReportType] = React.useState('')

	const showReport = () => {
		setVisible(true)
	}

	const handleOk = () => {
		if (reportDetails && reportType) {
			submitReport()
			setConfirmLoading(true)
			setTimeout(() => {
				setVisible(false)
				setConfirmLoading(false)
				setReportDetails('')
				setReportType('')
				setModalText('')
			}, 3000)
		} else if (reportDetails === '') {
			Modal.error({
				title: 'Error',
				content: 'Please give some details.',
			})
			return
		} else if (reportType === '') {
			Modal.error({
				title: 'Error',
				content: 'Please select a report type.',
			})
			return
		}
	}

	const handleCancel = () => {
		setReportDetails('')
		setReportType('')
		setModalText('')
		setVisible(false)
	}

	const submitReport = () => {
		const data = {
			vipId,
			uid,
			name,
			reportDetails,
			reportType,
			epoch: Date.now(),
			date: new Date().toISOString(),
		}

		const docRef = db.collection('reports').doc()
		docRef
			.set({ ...data }, { merge: true })
			.then(() => {
				setModalText('Thanks for reporting. We will look into it.')
			})
			.then(() => {
				db.collection('feedback')
					.add({
						to: ['victorbagu@gmail.com', 'kevinchinyande@gmail.com'],
						message: {
							subject: 'New Report',
							html: `<u>Report details:</u><br> 
						<b>Advertiser:</b> ${name}<br>
						<b>Date of report:</b> ${new Date().toLocaleDateString()}<br> 
						<b>Report type:</b> ${reportType}<br> 
						<b>Report details:</b> ${reportDetails ?? '*Not supplied'}<br> 
						<b>Reporter ID:</b> ${uid}<br>
						<b>Profile:</b> https://bootyvip.com/profile/${vipId}<br>
						<p><img width="200" src=${process.env.REACT_APP_S3_BASE_URL}+${photo} alt=${name}/></p>`,
						},
					})
					.catch((error) => {
						console.error('Error sending details to BootyVIP.', error)
					})
			})
			.catch((error) => {
				console.error('Error occurred while creating report: ', error)
			})
	}

	return (
		<>
			<Button
				onClick={() => showReport()}
				size="small"
				className="ant-btn"
				style={{
					fontSize: '10px',
					backgroundColor: 'transparent',
					borderColor: '#ec0606',
					color: '#ec0606',
					marginTop: '8px',
				}}
			>
				Report {name ?? 'this advertiser'}
			</Button>
			<Modal
				title={`Report ${name ?? 'this advertiser'}`}
				open={visible}
				onOk={handleOk}
				confirmLoading={confirmLoading}
				onCancel={handleCancel}
			>
				{modalText ? (
					<div>{modalText}</div>
				) : (
					<SelectBox
						reportType={reportType}
						setReportType={setReportType}
						reportDetails={reportDetails}
						setReportDetails={setReportDetails}
					/>
				)}
			</Modal>
		</>
	)
}

export default ReportButton
