import React, { useEffect, useState, useContext } from 'react'
import mobileDetect from 'mobile-detect'
import firebase, { firebaseAuth } from '../config/constants'
import PhotoCards from '../PhotoCards'
import shuffle from '../../utils/shuffle'
import DiscoverFooter from './DiscoverFooter'
import { CircularProgress } from '@material-ui/core'
import RegisterPop from '../common/Modal'
import ViewByLinks from './ViewByLinks'
import SocialMediaLinks from './SocialMediaLinks'
import ViewByMobileText from './ViewByMobileText'
import MoreBooty from './MoreBooty'
import { VIPContext } from '../../context/VIPContext'
import SEO from '../common/SEO'
import '../App.scss'

function Discover() {
	const [allVIPs, , , , countryCode] = useContext(VIPContext)
	const [ads, setAds] = useState([])
	const [availableProfiles, setAvailableProfiles] = useState([])
	const [isMobile, setIsMobile] = useState(false)
	const [myFavourites, setMyFavourites] = useState([])
	const [newProfiles, setNewProfiles] = useState([])
	const [processing, setProcessing] = useState(true)
	const [videoProfiles, setVideoProfiles] = useState([])
	const [modalVisible, setModalVisible] = useState(false)
	// const [online, setOnline] = useState('offline')
	// const [error, setError] = useState('')

	useEffect(() => {
		const isMobile = new mobileDetect(window.navigator.userAgent)
		isMobile.ua.includes('Mobile') && setIsMobile(true)
	}, [])

	useEffect(() => {
		if (allVIPs.length > 0) {
			try {
				const _ads = [...allVIPs]
				setAds(_ads)

				const favs = JSON.parse(localStorage.getItem('favourites'))
				if (favs && favs.length) {
					const list = favs.map((fav) => _ads.filter((meisie) => meisie.id === fav))
					if (list[0].length) {
						JSON.parse(localStorage.getItem('favourites')) && processMyFavourites(_ads)
					}
				}

				trackPage()
				setProcessing(false)
			} catch (error) {
				setProcessing(false)
				firebase.analytics().logEvent('discover_view_error', {
					category: 'Discover-View',
					action: 'FireStore Error',
					label: `Error getting document: ${error}`,
					nonInteraction: true,
				})
				// setError(error.message)
			}
		} else {
			setProcessing(false)
		}
	}, [allVIPs])

	useEffect(() => {
		if (ads.length > 0) {
			const list = ads.filter((meisie) => meisie.data.videoLink !== '' && meisie.data.videoLink !== undefined)
			const shuffledList = shuffle(list)
			setVideoProfiles(shuffledList)
		}
	}, [ads])

	useEffect(() => {
		if (ads.length > 0) {
			const list = ads.filter((meisie) => meisie.data.active)
			const shuffledList = shuffle(list.splice(0, 20))
			setNewProfiles(shuffledList)
		}
	}, [ads])

	useEffect(() => {
		if (ads.length > 0) {
			const list = ads.filter(
				(meisie) =>
					meisie.data.available !== '' && meisie.data.available !== undefined && Date.now() - meisie.data.available < 3600000
			)
			setAvailableProfiles(list)
		}
	}, [ads])

	const trackPage = () => {
		if (firebaseAuth().currentUser) {
			firebase.analytics().setUserId(firebaseAuth().currentUser.uid)
		}

		firebase.analytics().logEvent('discover_view', {
			category: 'Discover-View',
			action: 'Discover',
			label: 'Discover-View',
		})
	}

	const processMyFavourites = async (meisies) => {
		const favs = JSON.parse(localStorage.getItem('favourites'))
		const list = await favs.map((fav, i) => meisies.filter((meisie) => meisie.id === fav)[0])
		if (list.length > 0) {
			setMyFavourites(list)
		}
	}

	const handleRegisterModal = (status) => {
		setModalVisible(status)
	}

	return (
		<>
			<SEO url={window.location.href} title={'Discover'} image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'} />
			<div className="App">
				{isMobile && allVIPs && allVIPs.length > 0 ? <ViewByMobileText totalAdverts={allVIPs.length} /> : null}
				{firebaseAuth().currentUser && !processing ? (
					<>
						{allVIPs && allVIPs.length > 0 && (
							<ViewByLinks
								countryCode={countryCode}
								email={firebaseAuth().currentUser.email}
								allVIPs={allVIPs}
								handleRegisterModal={handleRegisterModal}
							/>
						)}

						{availableProfiles.length > 0 ? (
							<>
								<h5>AVAILABLE RIGHT NOW</h5>
								<PhotoCards id="photocards-available" allVIPs={availableProfiles} />
							</>
						) : null}

						{myFavourites.length > 0 ? (
							<>
								<h5>MY CALL ROSTER</h5>
								<PhotoCards id="photocards-fav" favVIPs={myFavourites} />
							</>
						) : null}

						{newProfiles.length > 0 ? (
							<>
								<h5>NEW BOOTY</h5>
								<PhotoCards id="photocards-new" allVIPs={newProfiles} />
							</>
						) : null}

						<SocialMediaLinks />

						<MoreBooty ads={ads} />

						{videoProfiles.length && firebaseAuth().currentUser.email ? (
							<>
								<h5>VIDEO BOOTY</h5>
								<PhotoCards id="photocards-video" allVIPs={videoProfiles} />
							</>
						) : null}

						{allVIPs && allVIPs.length > 0 && (
							<ViewByLinks
								email={firebaseAuth().currentUser.email}
								allVIPs={allVIPs}
								handleRegisterModal={handleRegisterModal}
							/>
						)}
						<DiscoverFooter />
					</>
				) : (
					<div id="loader">
						<CircularProgress color="secondary" />
					</div>
				)}
				<RegisterPop open={modalVisible} handleRegisterModal={handleRegisterModal} />
			</div>
		</>
	)
}

export default Discover
