import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import firebase from '../config/constants'
import PhotoCards from '../PhotoCards'
import { MoreBootyContext } from '../../context/MoreBootyContext.js'
import { IVip } from '../../interfaces/IVip'

function MoreBooty({ ads }: { ads: IVip[] }): JSX.Element {
	const [moreBootyList] = useContext(MoreBootyContext)
	const [oldProfiles, setOldProfiles] = useState<IVip[]>([])
	const [countryCode, setCountryCode] = useState('ZA')
	const [deletionComplete, setDeletionComplete] = useState(false)
	const [listPopulationComplete, setListPopulationComplete] = useState(false)

	const db = firebase.firestore()
	const moreBootyCollection = db.collection('moreBootyList')

	useEffect(() => {
		const country_code_object = localStorage.getItem('vipPosition')
		if (country_code_object) {
			const country_code = JSON.parse(country_code_object).country_code
			setCountryCode(country_code)
		}
	}, [])

	useEffect(() => {
		if (ads.length > 0 && !listPopulationComplete) {
			const dateNow = Date.now()
			const age = moreBootyList?.data?.created ? moment().diff(moment(moreBootyList?.data?.created), 'hours') : undefined

			try {
				if (moreBootyList === undefined || (age !== undefined && age > 2) || age === undefined) {
					const list: IVip[] = ads.filter((meisie) => meisie.data.active).splice(0, 20)
					let oldProfilesList = []
					let randomIds: number[] = []
					let num = 0

					while (randomIds.length < 20) {
						num = Math.floor(Math.random() * 20)
						if (!randomIds.includes(num)) {
							randomIds.push(num)
							list[num] && oldProfilesList.push(list[num])
						}
					}
					setOldProfiles(oldProfilesList)

					const _countryCode =
						countryCode === 'ZA' || countryCode === 'ZW' || countryCode === 'BW' || countryCode === 'KE' || countryCode === 'TZ'
							? countryCode
							: 'XX'

					moreBootyCollection
						.add({
							list: oldProfilesList,
							created: dateNow,
							countryCode: _countryCode,
						})
						.catch((error) => {
							console.error('Error adding document: ', error)
						})
				} else if (age <= 2) {
					setOldProfiles(moreBootyList?.data?.list)
				}
			} catch (error) {
				console.error('Error populating moreBootyList', error)
			} finally {
				setListPopulationComplete(true)
			}
		}
	}, [ads, countryCode, moreBootyList, moreBootyCollection, listPopulationComplete])

	useEffect(() => {
		if (!deletionComplete) {
			try {
				moreBootyCollection.get().then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						const age = moment().diff(moment(doc.data().created), 'hours')
						if (age && age > 2) {
							moreBootyCollection.doc(doc.id).delete()
						}
					})
				})
			} catch (error) {
				console.log('Error deleting old moreBootys: ', error)
			} finally {
				setDeletionComplete(true)
			}
		}
	}, [moreBootyCollection, deletionComplete])

	return oldProfiles && oldProfiles.length > 0 ? (
		<>
			<h5>MORE BOOTY</h5>
			<PhotoCards id="photocards-morebooty" allVIPs={oldProfiles} />
		</>
	) : (
		<></>
	)
}

export default MoreBooty
