import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

import { loginAnonymously } from '../helpers/auth.js'
import firebase, { firebaseAuth } from '../config/constants'
import '../App.scss'
import { CircularProgress } from '@material-ui/core'
import { VIPContext } from '../../context/VIPContext'
import { IVip } from '../../../src/interfaces/IVip'

import Home from '../PhotoCards'
import SEO from '../common/SEO'

function Men(): JSX.Element {
	const [allVIPs] = useContext(VIPContext)
	const [males, setMales] = useState<IVip[]>([])

	useEffect(() => {
		firebase.auth().onAuthStateChanged((authUser) => {
			if (!authUser) {
				loginAnonymously()
			}
		})
	}, [])

	useEffect(() => {
		if (allVIPs.length > 0) {
			const selectMales = () => {
				const meisies = allVIPs.filter((meisie) => meisie.data.gender === 'Male')
				setMales(meisies)

				firebase.analytics().logEvent('male_view', {
					category: 'Male-View',
					action: 'Male selected',
					label: 'Males',
				})
			}
			selectMales()
		}
	}, [allVIPs])

	return (
		<>
			<SEO url={window.location.href} title={'Men'} image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'} />
			<div className="App">
				{firebaseAuth().currentUser ? (
					<>
						<Link to="/discover">
							<div className="tag" style={{ color: '#fff' }}>
								BACK TO DISCOVER
							</div>
						</Link>
						<h5>Men</h5>
						<Home allVIPs={males} />
					</>
				) : (
					<div id="loader">
						<CircularProgress color="secondary" />
					</div>
				)}
			</div>
		</>
	)
}

export default Men
