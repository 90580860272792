import Example from './example'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

function dnd(props) {
	return (
		<div>
			<DndProvider backend={props.isMobile ? TouchBackend : HTML5Backend} options={{ enableTouchEvents: true }}>
				<Example pics={props.pics} adId={props.adId} />
			</DndProvider>
		</div>
	)
}

export default dnd
