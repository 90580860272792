import React from 'react'

function Sitemap() {
	return (
		<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
			<url>
				<loc>https://bootyvip.co.za/</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.co.za/discover</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.co.za/disclaimer</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.co.za/browse/suburbs</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.co.za/browse/bodies</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.co.za/browse/ethnicities</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.co.za/browse/busts</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.co.za/feedback</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.co.za/advertise</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.co.za/profile/rzjr4pc0zEO6ZEKps9Jd</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/XQxUj4hkS6MQGLcGKqe9</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/JFJAwh2NkS2Aycjm3ng7</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/te418tEO2Cc4ssyCI0l6</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/7sTImiQAXJ6zaaiacr6e</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/cm95TUhs1GYn1YiaLGBB</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/8SO5dPYytfcAO3RHghYQ</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/dh9JablMKFOqJuIwnAn0</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/5aFwH7DEczlcwFXbWtMY</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/RlROduVJR6ITecMoJXbB</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/w2knlloFtVKsofEauoCF</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/E8MTB9Yz9Qzz8zsE72dH</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/S5xY0EoC6z7DB1fAeZRr</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/KOVMmL0y3dtMbCXdFP75</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/iWztDxYyqk2XAwdAdJwn</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/9TAGreGfCEZ3NrPV7XL4</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/65cEibbbiHMUZpWMgcuX</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/3LO6xf1ngk8tRs2h6B8w</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/r1GSTea6AEKkSP1bKSac</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/JSZLLyNOBEK1St6ggpnq</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/Nlbu0NpENddgLuoGVsIw</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/dRKcf4iJSaV848hR7LOA</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/AA55idkXSED9YfF4nQzV</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/p8ZUg3UN7CdH2OGMpXeY</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/zDUhIBYKO32ckcE1P9FJ</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/qvy0y1K9UHz8s73V0lCL</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/O3GbMfsd0xjWJvIpQG4O</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/t7R3jFOHsuvZxPOCcHwJ</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/jPC3NM88roKaCPmHMCwQ</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/k4Tdqmdyjcf891Wxb7K2</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/9p1Bjx6LKMozf4ZNMneS</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/UrTMSanUsb4DmhWImr1f</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/bYcxkwVSbRTwAubIusis</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/FzzXwwUZrCX9Sv5EYFcZ</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/Chf9DzysHjyXwKr3juFN</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/n1iCNdejuTimhhwjMRPb</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/dLjEp58mZCYmLpclPz7H</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/qj1A0gWWfavVEiAJ1En9</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/WX0N82KzQhrnqatn53eV</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/lxrrR6yzcGrxI9SrdWsi</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/MI7sQxJMAKAUFP9RtH21</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/JM8Oia6R8ZkGtXJvTGAM</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/pjvbKGAce0s5g4Nfa4ox</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/m2spuPFKwWSzIQdpMqx5</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/ziISpjFx0ee9vuhqc0s1</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/BuEMo69F2ydYy4ewqcLy</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/2FmXvm37iTu8D0ko2xyL</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/EgkmKSP2FcGwR4TPP0H0</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/Xt5nbRbjP23U4nPJbMtZ</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/iJklXP7sg2RHJfWRn2sY</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/Z9EB3urzT4vdxPjw8sFQ</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/V2jatFJGbR3Nlu0uftbY</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/5fOPhoMGRsjsU9HD4pmM</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/4P1pT6Ylm71reqskZbSH</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/y62QIBemsnPs3zC0Zu86</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/A1mO8iDbE3KSzvp02HCg</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/HSDUVkaqOdtcFV6726Ld</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/rvmNITcCNWJIfZfJTg6H</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.co.za/profile/vrVrDsUH4pOb9Z4fDfgw</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.com/</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.com/discover</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.com/disclaimer</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.com/browse/suburbs</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.com/browse/bodies</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.com/browse/ethnicities</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.com/browse/busts</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.com/feedback</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.com/advertise</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>

			<url>
				<loc>https://bootyvip.com/profile/rzjr4pc0zEO6ZEKps9Jd</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/XQxUj4hkS6MQGLcGKqe9</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/JFJAwh2NkS2Aycjm3ng7</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/te418tEO2Cc4ssyCI0l6</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/7sTImiQAXJ6zaaiacr6e</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/cm95TUhs1GYn1YiaLGBB</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/8SO5dPYytfcAO3RHghYQ</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/dh9JablMKFOqJuIwnAn0</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/5aFwH7DEczlcwFXbWtMY</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/RlROduVJR6ITecMoJXbB</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/w2knlloFtVKsofEauoCF</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/E8MTB9Yz9Qzz8zsE72dH</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/S5xY0EoC6z7DB1fAeZRr</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/KOVMmL0y3dtMbCXdFP75</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/iWztDxYyqk2XAwdAdJwn</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/9TAGreGfCEZ3NrPV7XL4</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/65cEibbbiHMUZpWMgcuX</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/3LO6xf1ngk8tRs2h6B8w</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/r1GSTea6AEKkSP1bKSac</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/JSZLLyNOBEK1St6ggpnq</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/Nlbu0NpENddgLuoGVsIw</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/dRKcf4iJSaV848hR7LOA</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/AA55idkXSED9YfF4nQzV</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/p8ZUg3UN7CdH2OGMpXeY</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/zDUhIBYKO32ckcE1P9FJ</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/qvy0y1K9UHz8s73V0lCL</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/O3GbMfsd0xjWJvIpQG4O</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/t7R3jFOHsuvZxPOCcHwJ</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/jPC3NM88roKaCPmHMCwQ</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/k4Tdqmdyjcf891Wxb7K2</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/9p1Bjx6LKMozf4ZNMneS</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/UrTMSanUsb4DmhWImr1f</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/bYcxkwVSbRTwAubIusis</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/FzzXwwUZrCX9Sv5EYFcZ</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/Chf9DzysHjyXwKr3juFN</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/n1iCNdejuTimhhwjMRPb</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/dLjEp58mZCYmLpclPz7H</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/qj1A0gWWfavVEiAJ1En9</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/WX0N82KzQhrnqatn53eV</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/lxrrR6yzcGrxI9SrdWsi</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/MI7sQxJMAKAUFP9RtH21</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/JM8Oia6R8ZkGtXJvTGAM</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/pjvbKGAce0s5g4Nfa4ox</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/m2spuPFKwWSzIQdpMqx5</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/ziISpjFx0ee9vuhqc0s1</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/BuEMo69F2ydYy4ewqcLy</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/2FmXvm37iTu8D0ko2xyL</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/EgkmKSP2FcGwR4TPP0H0</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/Xt5nbRbjP23U4nPJbMtZ</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/iJklXP7sg2RHJfWRn2sY</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/Z9EB3urzT4vdxPjw8sFQ</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/V2jatFJGbR3Nlu0uftbY</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/5fOPhoMGRsjsU9HD4pmM</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/4P1pT6Ylm71reqskZbSH</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/y62QIBemsnPs3zC0Zu86</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/A1mO8iDbE3KSzvp02HCg</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/HSDUVkaqOdtcFV6726Ld</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/rvmNITcCNWJIfZfJTg6H</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
			<url>
				<loc>https://bootyvip.com/profile/vrVrDsUH4pOb9Z4fDfgw</loc>
				<lastmod>2019-08-12T19:32:34+00:00</lastmod>
				<changefreq>daily</changefreq>
			</url>
		</urlset>
	)
}

export default Sitemap
