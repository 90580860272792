import { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { firebaseAuth } from '../config/constants'
import '../App.scss'
import { VIPContext } from '../../context/VIPContext'
import SEO from '../common/SEO'

function Cities(props) {
	const [allVIPs, , globalVIPs] = useContext(VIPContext)
	const [numberedCities, setNumberedCities] = useState(null)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const activeCities = () => {
			let cities = []
			let allCities = []
			let allData = []

			if (globalVIPs.length > 0) {
				if (props && props.match.params.countrycode) {
					allData = globalVIPs.filter((item) => item.data.country === props.match.params.countrycode)
				} else {
					allData = allVIPs
				}
				allData.map((meisie) => allCities.push(meisie.data.city))
				allCities.sort()

				let current = null
				let cnt = 0
				for (let i = 0; i < allCities.length + 1; i++) {
					if (allCities[i] !== current) {
						if (cnt > 0) {
							cities.push(`${current} (${cnt})`)
						}
						current = allCities[i]
						cnt = 1
					} else {
						cnt++
					}
				}
				setNumberedCities([...cities])
				setLoading(false)
			} else {
				setNumberedCities([])
				setLoading(false)
			}
		}

		activeCities()
	}, [allVIPs, globalVIPs, props])

	return (
		<>
			<SEO url={window.location.href} title={'View by City'} image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'} />
			<div className="App">
				{loading && !firebaseAuth().currentUser ? (
					<div id="loader">
						<CircularProgress color="secondary" />
					</div>
				) : firebaseAuth().currentUser ? (
					<>
						<Link to="/discover">
							<div className="tag" style={{ color: '#fff' }}>
								BACK TO DISCOVER
							</div>
						</Link>
						<h5>Cities</h5>
						{numberedCities && numberedCities.length > 0 ? (
							numberedCities.map((city, i) => (
								<Link to={encodeURI(`/browse/city/${city.split(' (')[0]}`)} key={i}>
									<div className="tag">{city}</div>
								</Link>
							))
						) : !loading && numberedCities && numberedCities.length === 0 ? (
							<div>We are busy verifying listings for this city. Please check again soon.</div>
						) : (
							<div id="loader">
								<CircularProgress color="secondary" />
							</div>
						)}
					</>
				) : !firebaseAuth().currentUser ? (
					<Link to="/discover">
						<div className="tag" style={{ color: '#fff' }}>
							BACK TO DISCOVER
						</div>
					</Link>
				) : null}
			</div>
		</>
	)
}

export default Cities
