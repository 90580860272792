import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import DOMPurify from 'dompurify'
import firebase from './config/constants'
import { firebaseAuth } from './config/constants'
import { loginAnonymously } from './helpers/auth.js'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Formik } from 'formik'
import { Button, Input, Form } from 'antd'
import 'react-toastify/dist/ReactToastify.css'
import initialValues from '../constants/initialFeedbackValues'
import SEO from './common/SEO'
import logError from '../utils/logError'
import { CircularProgress } from '@material-ui/core'

toast.configure()

const FormItem = Form.Item
const { TextArea } = Input
const db = firebase.firestore()

interface FeedbackValues {
	name: string;
	message: string;
	mobile: string;
	date: string;
	uid: string;
}

const validationSchema = Yup.object().shape({
	name: Yup.string().required('Name is required'),
	message: Yup.string().required('Message required'),
	mobile: Yup.string(),
})

function Feedback(): JSX.Element {
	const [formValues, setFormValues] = useState<FeedbackValues>(initialValues)
	const [disabled, setDisabled] = useState<boolean>(false)
	const [mobile, setMobile] = useState<string>('')
	const [uid, setUid] = useState<string | null>(null)

	const history = useHistory()

	const emailList = useMemo(() => {
		const list = process.env.REACT_APP_80074_MAILLIST
		if (list) {
			return list.includes(',') ? list.split(',') : [list]
		}
		return []
	}, [])

	const addFeedback = useCallback(
		(postData: FeedbackValues) => {
			if (emailList.length === 0) {
				toast('Error creating feedback. Refresh and try again.')
				logError(`Error PostFeedback: Empty email list`, 'Feedback', 'addFeedback')
				return
			}

			setDisabled(true)

			try {
				db.collection('feedback')
					.add({
						to: emailList,
						message: {
							subject: 'VIP User Feedback',
							html: `<b>Name:</b> ${DOMPurify.sanitize(postData.name)}<br>
							<b>Mobile Number:</b> ${DOMPurify.sanitize(postData.mobile)}<br>
							<b>Message:</b> ${DOMPurify.sanitize(postData.message)}<br>
							<b>Date:</b> ${postData.date}<br>
							<b>UID:</b> ${postData.uid}<br>`,
						},
					})
					.then(() => {
						const trace = firebase.performance().trace('Feedback')
						trace.incrementMetric('PostFeedback')
						toast('Thank you. We value your input.')
						setFormValues(initialValues)
						setDisabled(false)
						history.push('/discover')
					})
			} catch (error) {
				toast('Error creating feedback. Refresh and try again.')
				firebase.analytics().logEvent('post_feedback', {
					category: 'PostFeedback',
					action: 'Error PostFeedback: ',
					error,
				})
				logError(`Error PostFeedback: ${error}`, 'Feedback', 'addFeedback')
				setDisabled(false)
			}
		},
		[emailList, history]
	)

	useEffect(() => {
		const trace = firebase.performance().trace('Feedback')
		trace.incrementMetric('PostFeedback')
	}, [])

	useEffect(() => {
		const authUnsubscribe = firebaseAuth().onAuthStateChanged((authUser) => {
			if (authUser) {
				setUid(authUser.uid)
			} else {
				loginAnonymously()
			}
		})

		return () => {
			authUnsubscribe()
			firebase.analytics().logEvent('post_feedback', {
				category: 'PostFeedback',
				action: 'Unmount PostFeedback',
			})
		}
	}, [])

	const updateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target
		if (name === 'mobile') {
			setMobile(value)
		}
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		},
	}
	const tailFormItemLayout = {
		wrapperCol: {
			xs: {
				span: 24,
				offset: 0,
			},
			sm: {
				span: 16,
				offset: 8,
			},
		},
	}

	return uid ? (
		<>
			<SEO url={window.location.href} title={'Feedback'} image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'} />
			<h1 style={{ marginLeft: '20%' }}>Get in touch</h1>
			<h4 style={{ color: '#fff', marginBottom: '30px', textAlign: 'center' }}>
				We highly value your feedback. <br />
				We're always working on ways to improve your experience. <br />
				Please tell us what you think about our service. Add your mobile number if you want us to call.
				<p>
					Alternatively you can email us on{' '}
					<a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</a>
				</p>
			</h4>
			<Formik
				enableReinitialize={true}
				initialValues={formValues}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting }) => {
					values.uid = uid
					addFeedback(values)
					setSubmitting(false)
				}}
			>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
					<form onSubmit={handleSubmit}>
						<FormItem label="Name *" {...formItemLayout}>
							<Input
								style={{ width: 300 }}
								name="name"
								placeholder="Enter your name"
								type="text"
								value={values.name}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<div className="error">{errors.name && touched.name && errors.name}</div>
						</FormItem>

						<FormItem label="Message *" {...formItemLayout}>
							<TextArea
								placeholder="message"
								value={values.message}
								onChange={handleChange}
								onBlur={handleBlur}
								autoSize={{ minRows: 2, maxRows: 6 }}
								style={{ width: 300 }}
								name="message"
							/>
							<div className="error">{errors.message && touched.message && errors.message}</div>
						</FormItem>

						<FormItem label="Mobile number (Optional)" {...formItemLayout}>
							<Input
								style={{ width: 300 }}
								name="mobile"
								placeholder="0810001111"
								type="text"
								value={mobile}
								onChange={updateInput}
								onBlur={handleBlur}
							/>
							<div className="error">{errors.mobile && touched.mobile && errors.mobile}</div>
						</FormItem>

						<FormItem {...tailFormItemLayout}>
							<Button type="primary" htmlType="submit" disabled={disabled || isSubmitting}>
								{isSubmitting ? 'Processing' : 'Submit now'}
							</Button>
						</FormItem>
					</form>
				)}
			</Formik>
		</>
	) : (
		<div id="loader">
			<CircularProgress color="secondary" />
		</div>
	)
}

export default Feedback
