// import React, { useEffect, useState } from 'react'
import { Input } from 'antd'
// import { AutoComplete, Input } from 'antd'
// import { DeleteOutlined } from '@ant-design/icons'
import { ISearchTerm } from '../../../src/interfaces/ISearchTerm'
import SEO from '../common/SEO'

interface ISearchProps {
	setSearchTerm: (value: string) => void;
	recentSearchTerms: ISearchTerm[];
}

function SearchName({ setSearchTerm, recentSearchTerms }: ISearchProps): JSX.Element {
	// const [_searchOptions, setSearchOptions] = useState<{ value: string, label: JSX.Element }[]>([])

	// useEffect(() => {
	// 	const recentSearchTermsWithoutDuplicates = recentSearchTerms.filter((term, index, self) => {
	// 		return index === self.findIndex((t) => t.searchTerm === term.searchTerm)
	// 	})

	// 	const terms = recentSearchTermsWithoutDuplicates.map((term) => {
	// 		return {
	// 			value: term.searchTerm,
	// 			label: (
	// 				<div
	// 					style={{
	// 						display: 'flex',
	// 						justifyContent: 'space-between',
	// 					}}
	// 				>
	// 					{term.searchTerm}
	// 					<span>
	// 						{/* <div>
	// 							<DeleteOutlined />
	// 						</div> */}
	// 					</span>
	// 				</div>
	// 			),
	// 		}
	// 	})
	// 	setSearchOptions(terms)
	// }, [recentSearchTerms])

	return (
		<>
			<SEO url={window.location.href}  title={'Search by name'} image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'} />
			<div>
				{/* <AutoComplete popupClassName="certain-category-search-dropdown" dropdownMatchSelectWidth={275} options={searchOptions}> */}
				{/* <AutoComplete options={searchOptions}> */}
				<Input.Search size="large" placeholder="Type a name" onSearch={(value) => setSearchTerm(value)} style={{ width: 275 }} />
				{/* </AutoComplete> */}
			</div>
		</>
	)
}

export default SearchName
