const initialRatingValues = {
	username: '',
	uid: '',
	profileId: '',
	reviewText: '',
	rating: 0,
	likePhotosRating: 0,
	datePosted: new Date().toUTCString(),
	dateEpoch: Date.now(),
}

export default initialRatingValues
