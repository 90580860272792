import React from 'react'

const ProfileFooter = (): JSX.Element => (
	<div
		style={{
			paddingLeft: '10px',
			marginTop: '60px',
			fontWeight: 300,
			fontSize: '12px',
			marginBottom: '40px',
		}}
	>
		Note: Anything that may occur with the advertiser is a matter of personal choice between two consenting adults of legal age, and is
		not contracted for, nor is it requested to be contracted for or compensated for in any manner. No fees of any kind will be quoted,
		negotiated, or collected in exchange for any sexual conduct. All fees are exchanged for time and companionship only. This is not an
		offer for prostitution. Advertisers displayed on venues may have stopped working for the specific location, verify by contacting the
		specific advertiser.
	</div>
)

export default ProfileFooter
