import firebase from '../components/config/constants'
import { IVipData } from '../interfaces/IVip'

function shareProfile(selectedVIP: IVipData): void {
	const { workName, id } = selectedVIP

	if (navigator.share) {
		navigator
			.share({
				title: `Check out ${workName} on BootyVIP`,
				text: `Check out ${workName} on BootyVIP`,
				url: window.location.href,
			})
			.then(() => {
				firebase.analytics().logEvent('webshare', {
					category: 'Profile-View',
					workName: workName,
					id,
				})
			})
			.catch((error: unknown) => {
				firebase.analytics().logEvent('webshare_error', {
					category: 'Profile-View',
					action: `Share - ${workName}`,
					workName: workName,
					error: error,
				})
			})
	} else {
		firebase.analytics().logEvent('webshare_error', {
			category: 'Profile-View',
			action: `Share - ${workName}`,
			workName: workName,
			detail: 'web share not supported',
		})
	}
}

export default shareProfile
