import { useState, useCallback, useEffect } from 'react'
import { Card } from './Card'
import update from 'immutability-helper'
import firebase from '../config/constants'

const style = {
	display: 'flex',
	maxWidth: '400px',
	flexDirection: 'row',
	flexWrap: 'wrap',
}

export const Container = (props) => {
	const [cards, setCards] = useState(props.pics)

	const moveCard = useCallback((dragIndex, hoverIndex) => {
		setCards((prevCards) =>
			update(prevCards, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevCards[dragIndex]],
				],
			})
		)
	}, [])

	const renderCard = useCallback(
		(card, index) => {
			return <Card key={`${card}_${index}`} index={index} id={card} image={card} moveCard={moveCard} />
		},
		[moveCard]
	)

	useEffect(() => {
		firebase
			.firestore()
			.collection('users')
			.doc(props.adId)
			.update({
				pics: cards,
			})
			.catch((error) => {
				console.log(error)
			})
	}, [cards, props.adId])

	return (
		<>
			<div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
		</>
	)
}
