import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { loginAnonymously, logout } from './helpers/auth'
import mobileDetect from 'mobile-detect'
import axios from 'axios'
import firebase, { firebaseAuth } from './config/constants'
import { slide as Menu } from 'react-burger-menu'
import { toast } from 'react-toastify'
import '../styles/navigation.scss'

toast.configure()

class Nav extends Component {
	state = {
		isMobile: false,
		menuOpen: false,
		country_code: 'ZA',
		links: [],
		foreign_links: [],
		authed: false,
		loading: true,
		role: 'guest',
	}

	handleLeave = () => {
		firebase.analytics().logEvent('logout')
		logout()
		this.hideMenu()
	}

	hideMenu = () => {
		if (this.state.isMobile) {
			this.setState({
				menuOpen: false,
			})
			document.getElementById('react-burger-cross-btn').click()
		}
	}

	updateOnlineStatus = () => {
		navigator.onLine ? document.body.classList.remove('offline') : document.body.classList.add('offline')
		this.setState({ online: navigator.onLine })

		// firebase.analytics().logEvent('connection_state', {
		// 	category: 'Connection State',
		// 	action: this.state.online
		// })
	}

	geoFindMe = async () => {
		// const status = document.querySelector('#status')
		// const mapLink = document.querySelector('#map-link')

		// mapLink.href = ''
		// mapLink.textContent = ''

		async function success(position) {
			const latitude = position.coords.latitude
			const longitude = position.coords.longitude

			// status.textContent = ''
			// mapLink.href = `https://www.openstreetmap.org/#map=18/${latitude}/${longitude}`
			// mapLink.textContent = `Latitude: ${latitude} °, Longitude: ${longitude} °`

			try {
				axios.get(
					`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?types=country,place&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
				)
			} catch (error) {
				firebase.analytics().logEvent('store_vip_position_error', {
					category: 'Store VIP Position',
					action: error,
				})
			}
		}

		function error() {
			console.warn('Unable to retrieve your location')
		}

		if (!navigator.geolocation) {
			console.warn('Geolocation is not supported by your browser')
		} else {
			console.warn('Locating…')
			navigator.geolocation.getCurrentPosition(success, error)
		}
	}

	manageDesktopMenu = () => {
		let userEmail = firebaseAuth().currentUser.email
		let authLinks = []

		if (!userEmail) {
			authLinks = [
				{ href: '/login/', label: 'Login' },
				{ href: '/register/', label: 'Register' },
			]
		}

		let links = [
			{ href: '/discover/', label: 'Discover' },
			{ href: '/favourites/', label: 'My Call Roster' },
			{ href: '/search/', label: 'Search' },
			{ href: '/advertise/', label: 'Advertise' },
			{ href: '/feedback/', label: 'Feedback' },
			...authLinks,
		].map((link) => {
			link.key = `nav-link-${link.href}-${link.label}`
			return link
		})

		let foreign_links = [
			{ href: '/discover/', label: 'Discover' },
			{ href: '/favourites/', label: 'My Call Roster' },
			{ href: '/search/', label: 'Search' },
			{ href: '/advertise/', label: 'Advertise' },
			{ href: '/feedback/', label: 'Feedback' },
			...authLinks,
		].map((link) => {
			link.key = `nav-link-${link.href}-${link.label}`
			return link
		})

		this.state.country_code === 'ZA' ||
		this.state.country_code === 'ZW' ||
		this.state.country_code === 'BW' ||
		this.state.country_code === 'TZ' ||
		this.state.country_code === 'KE'
			? this.setState({
					links,
			  })
			: this.setState({
					links: foreign_links,
			  })
	}

	componentDidMount() {
		let tooOld = 0
		let dataTimeStamp = JSON.parse(localStorage.getItem('vipDataTimeStamp'))

		// this.geoFindMe()

		if (dataTimeStamp) {
			const vipDataTimeStamp = Date.parse(dataTimeStamp)
			const now = Date.parse(new Date())
			const dataAge = Math.round((now - vipDataTimeStamp) / (1000 * 60 * 60 * 24))
			tooOld = dataAge >= 1
			// We check location by IP when last check was a day or more ago
		}

		if (!localStorage.getItem('vipPosition') || tooOld) {
			axios
				.get(`https://api.ipdata.co?api-key=${process.env.REACT_APP_IPDATA_ACCESS_TOKEN}`)
				.then((res) => {
					localStorage.setItem('vipPosition', JSON.stringify(res.data))
					localStorage.setItem('vipDataTimeStamp', JSON.stringify(new Date()))
					let country_code = JSON.parse(localStorage.getItem('vipPosition')).country_code
					this.setState({
						country_code,
					})
					this.manageDesktopMenu(country_code)

					firebase.analytics().logEvent('store_vip_position', {
						category: 'Store VIP Position',
						action: country_code,
					})
				})
				.catch((error) => {
					firebase.analytics().logEvent('store_vip_position_error', {
						category: 'Store VIP Position',
						action: error,
					})
				})
		} else {
			this.setState({
				country_code: JSON.parse(localStorage.getItem('vipPosition')).country_code,
			})
		}

		this.removeListener = firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				if (firebaseAuth().currentUser) {
					let uid = firebaseAuth().currentUser.uid
					if (uid) {
						try {
							firebase
								.firestore()
								.collection('kings')
								.doc(uid)
								.get()
								.then((doc) => {
									if (doc.exists) {
										this.setState({
											role: doc.data().role,
										})
									}
								})
						} catch (error) {
							firebase.analytics().logEvent('role_error', {
								category: 'Role',
								action: error,
							})
							console.log(error.message)
							toast(error.message)
						}
					}
				}
				if (!user.isAnonymous) {
					this.setState({
						authed: true,
						loading: false,
					})
					this.manageDesktopMenu()
				} else {
					this.setState({
						authed: false,
						loading: false,
					})
					this.manageDesktopMenu()
				}
			} else {
				loginAnonymously().then(() => {
					this.setState({
						authed: false,
						loading: false,
					})
					this.manageDesktopMenu()
				})
			}
		})

		const isMobile = new mobileDetect(window.navigator.userAgent)
		isMobile.ua.includes('Mobile') &&
			this.setState({
				isMobile: true,
			})
		window.addEventListener('online', this.updateOnlineStatus)
		window.addEventListener('offline', this.updateOnlineStatus)
		this.updateOnlineStatus()
	}

	render() {
		const { isMobile, links, menuOpen, authed } = this.state
		return (
			this.props.location.pathname !== '/' && (
				<nav>
					<ul>
						<li>
							<div
								onClick={() => (isMobile ? this.props.history.goBack() : this.props.history.push('/discover/'))}
								className="activeLink"
							>
								{isMobile && this.props.location.pathname !== '/discover/' && this.props.location.pathname !== '/' ? (
									<img
										src={`${process.env.REACT_APP_S3_BASE_URL}static/icons/return(red).svg`}
										height="35px"
										alt="Back"
									/>
								) : (
									<img
										src={`${process.env.REACT_APP_S3_BASE_URL}static/logo.svg`}
										width="155px"
										height="30px"
										alt="Home"
									/>
								)}
							</div>
						</li>

						{isMobile ? (
							<Menu right width={'85%'} isOpen={menuOpen}>
								<Link to="/discover/" className="menu-item" onClick={() => this.hideMenu()}>
									Discover
								</Link>

								{authed ? (
									<Link
										to="/dashboard"
										className="bm-item menu-item"
										style={{ border: 'none', background: 'transparent', fontSize: '9pt', display: 'block' }}
										onClick={() => this.hideMenu()}
									>
										My Dashboard
									</Link>
								) : (
									<>
										<Link
											to="/login"
											className="bm-item menu-item"
											style={{ display: 'block' }}
											onClick={() => this.hideMenu()}
											id="login-link"
										>
											Login
										</Link>
										<Link
											to="/register"
											className="bm-item menu-item"
											style={{ display: 'block' }}
											onClick={() => this.hideMenu()}
										>
											Register
										</Link>
									</>
								)}
								<Link to="/favourites/" className="menu-item" onClick={() => this.hideMenu()}>
									My Favourites
								</Link>
								<Link to="/search" className="menu-item" onClick={() => this.hideMenu()}>
									Search
								</Link>
								<Link to="/advertise/" className="menu-item" onClick={() => this.hideMenu()}>
									Advertise
								</Link>
								<Link to="/feedback/" className="menu-item" onClick={() => this.hideMenu()}>
									Send Feedback
								</Link>
								<Link to="/privacy-policy/" className="menu-item" onClick={() => this.hideMenu()}>
									Privacy Policy
								</Link>

								{this.state.role === 'king' && (
									<Link to="/verify" className="menu-item" onClick={() => this.hideMenu()}>
										Unverified Ads
									</Link>
								)}
								{this.state.role === 'king' && (
									<Link to="/hidden" className="menu-item" onClick={() => this.hideMenu()}>
										Hidden Ads
									</Link>
								)}
								{authed && (
									<Link
										onClick={() => this.handleLeave()}
										to="#"
										className="bm-item menu-item"
										style={{ border: 'none', background: 'transparent', fontSize: '9pt', display: 'block' }}
									>
										Logout
									</Link>
								)}
							</Menu>
						) : (
							<ul className="nav-ul">
								{links.map(({ key, href, label }) => (
									<li key={key} data-test={label}>
										<Link to={href}>{label}</Link>
									</li>
								))}
								{firebaseAuth().currentUser && firebaseAuth().currentUser.email && (
									<>
										<li data-test="My-Dashboard">
											<Link to="/dashboard">My Dashboard</Link>
										</li>
										<li data-test="Logout">
											<Link to="/disclaimer" onClick={() => this.handleLeave()}>
												Logout
											</Link>
										</li>
									</>
								)}
								{this.state.role === 'king' && (
									<>
										<li data-test="Unverified-Ads">
											<Link onClick={() => this.hideMenu()} to="/verify">
												unverified Ads
											</Link>
										</li>
										<li data-test="Hidden-Ads">
											<Link onClick={() => this.hideMenu()} to="/hidden">
												Hidden Ads
											</Link>
										</li>
									</>
								)}
							</ul>
						)}
					</ul>
				</nav>
			)
		)
	}
}

export default React.memo(withRouter(Nav))
