import React, { useEffect, useState, useContext } from 'react'
import firebase, { firebaseAuth } from './config/constants'
import PhotoCards from './PhotoCards'
import SEO from './common/SEO'
import { IVip } from '../interfaces/IVip'
import { VIPContext } from '../context/VIPContext'
import './App.scss'

function EmptyBattery() {
	return (
		<img
			src={`${process.env.REACT_APP_S3_BASE_URL}static/icons/icon-empty-battery.svg`}
			width="120px"
			height="120px"
			alt="No favourites"
			style={{ marginTop: '80px' }}
		/>
	)
}

function Favourites(): JSX.Element {
	const [allVIPs] = useContext(VIPContext)
	const [uid, setUid] = useState<string | null>(null)
	const [favVIPs, setFavVIPs] = useState<IVip[]>([])

	useEffect(() => {
		firebase.analytics().logEvent('favourites_view', {
			category: 'Favourites-View',
			action: 'Error getting Fav VIPs',
			label: 'favourites_view',
		})
	}, [])

	useEffect(() => {
		if (uid === null) {
			firebaseAuth().onAuthStateChanged((user) => {
				user && setUid(user.uid)
			})
		}
	}, [uid])

	useEffect(() => {
		if (allVIPs && allVIPs.length > 0 && uid) {
			try {
				const favourites = (favsList: string[]) => {
					const finalList = favsList.map((fav) => allVIPs.filter((vip) => vip.id === fav)[0])
					if (finalList.length > 0) {
						setFavVIPs(finalList)
					}
				}

				firebase
					.firestore()
					.collection('players')
					.doc(uid)
					.get()
					.then((doc) => {
						if (doc.exists) {
							const data = doc.data()
							if (data && data.favourites) {
								const favsList = data.favourites
								if (favsList && favsList.length > 0) {
									favourites(favsList)
								}
							}
						} else {
							setFavVIPs([])
						}
					})
			} catch (err: unknown) {
				firebase.analytics().logEvent('favourites_view_error', {
					category: 'Favourites-View',
					action: 'Error getting Fav VIPs',
					label: err,
				})
			}
		}
	}, [allVIPs, uid])

	return (
		<>
			<SEO url={window.location.href} title="My Call Roster" image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'} />
			<div className="App">
				<h5>MY CALL ROSTER</h5>
				{firebaseAuth().currentUser ? (
					favVIPs && favVIPs.length ? (
						<PhotoCards id="PhotoCards" favVIPs={favVIPs} />
					) : (
						<>
							<EmptyBattery />
							<p>
								Welcome to your Call Roster! It looks like it's currently empty. No worries though! Once you log in, you'll
								be able to add beauties to your Call Roster with just a click. Simply find an advert you like and hit the
								"Add to Call Roster" button to get started. Happy calling!
							</p>
						</>
					)
				) : (
					<EmptyBattery />
				)}
			</div>
		</>
	)
}

export default Favourites
