import firebase from '../components/config/constants'

const logError = (error: string, file: string, functionName: string, uid?: string, region?: string) => {
	if (error && file && functionName) {
		firebase
			.firestore()
			.collection('errors')
			.doc(file)
			.set({ error, functionName, file, date: new Date().toISOString() })
			.catch((error) => {
				console.error(`Error logging error`, error)
			})
	}
}

export default logError
