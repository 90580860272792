import React from 'react'
import { Link } from 'react-router-dom'
import firebase from '../config/constants'
import { InstagramOutlined, TwitterOutlined } from '@ant-design/icons'

function SocialMediaLinks(): JSX.Element {
	const trackClick = (media: string) => {
		firebase.analytics().logEvent(`${media}_click`, {
			category: `${media}-Click`,
			action: `${media}`,
			label: `${media}-Click`,
		})
	}

	return (
		<div className="viewBy">
			<Link
				className="tagLink"
				to={{
					pathname: 'https://instagram.com/bootyvips/',
				}}
				target={'_blank'}
			>
				<div className="tag" style={{ border: 0, fontSize: '2.5rem' }} onClick={() => trackClick('instagram')}>
					<InstagramOutlined />
				</div>
			</Link>
			<Link
				className="tagLink"
				to={{
					pathname: 'https://twitter.com/booty_vip',
				}}
				target={'_blank'}
			>
				<div className="tag" style={{ border: 0, fontSize: '2.5rem' }} onClick={() => trackClick('twitter')}>
					<TwitterOutlined />
				</div>
			</Link>
			<p></p>
		</div>
	)
}

export default SocialMediaLinks
