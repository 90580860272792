export const bodyOptions: string[] = [
	'Athletic',
	'BBW',
	'Curvy',
	'Medium',
	'Model',
	'Petite',
	'Slim',
	'Slim with booty',
	'Tall',
	'Voluptuous',
]
