import React, { useEffect, useState } from 'react'
import firebase, { firebaseAuth } from './config/constants'
import { withRouter, Link } from 'react-router-dom'
import { Select } from 'antd'
import mobileDetect from 'mobile-detect'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
	johannesburg,
	pretoria,
	capeTown,
	durban,
	bloemfontein,
	portElizabeth,
	eastLondon,
	newcastle,
	secunda,
	rustenburg,
	welkom,
	bulawayo,
	harare,
	beitbridge,
	gweru,
	chitungwiza,
	ngezi,
	mutare,
	gaborone,
	daressalaam,
	nairobi,
	victoriaFalls,
} from '../constants/suburbs'
import { CircularProgress } from '@material-ui/core'
import ImageReorder from './imageReorder'
import SEO from './common/SEO'

toast.configure()

const { Option } = Select

function Dashboard() {
	const [isLoading, setIsLoading] = useState(true)
	const [updating, setUpdating] = useState(false)
	const [role, setRole] = useState('guest')
	const [username, setUsername] = useState('')
	const [adSelected, setAdSelected] = useState()
	const [suburbSelected, setSuburbSelected] = useState([])
	const [citySelected, setCitySelected] = useState([])
	const [isMobile, setIsMobile] = useState(true)
	// const [mobileSelected, setMobileSelected] = useState(0)
	const [suburbSelectedAttributes, setSuburbSelectedAttributes] = useState([])
	// const [mobileSelectedAttributes, setMobileSelectedAttributes] = useState([])
	const [ads, setAds] = useState([])
	const [zacities] = useState([
		'Johannesburg',
		'Pretoria',
		'Cape Town',
		'Durban',
		'Port Elizabeth',
		'East London',
		'Secunda',
		'Welkom',
		'Rustenburg',
	])
	const [zwcities] = useState(['Bulawayo', 'Harare', 'Gweru', 'Chitungwiza', 'Ngezi', 'Beitbridge', 'Mutare', 'Victoria Falls'])
	const [bwcities] = useState(['Gaborone'])
	const [tzcities] = useState(['Dar es Salaam', 'Mwanza', 'Arusha', 'Zanzibar', 'Dodoma', 'Tanga', 'Mbeya', 'Morogoro'])
	const [kecities] = useState(['Nairobi', 'Mombasa', 'Kisumu', 'Nakuru', 'Eldoret', 'Machakos', 'Nyeri', 'Meru'])

	const manageAvailable = (adId, time, workName) => {
		const profileRef = firebase.firestore().collection('users').doc(adId)
		const logRef = firebase.firestore().collection('stocklogs').doc(firebaseAuth().currentUser.uid)

		profileRef
			.update(
				{
					available: time,
				},
				{ merge: true }
			)
			.catch((error) => {
				firebase.analytics().logEvent('dashboard_available_time_error', {
					category: 'Dashboard',
					action: 'Firebase Error',
					label: `Error updating ${workName}'s available: ${error}`,
					nonInteraction: true,
				})
			})

		logRef
			.set(
				{
					[time]: `Availed ${workName} by ${firebaseAuth().currentUser.email}:${new Date(time).toISOString()}`,
				},
				{ merge: true }
			)
			.then(() => {
				setUpdating(!updating)
				toast(`${workName} now listed on Available`)

				firebase.analytics().logEvent('make_available', {
					category: 'Dashboard',
					action: 'Change Available',
					label: `${workName} - ${time}`,
				})
			})
			.catch((error) => {
				firebase.analytics().logEvent('dashboard_log_available_timeview_error', {
					category: 'Dashboard',
					action: 'Firebase Error',
					label: `Error updating ${workName}'s log available: ${error}`,
					nonInteraction: true,
				})
			})
	}

	const manageActive = (currentStatus, adId, time, workName) => {
		const profileRef = firebase.firestore().collection('users').doc(adId)
		const logRef = firebase.firestore().collection('stocklogs').doc(firebaseAuth().currentUser.uid)
		const action = currentStatus ? 'Hidden' : 'Activated'
		profileRef
			.update({
				active: !currentStatus,
			})
			.then(() => {
				setUpdating(!updating)
			})
			.then(() => {
				logRef.set(
					{
						[time]: `${action} ${workName} by ${firebaseAuth().currentUser.email}:${new Date(time).toISOString()}`,
					},
					{ merge: true }
				)
			})
			.then(() => {
				toast(`${workName} advert is now ${action}`)

				firebase.analytics().logEvent('advert_active_view', {
					action: action,
					label: `${workName} - ${time}`,
				})
			})
			.catch((error) => {
				firebase.analytics().logEvent('dashboard_view_error', {
					category: 'Dashboard',
					action: 'Firebase Error',
					label: `Error updating ${workName}'s active status: ${error}`,
					nonInteraction: true,
				})
			})
	}

	const handleSuburbChange = (value, index) => {
		setAdSelected(index)
		if (citySelected.length && value.length > 0 && index !== undefined) {
			let suburbsSelected = [...suburbSelected]
			suburbsSelected[index] = value
			setSuburbSelected(suburbsSelected)
		}
	}

	const handleCityChange = (value, index) => {
		setAdSelected(index)
		if (value.length > 0 && index !== undefined) {
			let citiesSelected = [...citySelected]
			citiesSelected[index] = value
			setCitySelected(citiesSelected)

			let suburbsSelected = [...suburbSelected]
			suburbsSelected[index] = ''
			setSuburbSelected(suburbsSelected)
		}
	}

	// const handleMobile = (e, id, workName) => {
	//  if (e.target.value.length > 9) {
	//      setMobileSelected(e.target.value)
	//      setMobileSelectedAttributes([id, workName])
	//  }
	// }

	useEffect(() => {
		const isMobile = new mobileDetect(window.navigator.userAgent).ua.includes('Mobile') ?? false
		setIsMobile(isMobile)
	}, [])

	useEffect(() => {
		try {
			async function fetchUserRole() {
				if (firebaseAuth().currentUser) {
					let uid = firebaseAuth()?.currentUser.uid

					if (uid) {
						await firebase
							.firestore()
							.collection('kings')
							.doc(uid)
							.get()
							.then((doc) => {
								if (doc.exists) {
									setRole('king')
									firebase.analytics().setUserProperties({ role: 'king' })
								}
							})
					}

					if (uid) {
						await firebase
							.firestore()
							.collection('users')
							.doc(uid)
							.get()
							.then((doc) => {
								if (doc.exists) {
									setRole('stock')
									if (doc.data().workName) {
										setUsername(doc.data().workName)
									}
									firebase.analytics().setUserProperties({ role: 'stock' })
								}
							})
					} else {
						await firebase
							.firestore()
							.collection('players')
							.doc(uid)
							.get()
							.then((doc) => {
								if (doc.exists) {
									setRole('guest')
									if (doc.data().username) {
										setUsername(doc.data().username)
									}
									firebase.analytics().setUserProperties({ role: 'guest' })
								}
							})
					}
				}
			}
			fetchUserRole()
		} catch (error) {
			firebase.analytics().logEvent('dashboard_user_error', {
				category: 'Dashboard User',
				action: 'Error with Dashboard User',
				label: error,
			})
		} finally {
		}
	}, [])

	useEffect(() => {
		if (
			suburbSelectedAttributes.length &&
			adSelected !== undefined &&
			suburbSelected.length &&
			suburbSelected[adSelected] &&
			citySelected.length &&
			citySelected[adSelected]
		) {
			let workName = suburbSelectedAttributes[1]
			let adId = suburbSelectedAttributes[0]
			const profileRef = firebase.firestore().collection('users').doc(adId)
			profileRef
				.update({
					suburb: suburbSelected[adSelected],
					city: citySelected[adSelected],
				})
				.then(() => {
					toast(`${workName}'s details updated`)

					firebase.analytics().logEvent('dashboard_view', {
						category: 'Dashboard',
						action: 'Change details',
						label: `${workName}`,
					})
				})
				.catch((error) => {
					firebase.analytics().logEvent('dashboard_view_error', {
						category: 'Dashboard',
						action: 'Firebase Error',
						label: `Error changing ${workName}'s details: ${error}`,
						nonInteraction: true,
					})
				})
		}
	}, [citySelected, suburbSelected, suburbSelectedAttributes, adSelected])

	// useEffect(async () => {
	//  async function fetchData() {
	//      if (mobileSelectedAttributes.length) {
	//          let workName = await mobileSelectedAttributes[1]
	//          let adId = await mobileSelectedAttributes[0]
	//          const profileRef = await firebase.firestore().collection('users').doc(adId)
	//          profileRef
	//              .update({
	//                  mobile: mobileSelected
	//              })
	//              .then(() => {
	//                  setUpdating(!updating)
	//                  toast(`${workName}'s mobile now ${mobileSelected}`)
	//                  ReactGA.event = {
	//                      category: 'Dashboard',
	//                      action: 'Change mobile',
	//                      label: `${workName}`
	//                  }
	//
	//              })
	//              .catch(error => {
	//

	//                  ReactGA.event = {
	//                      category: 'Dashboard',
	//                      action: 'Firebase Error',
	//                      label: `Error changing ${workName}'s mobile: ${error}`,
	//                      nonInteraction: true
	//                  }
	//              })
	//      }
	//  }
	//  fetchData()
	// }, [mobileSelected])

	useEffect(() => {
		setTimeout(() => {
			if (firebaseAuth()?.currentUser?.uid) {
				let _ads = []
				let _citiesSelected = []
				let _suburbsSelected = []

				firebase
					.firestore()
					.collection('users')
					.where('uid', '==', firebaseAuth()?.currentUser?.uid)
					.get()
					.then((querySnapshot) => {
						querySnapshot.forEach(function (doc) {
							_ads.push({
								data: doc.data(),
								id: doc.id,
							})
							_citiesSelected.push(doc.data().city)
							_suburbsSelected.push(doc.data().suburb)
						})
					})
					.then(() => setAds(_ads))
					.then(() => setCitySelected(_citiesSelected))
					.then(() => setSuburbSelected(_suburbsSelected))
					.then(() => setIsLoading(false))
					.catch((error) => {
						firebase.analytics().logEvent('dashboard_view_error', {
							category: 'Set Dashboard Advertiser Details',
							action: error,
						})
						setIsLoading(false)
					})
			}
		}, 1000)
	}, [updating])

	return (
		<>
			<SEO url={window.location.href} title={'Dashboard'} image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'} />
			<div className="App">
				{isLoading ? (
					<div id="loader">
						<CircularProgress color="secondary" />
					</div>
				) : !isLoading ? (
					<>
						{ads.length > 0 && (
							<div style={{ clear: 'both' }}>
								<h5>Hi {username ? username : ads[0].data.workName}</h5>
							</div>
						)}

						{ads.length > 0 ? (
							ads.map((ad, index) => (
								<span key={index}>
									<div
										style={{
											clear: 'both',
											marginTop: '35px',
											display: 'flex',
											backgroundColor: Date.now() - ad.data.available < 3600000 ? 'rgb(86, 187, 107)' : null,
										}}
									>
										<div style={{ flex: 2 }}>
											{ad.data.pics && ad.data.pics.length > 0 ? (
												<img
													src={
														ad.data.pics[ad.data.pics.length - 1].includes('static')
															? process.env.REACT_APP_S3_BASE_URL + ad.data.pics[ad.data.pics.length - 1]
															: ad.data.pics[ad.data.pics.length - 1]
													}
													alt={`BootyVIP - ${ad.data.workName}`}
													width="100%"
													loading="lazy"
												/>
											) : (
												<img
													src={`${process.env.REACT_APP_S3_BASE_URL}static/profiles/images/person.png`}
													alt={`BootyVIP - ${ad.data.workName}`}
													width="100%"
													loading="lazy"
												/>
											)}
										</div>
										<div
											style={{
												flex: 3,
												textAlign: 'left',
												padding: '5px 10px 10px 10px',
												// backgroundColor: Date.now() - ad.data.available < 3600000 ? 'rgb(29, 43, 39)' : null
											}}
										>
											<p
												className="workName"
												style={{
													color: Date.now() - ad.data.available < 3600000 ? 'rgb(16, 14, 23)' : '#fff',
													fontSize: '17px',
												}}
											>
												{ad.data.workName}
											</p>

											{/* <div style={{ marginTop: '10px' }}>
                                            <Input
                                                name="mobile"
                                                placeholder="input with clear icon"
                                                allowClear
                                                onChange={handleMobile}
                                                onBlur={setMobileSelectedAttributes([ad.id, ad.data.workName])}
                                                defaultValue={mobileSelected.length > 1 ? mobileSelected : ad.data.mobile}
                                            />
                                        </div> */}
											{ad.data.active ? (
												<>
													<div style={{ marginTop: '10px' }}>
														<Select
															name="city"
															onBlur={(value) => handleCityChange(value, index)}
															style={{ width: '100%' }}
															defaultValue={
																citySelected[index]?.length > 0 ? citySelected[index] : ad.data.city
															}
															onChange={(value) => handleCityChange(value, index)}
														>
															{ad.data.country === 'ZA'
																? zacities.map((city, index) => (
																		<Option key={index} value={city}>
																			{city}
																		</Option>
																  ))
																: ad.data.country === 'ZW'
																? zwcities.map((city, index) => (
																		<Option key={index} value={city}>
																			{city}
																		</Option>
																  ))
																: ad.data.country === 'BW'
																? bwcities.map((city, index) => (
																		<Option key={index} value={city}>
																			{city}
																		</Option>
																  ))
																: ad.data.country === 'KE'
																? kecities.map((city, index) => (
																		<Option key={index} value={city}>
																			{city}
																		</Option>
																  ))
																: ad.data.country === 'TZ'
																? tzcities.map((city, index) => (
																		<Option key={index} value={city}>
																			{city}
																		</Option>
																  ))
																: null}
														</Select>
													</div>

													<div style={{ marginTop: '10px' }}>
														<Select
															id={`suburb-select-${index}`}
															name="suburb"
															showSearch
															optionFilterProp="children"
															filterOption={(input, option) =>
																option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
															}
															style={{ width: '100%' }}
															onChange={(value) => handleSuburbChange(value, index)}
															onSelect={() => setSuburbSelectedAttributes([ad.id, ad.data.workName])}
															defaultValue={
																suburbSelected.length > 0 && suburbSelected[index]
																	? suburbSelected[index]
																	: suburbSelected[index] === ''
																	? ''
																	: ad.data.suburb
															}
														>
															{citySelected[index] === 'Johannesburg'
																? johannesburg.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Pretoria'
																? pretoria.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Cape Town'
																? capeTown.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Durban'
																? durban.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Bloemfontein'
																? bloemfontein.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Port Elizabeth'
																? portElizabeth.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'East London'
																? eastLondon.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Newcastle'
																? newcastle.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Secunda'
																? secunda.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Rustenburg'
																? rustenburg.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Welkom'
																? welkom.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Harare'
																? harare.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Bulawayo'
																? bulawayo.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Beitbridge'
																? beitbridge.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Gweru'
																? gweru.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Chitungwiza'
																? chitungwiza.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Mutare'
																? mutare.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))

																: citySelected[index] === 'Ngezi'
																? ngezi.map((suburb, index) => (

																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Nairobi'
																? nairobi.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Dar es Salaam'
																? daressalaam.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Gaborone'
																? gaborone.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: citySelected[index] === 'Victoria Falls'
																? victoriaFalls.map((suburb, index) => (
																		<Option key={index} value={suburb}>
																			{suburb}
																		</Option>
																  ))
																: null}
														</Select>
													</div>

													<div style={{ marginTop: '10px' }}>
														{ad.data.active &&
														(!ad.data.available || Date.now() - ad.data.available > 3600000) ? (
															<button
																className="makeAvailable"
																onClick={() => manageAvailable(ad.id, Date.now(), ad.data.workName)}
															>
																Make Me Available
															</button>
														) : ad.data.active && Date.now() - ad.data.available < 3600000 ? (
															<span className="profile" style={{ color: 'rgb(16, 14, 23)' }}>
																Available
															</span>
														) : null}
													</div>
												</>
											) : null}

											<div style={{ marginTop: '10px' }}>
												{!ad.data.active ? (
													ad.data.verified === true ? (
														<button
															className="makeAvailable"
															onClick={() =>
																manageActive(ad.data.active, ad.id, Date.now(), ad.data.workName)
															}
														>
															Display My Advert
														</button>
													) : (
														<button className="deactivate" disabled>
															Awaiting Verification
														</button>
													)
												) : (
													<button
														className="deactivate"
														onClick={() => manageActive(ad.data.active, ad.id, Date.now(), ad.data.workName)}
													>
														Hide This Advert
													</button>
												)}
												<button
													className="deactivate"
													style={isMobile ? { marginTop: '10px' } : { marginLeft: '10px' }}
												>
													<Link to={`/profile/${ad.id}`} style={{ color: '#0b2239', padding: 0 }}>
														View This Advert
													</Link>
												</button>
												<button
													className="deactivate"
													style={isMobile ? { marginTop: '10px' } : { marginLeft: '10px' }}
												>
													<Link to={`/profile/${ad.id}`} style={{ color: '#0b2239', padding: 0 }}>
														Add/Delete Photos
													</Link>
												</button>
											</div>

											{ad.data.pics && ad.data.pics.length > 2 && (
												<div style={{ marginTop: '10px' }}>
													<span className="profile" style={{ color: 'rgb(255,255,255)' }}>
														Rearrange Photos:
														<br />
														Click &amp; drag (Last picture is your profile photo):
													</span>
													<ImageReorder pics={ad.data.pics} adId={ad.id} />
												</div>
											)}
										</div>
									</div>
								</span>
							))
						) : (
							<>
								<h5>Hi {username} </h5>
								{role === 'king' ? (
									<img src={`${process.env.REACT_APP_S3_BASE_URL}static/icons/icon-king.svg`} height="20px" alt={role} />
								) : role === 'guest' ? (
									<img src={`${process.env.REACT_APP_S3_BASE_URL}static/icons/icon-guest.svg`} height="20px" alt={role} />
								) : null}
								<br />
							</>
						)}

						{role === 'king' ? (
							<>
								<button className="redirect">
									<Link to="/verify" style={{ color: '#fff' }}>
										Unverified Escorts
									</Link>
								</button>
								<p></p>
							</>
						) : null}

						<button className="redirect">
							<Link to="/discover" style={{ color: '#fff' }}>
								Browse Escorts
							</Link>
						</button>
						<p></p>
					</>
				) : firebaseAuth()?.currentUser?.isAnonymous ? (
					<>
						<button className="redirect">
							<Link to="/login" style={{ color: '#fff' }}>
								Login
							</Link>
						</button>{' '}
						to view this dashboard
					</>
				) : null}
			</div>
		</>
	)
}

export default withRouter(Dashboard)
