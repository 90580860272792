import React from 'react'
import { Input, Radio, Space } from 'antd'

const SelectBox = ({ reportDetails, setReportDetails, reportType, setReportType }) => {
	return (
		<>
			<Radio.Group value={reportType} onChange={({ target }) => setReportType(target.value)}>
				<Space direction="vertical">
					<Radio value="NUMBER_NOT_WORKING">Phone number not working</Radio>
					<Radio value="NUMBER_INCORRECT">Wrong phone number</Radio>
					<Radio value="WRONG_LOCATION">Wrong location</Radio>
					<Radio value="OTHER">Other</Radio>
				</Space>
			</Radio.Group>
			<br />
			<br />
			<strong>Details:</strong>
			<Input.TextArea rows={3} name="reportDetails" value={reportDetails} onChange={({ target }) => setReportDetails(target.value)} />
		</>
	)
}

export default SelectBox
