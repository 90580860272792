import firebase from '../components/config/constants'
const db = firebase.firestore()

export const postSearchTerm = ({ searchTerm, uid, date }: { searchTerm: string, uid: string, date: string }): void => {
	db.collection('searches')
		.doc()
		.set(
			{
				searchTerm,
				uid,
				date,
			},
			{ merge: true }
		)
		.catch((error) => {
			console.error(`Error writing search: `, error)
		})

	firebase.analytics().logEvent('search_by_name_action', {
		category: 'SearchByName-Action',
		action: 'Name searched',
		label: searchTerm,
	})
}
