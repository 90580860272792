import React from 'react'
import UploadToS3 from '../common/UploadToS3'

interface IUploadMediaProps {
    vipId: string
    selectedVIP: any
}

const UploadMedia = ({ vipId, selectedVIP }: IUploadMediaProps) => {
	const {  pics, videoUrls, videoLink } = selectedVIP

	return (
		<>
			<UploadToS3 profileId={vipId} buttonLabel={'Add photos to profile'} currentPics={pics ?? []} type={'image'} />
			<UploadToS3
				profileId={vipId}
				buttonLabel={'Add videos to profile'}
				currentVideos={videoUrls ?? []}
				currentVideo={videoLink ?? null}
				type={'video'}
			/>
		</>
	)
}

export default UploadMedia
