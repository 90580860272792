import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import { IVipData } from '../../interfaces/IVip'
import { trackInteraction } from '../../utils/trackProfileInteraction'
import firebase from '../config/constants'

interface IFavouriteButtonProps {
	uid: string;
	selectedVIP: IVipData;
}

function FavouriteButton(props: IFavouriteButtonProps): JSX.Element {
	const { uid, selectedVIP } = props
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [isFavourite, setIsFavourite] = useState<boolean>(false)
	const [favsList, setFavsList] = useState<null | string[]>([])

	useEffect(() => {
		if (uid && selectedVIP.id) {
			try {
				firebase
					.firestore()
					.collection('players')
					.doc(uid)
					.get()
					.then((doc) => {
						if (doc.exists) {
							const data = doc.data()
							if (data && data.favourites) {
								const favsList = data.favourites
								if (favsList && favsList.length > 0) {
									favsList.includes(selectedVIP.id) ? setIsFavourite(true) : setIsFavourite(false)
									setFavsList(favsList)
								}
							}
						}
					})
			} catch (err: unknown) {
				firebase.analytics().logEvent('favourites_button_error', {
					category: 'Favourites-Button',
					action: 'Error with Favourites VIP',
					label: err,
				})
			} finally {
				setIsLoading(false)
			}
		}
	}, [uid, selectedVIP.id])

	const handleFavourite = () => {
		if (isFavourite && favsList && favsList.length > 0 && favsList.includes(selectedVIP.id)) {
			const newList = favsList.filter((fav) => fav !== selectedVIP.id)
			setFavsList(newList)
			setIsFavourite(false)
			sendFavourite(newList)
		} else if (!isFavourite && favsList && !favsList.includes(selectedVIP.id)) {
			const newList = [...favsList, selectedVIP.id]
			setFavsList(newList)
			setIsFavourite(true)
			sendFavourite(newList)
		}
		const favAction = isFavourite ? 'Unfavourite' : 'Favourite'
		trackInteraction(uid, selectedVIP.workName, selectedVIP.id, favAction.toUpperCase())
	}

	const sendFavourite = (newList: string[]) => {
		if (uid) {
			try {
				firebase.firestore().collection('players').doc(uid).set({ favourites: newList }, { merge: true })
			} catch (err: unknown) {
				firebase.analytics().logEvent('favourites_update_error', {
					category: 'Favourites-Update',
					action: 'Error with Favourites VIP update',
					label: err,
				})
			}
		}
	}

	return !isLoading ? (
		<Button
			onClick={handleFavourite}
			size="small"
			className="ant-btn"
			style={{
				fontSize: '10px',
				backgroundColor: 'transparent',
				borderColor: isFavourite ? 'rgb(113, 112, 114)' : '#ec0606',
				color: isFavourite ? 'rgb(113, 112, 114)' : '#ec0606',
				marginTop: '8px',
			}}
		>
			{isFavourite ? 'Remove from Call Roster' : '❤️ Add to Call Roster'}
		</Button>
	) : (
		<></>
	)
}

export default FavouriteButton
