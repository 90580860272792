import firebase, { firebaseAuth } from '../config/constants'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure()

export async function loginAnonymously() {
	return await firebaseAuth().signInAnonymously().then(saveUserData)
}

export async function saveUserData(user) {
	const vipDataTimeStamp = localStorage.getItem('vipDataTimeStamp')
	const lastPeek = localStorage.getItem('lastPeek') && JSON.parse(localStorage.getItem('lastPeek'))
	const profileViews = localStorage.getItem('profileViews') && JSON.parse(localStorage.getItem('profileViews'))
	const phoneCall = localStorage.getItem('phoneCall') && JSON.parse(localStorage.getItem('phoneCall'))
	const playVideo = localStorage.getItem('playVideo') && JSON.parse(localStorage.getItem('playVideo'))
	const whatsApp = localStorage.getItem('whatsApp') && JSON.parse(localStorage.getItem('whatsApp'))
	const yearOfBirth = localStorage.getItem('vipPosition') && JSON.parse(localStorage.getItem('yearOfBirth'))
	const bodyDescription = localStorage.getItem('bodyDescription') && JSON.parse(localStorage.getItem('bodyDescription'))
	const suburb = localStorage.getItem('suburb') && JSON.parse(localStorage.getItem('suburb'))

	const vipPosition = localStorage.getItem('vipPosition') && JSON.parse(localStorage.getItem('vipPosition'))
	const ip_continent_name = vipPosition && vipPosition.continent_name ? vipPosition.continent_name : null
	const ip_country_code = vipPosition && vipPosition.country_code ? vipPosition.country_code : null
	const ip_country_name = vipPosition && vipPosition.country_name ? vipPosition.country_name : null
	const ip_carrier = vipPosition && vipPosition.carrier ? vipPosition.carrier : null

	// console.log('userId', user.user.uid)

	// let userExists = await https://europe-west1-ooty-a0873.cloudfunctions.net/scrutiniseUser?userId=${user.user.uid}
	// console.log('userExists', userExists)

	return firebase
		.firestore()
		.collection('players')
		.doc(user.user.uid)
		.set(
			{
				ip_carrier,
				ip_continent_name,
				ip_country_code,
				ip_country_name,
				lastPeek,
				profileViews,
				phoneCall,
				playVideo,
				whatsApp,
				yearOfBirth,
				bodyDescription,
				suburb,
				vipDataTimeStamp,
				role: 'guest',
			},
			{ merge: true }
		)
		.catch((error) => {
			firebase.analytics().logEvent('auth_error', {
				category: 'Auth',
				action: error,
				label: 'Error setting auth user',
			})
		})
}

export async function auth(email, pw, username) {
	if (firebaseAuth().currentUser) {
		const uid = firebaseAuth().currentUser.uid
		if (firebaseAuth().currentUser.isAnonymous && uid) {
			let credential = firebaseAuth.EmailAuthProvider.credential(email, pw)
			return await firebaseAuth()
				.currentUser.linkWithCredential(credential)
				.then(() => {
					const usernameAmended = username ? username.replaceAll(' ', '_') : null
					firebase.firestore().collection('players').doc(uid).set({
						username: usernameAmended,
					})
				})
				.then(() => toast('Yipee, Account has been successfully created'))
		}
	} else {
		return await firebaseAuth()
			.createUserWithEmailAndPassword(email, pw)
			.then(() => toast('Account successfully created'))
	}
}

export async function logout() {
	firebaseAuth()
		.signOut()
		.then(() => {
			window.location.replace('/disclaimer')
			toast('You have successfully logged out')
			// localStorage.clear()
		})
}

export async function login(email, pw) {
	await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
	const credentials = await firebaseAuth().signInWithEmailAndPassword(email, pw)
	await saveUserData(credentials)
	return credentials
}

export async function loginWithGoogle() {
	const provider = new firebase.auth.GoogleAuthProvider()
	// provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
	provider.addScope('https://www.googleapis.com/auth/userinfo.email')
	// provider.addScope('email')

	firebase
		.auth()
		.signInWithPopup(provider)
		.then((result) => {
			if (result.user) {
				saveUserData(result)
				toast('You have successfully logged in')
				return result
			}
		})
		.catch((error) => {
			firebase.analytics().logEvent('auth_error', {
				category: 'Auth',
				action: error.message,
				label: 'Error Gmail login',
			})
		})
}

export async function resetPassword(email) {
	return firebaseAuth()
		.sendPasswordResetEmail(email)
		.then(() => {
			toast(`Password reset email sent to ${email}`)
		})
		.catch((error) => {
			toast(`Email address not found.`)
		})
}
