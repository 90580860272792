import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { auth } from './helpers/auth'
import { Link } from 'react-router-dom'
import { Form, Input } from 'antd'
import SEO from './common/SEO'
import { IRegisterForm } from '../interfaces/IRegisterForm'
import '../styles/styles.scss'
import '../styles/profile.scss'
import logError from '../utils/logError'

function Register(): JSX.Element {
	const history = useHistory()
	const [registerError, setRegisterError] = useState<string | null>(null)

	const onFinish = (values: IRegisterForm) => {
		auth(values.email, values.password, values.username)
			.then(() => {
				const savedRoute = sessionStorage.getItem('savedRoute')
				sessionStorage.removeItem('savedRoute')
				history.push(savedRoute ? savedRoute : '/dashboard')
			})
			.catch((e) => {
				setRegisterError(`Error registering user: ${e.message}`)
				logError(`Error registering user: ${e}`, 'Register', 'handleSubmit')
			})
	}

	return (
		<>
			<SEO url={window.location.href} title={'Register'} image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'} />
			<div className="col-sm-6 col-sm-offset-3">
				<h1 style={{ marginLeft: '5rem' }}>Register</h1>
				<Form name="register-form" onFinish={onFinish} layout="vertical">
					<Form.Item label="Preferred username" style={{ marginLeft: '5rem', marginBottom: '0', display: 'block' }}>
						<Form.Item name="username" noStyle rules={[{ required: true, message: 'Please input your preferred username!' }]}>
							<Input
								className="form-control"
								placeholder="Preferred username"
								style={{ marginLeft: 0, maxWidth: '300px' }}
								maxLength={30}
							/>
						</Form.Item>
					</Form.Item>
					<Form.Item label="Email" style={{ marginLeft: '5rem', marginBottom: '0', display: 'block', marginTop: '2rem' }}>
						<Form.Item name="email" noStyle rules={[{ required: true, type: 'email', message: 'Please type your email!' }]}>
							<Input className="form-control" placeholder="Email" style={{ marginLeft: 0, maxWidth: '300px' }} />
						</Form.Item>
					</Form.Item>
					<Form.Item label="Password" style={{ marginLeft: '5rem', marginBottom: '0', display: 'block', marginTop: '2rem' }}>
						<Form.Item name="password" noStyle rules={[{ required: true, message: 'Please type your password!' }]}>
							<Input.Password className="form-control" placeholder="Password" style={{ marginLeft: 0, maxWidth: '300px' }} />
						</Form.Item>
					</Form.Item>
					{registerError && (
						<div
							className="alert alert-danger"
							role="alert"
							style={{ marginLeft: '3rem', marginBottom: '3rem', marginTop: '2rem' }}
						>
							<span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true" />
							<span className="sr-only">Error:</span>
							&nbsp;{registerError}
						</div>
					)}

					<button
						type="submit"
						className="btn btn-primary psych-btn"
						style={{ marginLeft: '5rem', marginTop: '2rem' }}
						data-test="register-button"
					>
						Register →
					</button>
				</Form>
				<div className="alert alert-danger" role="alert" style={{ marginLeft: '3rem', marginTop: '3rem' }}>
					{/* <Link to="/login/" onClick={resetPassword} className="alert-link">
								Forgot Password?
							</Link> */}
					<Link to="/login/" className="alert-link" style={{ textDecoration: 'underline' }}>
						Login →
					</Link>
				</div>
			</div>
		</>
	)
}

export default Register
