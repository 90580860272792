import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'
import 'firebase/performance'
import 'firebase/functions'
import 'firebase/app-check'

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

export const firebaseAuth = firebase.auth
export const firebaseFunctions = firebase.functions
export const awsUrl = process.env.REACT_APP_S3_BASE_URL
export const ROOT_URL = process.env.PUBLIC_URL

// Check that `window` is in scope for the analytics module!
if (typeof window !== 'undefined' && !firebase.apps.length && config) {
	firebase.initializeApp(config)
	// To enable analytics. https://firebase.google.com/docs/analytics/get-started
	if (config.measurementId) {
		firebase.analytics()
	}
}

// const appCheck = firebase.appCheck()
// appCheck.activate(new firebase.appCheck.ReCaptchaEnterpriseProvider('6LdQ-QUiAAAAAMWm8ckjFVWQLXNuESTbsZ7vnkGG'), true)
// appCheck.activate(new firebase.appCheck.ReCaptchaEnterpriseProvider('6Leo2QUiAAAAAK58EVjNZ3QS8XOHDo0tmNHvYIf7'), true)

export default firebase
