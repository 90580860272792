import React from 'react'
import { Helmet } from 'react-helmet-async'
import { ISeo } from '../../interfaces/ISeo'

export default function SEO(props: ISeo): JSX.Element {
	const { title, image, url } = props
	const fullTitle = `${title} - BootyVIP - List of Escorts in South Africa, Zimbabwe, Tanzania, Botswana and Kenya.`
	const lang = url.includes('.co.za') ? 'en-za' : 'en'

	return (
		<Helmet prioritizeSeoTags>
			{title && <title>{fullTitle}</title>}
			{title && <meta property="og:title" content={fullTitle} />}
			{title && <meta name="twitter:title" content={fullTitle} />}
			{image && <meta property="og:image" content={image} />}
			{image && <meta property="og:url" content={url} />}
			{image && <meta name="twitter:image" content={image} />}
			<link rel="canonical" href={url} />
			<link rel="alternate" hrefLang={lang} href={url} />
		</Helmet>
	)
}
