import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import firebase, { firebaseAuth } from './config/constants'
import { loginAnonymously } from './helpers/auth.js'
import ReactPlayer from 'react-player/lazy'
import mobileDetect from 'mobile-detect'
import { CircularProgress } from '@material-ui/core'
import { Button } from 'antd'
import { RightOutlined } from '@ant-design/icons'

import SEO from './common/SEO'
import logError from '../utils/logError'
import SinglePicture from './profile/SinglePicture'
import FavouriteButton from './profile/FavouriteButton'
import UploadMedia from './profile/UploadMedia'
import ProfileFooter from './ProfileFooter'
import RandomQuotes from './common/RandomQuotes'
import RegisterPop from './common/Modal'
import ModalPhoneConfirmation from './common/ModalPhoneConfirmation'
import ImageReorder from './imageReorder'
import ReviewWrite from './ReviewWrite'
import ReviewList from './ReviewList'
import ReportButton from './ui/Report/ReportButton'
import shareProfile from '../utils/shareProfile'
import { trackInteraction } from '../utils/trackProfileInteraction'
import ETravelHostPreferences from '../interfaces/enums/ETravelHostPreferences'

import '../styles/styles.scss'
import '../styles/profile.scss'

const db = firebase.firestore()

let country_code = localStorage.getItem('vipPosition') ? JSON.parse(localStorage.getItem('vipPosition')).country_code : null

class Profile extends Component {
	state = {
		contactsVisible: false,
		email: null,
		errorMessage: null,
		galleryOpen: false,
		isMobile: false,
		modalVisible: false,
		modalPhoneVisible: false,
		role: 'guest',
		selectedVIP: {},
		thisYear: new Date().getFullYear(),
		uid: null,
		verified: false,
		whatsAppBlock: '',
		whatsAppShareBlock: '',
		smsBlock: '',
		fullPhoneNumber: '',
		locationUrl: window.location.origin,
		meta: { image: '', title: '' },
		authed: false,
		loading: true,
		arrivalTime: null,
		country_code: null,
		currentPhoneContactType: null,
	}

	componentWillUnmount() {
		const departureTime = new Date().getTime()
		const timeSpentSeconds = Math.round((departureTime - this.state.arrivalTime) / 1000)
		trackInteraction(this.state?.uid, this.state.selectedVIP.workName, this.props.match.params.vipId, 'DURATION', timeSpentSeconds)
	}

	showContacts = async () => {
		await this.setWhatsApp()

		document.querySelector('#mobile').style.display = 'flex'
		document.querySelector('#contactsCover').style.display = 'none'

		firebase.analytics().logEvent('see_phone', {
			category: 'Profile-View',
			id: this.props.match.params.vipId,
			workName: this.state.selectedVIP.workName,
		})
	}

	setWhatsApp = async () => {
		let localeDomain = '.com'
		let phoneCode = ''
		if (this.state.selectedVIP.mobile) {
			if (this.state.country_code === 'ZA') {
				localeDomain = '.co.za'
			}

			if (this.state.selectedVIP.country === 'ZA') {
				phoneCode = 27
				localeDomain = '.co.za'
			} else if (this.state.selectedVIP.country === 'ZW') {
				phoneCode = 263
			} else if (this.state.selectedVIP.country === 'BW') {
				phoneCode = 267
			} else if (this.state.selectedVIP.country === 'KE') {
				phoneCode = 254
			} else if (this.state.selectedVIP.country === 'TZ') {
				phoneCode = 255
			}

			let fullPhoneNumber = `+${phoneCode}${this.state.selectedVIP.mobile.substring(1, 20)}`

			if (this.state.selectedVIP?.mobile.includes('+')) {
				fullPhoneNumber = this.state.selectedVIP.mobile
			}

			this.setState({
				fullPhoneNumber,
			})

			let whatsAppBlock = this.state.selectedVIP.whatsappOnly
				? `https://wa.me/${phoneCode}${this.state.selectedVIP.mobile.substring(1, 20)}?text=Hi%20${
						this.state.selectedVIP.workName
				  }.%20I%20saw%20your%20ad%20on%20BootyVIP${localeDomain}.%20How%20are%20you%20doing?`
				: this.state.selectedVIP.whatsApp
				? `https://wa.me/${phoneCode}${this.state.selectedVIP.whatsApp.substring(1, 20)}?text=Hi%20${
						this.state.selectedVIP.workName
				  }.%20I%20saw%20your%20ad%20on%20BootyVIP${localeDomain}.%20How%20are%20you%20doing?`
				: null

			if (this.state.selectedVIP?.whatsappOnly && this.state.selectedVIP.mobile.includes('+')) {
				whatsAppBlock = `https://wa.me/${this.state.selectedVIP.mobile.substring(1, 20)}?text=Hi%20${
					this.state.selectedVIP.workName
				}.%20I%20saw%20your%20ad%20on%20BootyVIP${localeDomain}.%20How%20are%20you%20doing?`
			}

			if (this.state.selectedVIP?.whatsApp && this.state.selectedVIP?.whatsApp.includes('+')) {
				whatsAppBlock = `https://wa.me/${this.state.selectedVIP.whatsApp.substring(1, 20)}?text=Hi%20${
					this.state.selectedVIP.workName
				}.%20I%20saw%20your%20ad%20on%20BootyVIP${localeDomain}.%20How%20are%20you%20doing?`
			}

			this.setState({
				whatsAppBlock,
			})

			const smsBlock = `sms:${fullPhoneNumber}?&body=Hi%20${this.state.selectedVIP.workName}.%20I%20saw%20your%20ad%20on%20BootyVIP${localeDomain}.%20How%20are%20you%20doing?`
			this.setState({
				smsBlock,
			})
		}
	}

	async componentDidMount() {
		this.setState({
			country_code,
		})

		let userRole = 'guest'

		firebase.auth().onAuthStateChanged((authUser) => {
			if (authUser) {
				this.setState({
					uid: authUser.uid,
					email: authUser.email,
				})
			} else {
				loginAnonymously()
			}
		})

		const isMobile = new mobileDetect(window.navigator.userAgent)
		if (isMobile.ua.includes('Mobile')) {
			this.setState({
				isMobile: true,
			})
		}

		const whatsAppShareBlock = `https://api.whatsapp.com/send/?text=${this.state.locationUrl}${this.props.location.pathname}&type=custom_url&app_absent=0`

		this.setState({
			whatsAppShareBlock,
		})

		this.removeListener = firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				this.setState({
					authed: true,
					loading: false,
					uid: user?.uid,
				})
				const trace = firebase.performance().trace('Profile')

				if (user.uid) {
					db.collection('kings')
						.doc(user.uid)
						.get()
						.then((doc) => {
							if (doc.exists) {
								this.setState({
									role: doc.data().role,
								})
								userRole = doc.data().role
							}
						})
				} else {
					db.collection('users')
						.doc(user.uid)
						.get()
						.then((doc) => {
							if (doc.exists) {
								this.setState({
									role: 'stock',
								})
								userRole = 'stock'
							} else {
								this.setState({
									role: 'guest',
								})
								userRole = 'guest'
							}
						})
				}

				trace.incrementMetric('Profile-View')
				const id = this.props.match.params.vipId
				let vip = {}
				vip.data = []

				const docRef = db.collection('users').doc(id)
				docRef
					.get()
					.then((doc) => {
						if (doc.exists) {
							if (doc.data().active === false && userRole !== 'king' && doc.data().uid !== firebaseAuth().currentUser?.uid) {
								this.setState({
									errorMessage: { title: 'Uh oh..', body: 'This advert is no longer available' },
								})
								logError('Advert no longer available', 'Profile', 'removeListener')
								firebase.analytics().logEvent('profile_view', {
									category: 'Profile-View',
									action: 'Advert no longer available',
									nonInteraction: true,
								})
							} else {
								this.setState({
									arrivalTime: new Date().getTime(),
								})

								vip.data = doc.data()

								this.setState({
									selectedVIP: { ...vip.data, id: doc.id },
								})
								this.setState({
									verified: vip.data.verified,
								})

								localStorage.setItem(
									'profileViews',
									parseInt(localStorage.getItem('profileViews') ? localStorage.getItem('profileViews') : 0) + 1
								)

								firebase.analytics().logEvent('profile_view', {
									category: 'Profile-View',
									action: `View - ${vip.data.workName} - ${id}`,
									workName: vip.data.workName,
								})

								const metaImage =
									vip.data.pics && vip.data.pics.length > 0
										? vip.data.pics[vip.data.pics.length - 1]
										: process.env.REACT_APP_S3_BASE_URL + 'static/profiles/images/person.png'

								this.setState({
									meta: {
										image: metaImage.startsWith('static/') ? process.env.REACT_APP_S3_BASE_URL + metaImage : metaImage,
										title: `${vip.data.workName} - ${vip.data.suburb} - ${vip.data.city}`,
									},
								})

								const ogDescription = document.querySelector('meta[property="og:description"]')
								if (ogDescription) {
									ogDescription.setAttribute('content', `${vip.data.tagline} - ${vip.data.workName} - BootyVIP`)

									document
										.querySelector('meta[name="twitter:description"]')
										.setAttribute('content', `${vip.data.tagline} - ${vip.data.workName} - BootyVIP`)
								}

								const ogUrl = document.querySelector('meta[property="og:url"]')
								if (ogUrl) {
									ogUrl.setAttribute(
										'content',
										`https://${window.location.hostname}/profile/${this.props.match.params.vipId}`
									)
								}
							}
							this.loadPreferences('yearOfBirth', vip.data.yearOfBirth)
							this.loadPreferences('bodyDescription', vip.data.bodyDescription)
							this.loadPreferences('suburb', vip.data.suburb)
							localStorage.setItem('lastPeek', Date.now())
						} else {
							this.setState({
								errorMessage: { title: 'Uh oh..', body: "We can't find this advert" },
							})
							firebase.analytics().logEvent('profile_view', {
								category: 'Profile-View',
								action: 'No such profile',
								nonInteraction: true,
							})
							logError(`We can't find this advert`, 'Profile', 'removeListener')
						}
					})
					.catch((error) => {
						firebase.analytics().logEvent('profile_view', {
							category: 'Profile-View',
							action: 'Firebase Error',
							label: `Error getting profile: ${error}`,
							nonInteraction: true,
							id: this.props.match.params.vipId,
						})
						logError(`Error getting profile: ${error}`, 'Profile', 'removeListener')
					})
			} else {
				this.setState({
					authed: false,
					loading: false,
				})
			}
		})
	}

	notFoundRedirectToDiscover = () => {
		firebase.analytics().logEvent('profile_not_found_redirect', {
			category: 'Redirect',
			action: 'Redirect Profile Not Found',
		})
		this.props.history.push('/discover/')
	}

	loadPreferences = (spec, description) => {
		let b
		if (localStorage.getItem(spec)) {
			b = JSON.parse(localStorage.getItem(spec))
			b.push(description)
			b.sort()
		} else {
			b = [description]
		}
		localStorage.setItem(spec, JSON.stringify(b))
	}

	handleRegisterModal = (status) => {
		this.setState({ modalVisible: status })
	}

	handlePhoneModal = (status, type) => {
		this.setState({ modalPhoneVisible: status })
		this.setState({ currentPhoneContactType: type })
	}

	zoomImages = (action) => {
		const workName = this.state.selectedVIP.workName
		const vipId = this.props.match.params.vipId

		if (this.state.isMobile) {
			if (action === 'open') {
				document.querySelector('#profile-details').style.display = 'none'
				document.querySelector('#display-image').style.width = '100%'
				this.setState({
					galleryOpen: true,
				})
				firebase.analytics().logEvent('zoom_profile_images', {
					category: 'Zoom Images',
					action: `Zoom Images - ${workName} - ${vipId}`,
					workName: workName,
				})
			} else {
				document.querySelector('#profile-details').style.display = 'block'
				document.querySelector('#display-image').style.width = '50%'
				this.setState({
					galleryOpen: false,
				})
			}
		}
	}

	profileStatus = (status) => {
		let vipId = this.props.match.params.vipId

		const profileRef = firebase.firestore().collection('users').doc(vipId)

		if (status === 'deploy') {
			profileRef
				.update({
					active: true,
				})
				.then(() => {
					this.props.history.push('/verify/')
				})
				.catch((error) => {
					logError(`Error updating profile: ${error}`, 'Profile', 'profileStatus')
				})
		} else if (status === 'verify') {
			profileRef
				.update({
					verified: true,
				})
				.then(() => {
					this.setState({ verified: true })
				})
				.catch((error) => {
					logError(`Error updating profile: ${error}`, 'Profile', 'profileStatus')
				})
		} else if (status === 'delete') {
			profileRef
				.delete()
				.then(() => {
					this.props.history.push('/verify/')
				})
				.catch((error) => {
					logError(`Error removing profile: ${error}`, 'Profile', 'profileStatus')
				})
		} else if (status === 'deactivate') {
			profileRef
				.update({
					active: false,
				})
				.then(() => {
					this.props.history.push('/verify/')
				})
				.catch((error) => {
					logError(`Error deactivating profile: ${error}`, 'Profile', 'profileStatus')
				})
		}
	}

	render() {
		const selectedVIP = this.state.selectedVIP.workName ? this.state.selectedVIP : null

		return (
			<>
				<SEO url={window.location.href} title={this.state.meta.title} image={this.state.meta.image} />
				{this.state.authed && selectedVIP !== null ? (
					<>
						<div className="container">
							<div className="display_image" id="display-image">
								{this.state.isMobile && this.state.galleryOpen && (
									<div id="close-gallery" onClick={() => this.zoomImages('close')}>
										<img
											src={`${process.env.REACT_APP_S3_BASE_URL}static/icons/close-icon.svg`}
											height="30"
											width="30"
											alt="Close gallery"
										/>
									</div>
								)}
								<br />
								{selectedVIP.videoUrls && selectedVIP.videoUrls.length > 0
									? selectedVIP.videoUrls.map((video, index) => (
											<div
												className="video"
												key={index}
												onClick={() =>
													trackInteraction(
														this.state?.uid,
														this.state.selectedVIP.workName,
														this.props.match.params.vipId,
														'videoPlay'
													)
												}
											>
												<ReactPlayer
													url={video.includes('static') ? process.env.REACT_APP_S3_BASE_URL + video : video}
													loop
													controls
													config={{
														file: {
															attributes: {
																controlsList: 'nodownload',
																preload: 'none',
															},
														},
													}}
													width="90%"
												/>
											</div>
									  ))
									: selectedVIP.videoLink && (
											<div
												className="video"
												onClick={() =>
													trackInteraction(
														this.state?.uid,
														this.state.selectedVIP.workName,
														this.props.match.params.vipId,
														'videoPlay'
													)
												}
											>
												<ReactPlayer
													url={
														selectedVIP.videoLink.includes('static')
															? process.env.REACT_APP_S3_BASE_URL + selectedVIP.videoLink
															: selectedVIP.videoLink
													}
													loop
													controls
													config={{
														file: {
															attributes: {
																controlsList: 'nodownload',
																preload: 'none',
															},
														},
													}}
													onClick={() =>
														trackInteraction(
															this.state?.uid,
															this.state.selectedVIP.workName,
															this.props.match.params.vipId,
															'videoPlay'
														)
													}
													width="90%"
												/>
											</div>
									  )}
								{firebaseAuth().currentUser?.email
									? selectedVIP.pics &&
									  selectedVIP.pics.length > 0 &&
									  selectedVIP.pics.map((pic, index) => (
											<SinglePicture
												index={index}
												pic={pic}
												selectedVIP={selectedVIP}
												isMobile={this.state.isMobile}
												galleryOpen={this.state.galleryOpen}
												key={`image_${index}`}
												zoomImages={this.zoomImages}
												// onClick={() => this.zoomImages('open')}
												isKing={this.state.role === 'king'}
												isOwner={this.state?.uid === selectedVIP.uid}
												uid={this.state?.uid}
											/>
									  ))
									: selectedVIP.pics.map((pic, index) =>
											index < 2 ? (
												<SinglePicture
													index={index}
													pic={pic}
													selectedVIP={selectedVIP}
													isMobile={this.state.isMobile}
													galleryOpen={this.state.galleryOpen}
													key={`image_${index}`}
													zoomImages={this.zoomImages}
													onClick={() => this.zoomImages('open')}
													isKing={this.state.role === 'king'}
													isOwner={this.state?.uid === selectedVIP.uid}
													uid={this.state?.uid}
												/>
											) : (
												<img
													src="/static/images/blurred.jpg"
													alt={selectedVIP.workName}
													key={index}
													onClick={this.handleRegisterModal}
													style={{ cursor: 'pointer' }}
												/>
											)
									  )}
							</div>

							<div className="profile-details" id="profile-details">
								<p className="workName">{selectedVIP.workName}</p>
								<>
									<div className="contactsCover" onClick={this.showContacts} id="contactsCover">
										<u>Show Phone</u>
									</div>
									<div className="mobile" style={{ display: 'none' }} id="mobile">
										<div className="mobileNumber contactDetail">
											<a
												onClick={() => {
													trackInteraction(
														this.state.uid,
														this.state.selectedVIP.workName,
														this.props.match.params.vipId,
														'mobileCall'
													)
													this.handlePhoneModal(true, 'phone')
												}}
												href={`tel:${this.state.fullPhoneNumber}`}
												target="_blank"
												rel="noreferrer noopener"
											>
												{selectedVIP.mobile.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}{' '}
											</a>
										</div>
										{this.state.whatsAppBlock && (
											<div
												className="contactDetail"
												onClick={() => {
													trackInteraction(
														this.state.uid,
														this.state.selectedVIP.workName,
														this.props.match.params.vipId,
														'whatsApp'
													)
													this.handlePhoneModal(true, 'whatsApp')
												}}
											>
												<a href={this.state.whatsAppBlock} target="_blank" rel="noreferrer noopener">
													<img
														src="/static/icons/whatsapp-icon.svg"
														height="18"
														width="114"
														alt="Send a WhatsApp message"
														style={{ verticalAlign: 'top', marginLeft: '7px', marginRight: '7px' }}
													/>
												</a>
											</div>
										)}
										{this.state.isMobile && (
											<div className="contactDetail">
												<a
													onClick={() => {
														trackInteraction(
															this.state.uid,
															this.state.selectedVIP.workName,
															this.props.match.params.vipId,
															'mobileCall'
														)
														this.handlePhoneModal(true, 'phone')
													}}
													href={`tel:${this.state.fullPhoneNumber}`}
													target="_blank"
													rel="noreferrer noopener"
												>
													<img
														src={`${process.env.REACT_APP_S3_BASE_URL}static/icons/phone-icon.svg`}
														height="18"
														width="90"
														alt="Start a phone call"
														style={{ verticalAlign: 'top', marginLeft: '7px', marginRight: '7px' }}
													/>
												</a>
											</div>
										)}
										{this.state.isMobile && (
											<div className="contactDetail">
												<a
													onClick={() =>
														trackInteraction(
															this.state.uid,
															this.state.selectedVIP.workName,
															this.props.match.params.vipId,
															'sms'
														)
													}
													href={this.state.smsBlock}
													target="_blank"
													rel="noreferrer noopener"
												>
													<img
														src="/static/icons/sms-icon.svg"
														height="18"
														width="80"
														alt="Send SMS"
														style={{ verticalAlign: 'top', marginLeft: '7px', marginRight: '7px' }}
													/>
												</a>
											</div>
										)}
									</div>
									{navigator.share && (
										<div
											onClick={() => {
												shareProfile(this.state.selectedVIP)
												trackInteraction(
													this.state.uid,
													this.state.selectedVIP.workName,
													this.props.match.params.vipId,
													'share'
												)
											}}
											style={{ cursor: 'pointer' }}
											className="contactDetail"
										>
											<img
												src={`${process.env.REACT_APP_S3_BASE_URL}static/icons/share-icon.svg`}
												height="18"
												width="90"
												alt="Share this profile"
												style={{ verticalAlign: 'top', marginLeft: '11px', marginRight: '7px' }}
											/>
										</div>
									)}
									<div
										className="contactDetail"
										onClick={() =>
											trackInteraction(
												this.state.uid,
												this.state.selectedVIP.workName,
												this.props.match.params.vipId,
												'whatsAppShare'
											)
										}
									>
										<a href={this.state.whatsAppShareBlock} target="_blank" rel="noreferrer noopener">
											<img
												src="/static/icons/whatsapp-share-icon.svg"
												height="18"
												width="145"
												alt="Share on WhatsApp"
												style={{ verticalAlign: 'top', marginLeft: '0px', marginRight: '7px' }}
											/>
										</a>
									</div>
								</>
								<p className="tagline">{selectedVIP.tagline}</p>
								{(this.state.country_code === 'ZA' ||
									this.state.country_code === 'ZW' ||
									this.state.country_code === 'KE' ||
									this.state.country_code === 'BW' ||
									this.state.country_code === 'TZ') && (
									<p style={{ marginTop: '15px', fontWeight: 300, fontSize: '12px', marginBottom: '14px' }}>
										* Remember to mention you got the number from BootyVIP
									</p>
								)}

								{selectedVIP.active && <FavouriteButton selectedVIP={selectedVIP} uid={this.state.uid} />}

								{selectedVIP.travelOnly || selectedVIP.hostAndTravelPreference === ETravelHostPreferences.TravelOnly ? (
									<p className="profile">
										<strong>Location:</strong>
										{'I travel to you'}
									</p>
								) : (
									<>
										<p className="profile">
											<strong>Location:</strong>
											<Link className="location-link" to={encodeURI(`/browse/suburb/${selectedVIP.suburb}`)}>
												{selectedVIP.suburb}
											</Link>{' '}
											in{' '}
											<Link className="location-link" to={encodeURI(`/browse/city/${selectedVIP.city}`)}>
												{selectedVIP.city}
											</Link>{' '}
											{selectedVIP.hostOnly || selectedVIP.hostAndTravelPreference === ETravelHostPreferences.HostOnly
												? '(I host only)'
												: '(I host and travel)'}
										</p>
										{selectedVIP.premise && (
											<p className="profile">
												<strong>Premises:</strong>
												{`${selectedVIP.premise}`}
											</p>
										)}
										{selectedVIP.parking && (
											<p className="profile">
												<strong>Parking:</strong>
												{`${selectedVIP.parking}`}
											</p>
										)}
									</>
								)}
								{selectedVIP.yearOfBirth && (
									<p className="profile">
										<strong>Age:</strong> {this.state.thisYear - selectedVIP.yearOfBirth}
									</p>
								)}
								{selectedVIP.height && selectedVIP.height > 1 && (
									<p className="profile">
										<strong>Height:</strong> {selectedVIP.height}m
									</p>
								)}
								{selectedVIP.ethnicity && (
									<p className="profile">
										<strong>Ethnicity:</strong> {selectedVIP.ethnicity}
									</p>
								)}
								{selectedVIP.bust && selectedVIP.gender !== 'Male' ? (
									selectedVIP.bust.length >= 3 ? (
										<p className="profile">
											<strong>Bust:</strong> {selectedVIP.bust}
										</p>
									) : null
								) : null}
								{selectedVIP.gender ? (
									<p className="profile">
										<strong>Gender:</strong> {selectedVIP.gender}
									</p>
								) : null}
								{selectedVIP.bodyDescription ? (
									<p className="profile">
										<strong>Body:</strong> {selectedVIP.bodyDescription}
									</p>
								) : null}
								{selectedVIP.song && (
									<p className="profile">
										<strong>Favourite song:</strong> {selectedVIP.song}
									</p>
								)}
								{selectedVIP.drink && (
									<p className="profile">
										<strong>Favourite drink:</strong> {selectedVIP.drink}
									</p>
								)}
								{selectedVIP.series && (
									<p className="profile">
										<strong>Favourite series:</strong> {selectedVIP.series}
									</p>
								)}
								{selectedVIP.languages && selectedVIP.languages.length > 0 && (
									<p className="profile">
										<strong>Languages:</strong> {selectedVIP.languages.join(', ')}
									</p>
								)}
								{selectedVIP.hairColour && (
									<p className="profile">
										<strong>Hair colour:</strong> {selectedVIP.hairColour}
									</p>
								)}
								{selectedVIP.eyeColour && (
									<p className="profile">
										<strong>Eye colour:</strong> {selectedVIP.eyeColour}
									</p>
								)}
								<p className="profile">
									<strong>Tattoos:</strong> {selectedVIP.tattoos ? 'Yes' : 'None'}
								</p>
								{selectedVIP.starSign && (
									<p className="profile">
										<strong>Star sign:</strong> {selectedVIP.starSign}
									</p>
								)}
								{selectedVIP.iLike && (
									<p className="profile">
										<strong>I like:</strong> {selectedVIP.iLike}
									</p>
								)}
								{selectedVIP.iHate && (
									<p className="profile">
										<strong>I don't like:</strong> {selectedVIP.iHate}
									</p>
								)}
								<ReportButton
									vipId={this.props.match.params.vipId}
									name={selectedVIP.workName}
									uid={this.state.uid}
									photo={selectedVIP?.pics[selectedVIP?.pics.length - 1]}
								/>
							</div>
						</div>
						{this.state.role === 'king' && (
							<div style={{ padding: '20px' }}>
								{selectedVIP.verified && (
									<p className="profile" style={{ color: '#1DA57A' }}>
										Verified +++
									</p>
								)}
								{!selectedVIP.active && !this.state.verified && (
									<Button type="primary" onClick={() => this.profileStatus('verify')}>
										Verify Profile
									</Button>
								)}{' '}
								<Button type="warning" onClick={() => this.profileStatus('delete')}>
									Delete Profile
								</Button>{' '}
								{!selectedVIP.active && (
									<Button type="primary" onClick={() => this.profileStatus('deploy')}>
										Activate Profile
									</Button>
								)}{' '}
								{selectedVIP.active && (
									<Button type="primary" onClick={() => this.profileStatus('deactivate')}>
										Deactivate Profile
									</Button>
								)}{' '}
								<Button
									type="primary"
									onClick={() => {
										this.props.history.push(`/advertise/${this.props.match.params.vipId}`)
									}}
								>
									Edit Profile
								</Button>
								<br />
								{selectedVIP.pics && selectedVIP.pics.length > 0 && (
									<div style={{ margin: '20px 0' }}>
										<div className="profile" style={{ color: 'rgb(255,255,255)', marginBottom: '10px' }}>
											Gallery Photos:
											<br />
											Gallery Photo Order (Last picture is your profile photo):
										</div>
										<ImageReorder
											pics={selectedVIP.pics}
											adId={this.props.match.params.vipId}
											isMobile={this.state.isMobile}
										/>
									</div>
								)}
							</div>
						)}

						{this.state.role === 'king' || selectedVIP?.uid === firebaseAuth().currentUser?.uid ? (
							<UploadMedia vipId={this.props.match.params.vipId} selectedVIP={selectedVIP} />
						) : null}

						{this.state.email && selectedVIP.uid !== firebaseAuth().currentUser?.uid ? (
							<div className="container container_reviews">
								<div className="display_reviews">
									<ReviewWrite profileId={this.props.match.params.vipId} workName={selectedVIP.workName} />
								</div>
							</div>
						) : !this.state.email ? (
							<div
								className="container container_reviews"
								style={{
									width: '100%',
									textAlign: 'center',
									alignItems: 'center',
									marginTop: '20px',
									justifyContent: 'center',
								}}
							>
								<div
									className="display_reviews"
									style={{ padding: '20px', borderRadius: '15px', backgroundColor: '#2E303B', width: '70%' }}
								>
									To review, please
									<Link to="/login">
										<u>LOGIN</u>
									</Link>
									or
									<Link to="/register">
										<u>REGISTER</u>
									</Link>
									, it's free.
								</div>
							</div>
						) : null}

						<div className="container container_reviews">
							<div className="display_reviews container_reviews" style={{ padding: '20px' }}>
								<ReviewList
									profileId={this.props.match.params.vipId}
									workName={selectedVIP.workName}
									role={this.state.role}
									isMyProfile={selectedVIP.uid === firebaseAuth().currentUser?.uid}
								/>
							</div>
						</div>

						<div className="container container_reviews" style={{ padding: '20px' }}>
							<ProfileFooter />
						</div>
					</>
				) : (
					<div>
						{this.state.errorMessage === null ? (
							<div className="profile" style={{ textAlign: 'center', marginTop: '70px' }}>
								<RandomQuotes />
								<br />
								<br />
								<br />
								<div id="loader">
									<CircularProgress color="secondary" />
								</div>
							</div>
						) : (
							<div className="profile" style={{ textAlign: 'center', marginTop: '70px' }}>
								<p style={{ fontSize: '36px' }}>{this.state.errorMessage.title}</p>
								<p>{this.state.errorMessage.body}</p>
								<Button
									onClick={this.notFoundRedirectToDiscover}
									type="primary"
									block
									htmlType="submit"
									size="large"
									style={{
										color: 'white',
										background: '#ee1e3b',
										borderColor: '#ee1e3b',
										cursor: 'pointer',
										width: '200px',
									}}
									aria-label="Go to discover"
								>
									<strong style={{ color: 'white' }}>Go to discover</strong> <RightOutlined />
								</Button>
							</div>
						)}
					</div>
				)}
				<RegisterPop open={this.state.modalVisible} handleRegisterModal={this.handleRegisterModal} />
				<ModalPhoneConfirmation
					open={this.state.modalPhoneVisible}
					handlePhoneModal={this.handlePhoneModal}
					profile={this.state.selectedVIP}
					uid={this.state.uid}
					vipId={this.props.match.params.vipId}
					type={this.state.currentPhoneContactType}
				/>
			</>
		)
	}
}

export default withRouter(React.memo(Profile))
