import React, { useState } from 'react'
import TrashCan from './TrashCan'
import firebase from '../config/constants'
import { toast } from 'react-toastify'
import moment from 'moment'
import 'react-toastify/dist/ReactToastify.css'

toast.configure()
const db = firebase.firestore()

const SinglePicture = ({ index, pic, selectedVIP, isMobile, galleryOpen, isKing, isOwner, uid, zoomImages }) => {
	const [deletedPic, setDeletedPic] = useState(null)

	const archivePicture = () => {
		try {
			const details = {
				advertId: selectedVIP.id,
				url: pic,
				dateDeleted: moment().utc().valueOf(),
				deleter: isKing ? 'KING' : 'OWNER',
				deleterUid: uid,
			}
			db.collection('deletedPictures').add(details)
		} catch (error) {
			toast(`Error. ${error.message}`)
		}
	}

	const deletePicture = () => {
		archivePicture()
		let originalData = {}
		db.collection('users')
			.doc(selectedVIP.id)
			.get()
			.then((doc) => {
				if (doc.exists) {
					originalData = doc.data()
				}
			})
			.then(() => {
				const newPhotosArray = originalData.pics.filter((picture) => picture !== pic)
				if (originalData.pics.length - newPhotosArray.length === 1) {
					try {
						db.collection('users')
							.doc(selectedVIP.id)
							.set({ ...originalData, pics: newPhotosArray }, { merge: true })
							.then(() => {
								toast('Picture deleted successfully', 500)
								setDeletedPic(pic)
							})
					} catch (error) {
						toast(`Error. ${error.message}`)
					}
				}
			})
	}

	return (
		<>
			{index === 0 && galleryOpen === false && isMobile && (
				<span onClick={() => zoomImages('open')}>
					<img src={`${process.env.REACT_APP_S3_BASE_URL}static/icons/fullscreen-icon.svg`} height="12" alt="Zoom images" />
				</span>
			)}
			{deletedPic !== pic && (
				<div className="single-image" onClick={() => zoomImages('open')}>
					<img
						src={pic.includes('static') ? process.env.REACT_APP_S3_BASE_URL + pic : pic}
						alt={`BootyVIP.com - Escort - Africa - ${selectedVIP.workName} - ${selectedVIP.suburb} - ${selectedVIP.city} - ${
							selectedVIP.country === 'ZW'
								? 'Zimbabwe'
								: selectedVIP.country === 'ZA'
								? 'South Africa'
								: selectedVIP.country === 'BW'
								? 'Botswana'
								: selectedVIP.country === 'KE'
								? 'Kenya'
								: selectedVIP.country === 'TZ'
								? 'Tanzania'
								: ''
						}`}
					/>
					{(isKing || isOwner) && <TrashCan action={deletePicture} />}
				</div>
			)}
		</>
	)
}

export default SinglePicture
