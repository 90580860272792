import firebase from '../components/config/constants'

interface ITrackProfileInteractionProps {
	uid: string;
	workName: string;
	vipId: string;
	type?: string;
	eventNames?: string[];
	timeSpentSeconds?: number;
}

interface ITrackPhoneStatusProps {
	uid: string;
	workName: string;
	vipId: string;
	type: string;
	mobileNumber: string;
	result: string;
}

const db = firebase.firestore()

export const trackPhoneStatus = ({ uid, workName, vipId, type, mobileNumber, result }: ITrackPhoneStatusProps) => {
	try {
		db.collection('phone_status').doc().set({
			uid,
			workName,
			vipId,
			type,
			mobileNumber,
			result,
			timestamp: firebase.firestore.FieldValue.serverTimestamp(),
			date: new Date().getTime(),
		})
	} catch (error) {
		console.error(`Error writing phone status`, error)
	}
}

export const trackInteraction = (uid: string, workName: string, vipId: string, type: string, timeSpentSeconds?: number) => {
	// export const trackInteraction = ({ uid, workName, vipId, type, timeSpentSeconds }: ITrackProfileInteractionProps) => {
	let eventNames: string[] = []
	if (type === 'mobileCall') {
		eventNames = ['phoneCall', 'phone_call', 'Phone Call']
	} else if (type === 'videoPlay') {
		eventNames = ['VIDEO_PLAY', 'VIDEO_PLAY', 'VIDEO_PLAY']
	} else if (type === 'whatsAppShare') {
		eventNames = ['whatsAppShare', 'whatsapp_share', 'WhatsApp Share']
	} else if (type === 'whatsApp') {
		eventNames = ['whatsApp', 'whatsapp', 'WhatsApp']
	} else if (type === 'sms') {
		eventNames = ['SMS', 'SMS', 'SMS']
	} else if (type === 'share') {
		eventNames = ['share', 'share', 'Share']
	} else if (type === 'DURATION') {
		eventNames = ['DURATION', 'DURATION', 'DURATION']
	} else if (type === 'FAVOURITE') {
		eventNames = ['FAVOURITE', 'FAVOURITE', 'FAVOURITE']
	} else if (type === 'UNFAVOURITE') {
		eventNames = ['UNFAVOURITE', 'UNFAVOURITE', 'UNFAVOURITE']
	}
	trackProfileInteraction({ uid, workName, vipId, eventNames, type, timeSpentSeconds })
}

const trackProfileInteraction = (props: ITrackProfileInteractionProps): void => {
	const { uid, workName, vipId, eventNames, timeSpentSeconds } = props

	if (eventNames && eventNames.length > 0) {
		const storedEvent: string | null = localStorage.getItem(eventNames[0])

		if (storedEvent) {
			localStorage.setItem(eventNames[0], String(Number(storedEvent) + 1))
		} else {
			localStorage.setItem(eventNames[0], '1')
		}

		if (uid) {
			db.collection('players')
				.doc(uid)
				.set(
					{
						[eventNames[0]]: storedEvent ? Number(JSON.parse(storedEvent)) : 1,
					},
					{ merge: true }
				)
				.catch((error) => {
					console.error(`Error writing ${eventNames[0]}: `, error)
				})
		}

		if (workName && vipId) {
			db.collection('adActions')
				.doc()
				.set(
					{
						action: eventNames[0].toUpperCase(),
						vipId,
						uid: uid ?? 'anonymous',
						datePosted: new Date().toISOString(),
						workName,
						timeSpentSeconds: timeSpentSeconds ? timeSpentSeconds : 0,
					},
					{ merge: true }
				)
				.catch((error) => {
					console.error(`Error writing adAction ${eventNames[0]}: `, error)
				})

			firebase.analytics().logEvent(eventNames[1], {
				category: eventNames[2],
				action: `${eventNames[0]} - ${workName} - ${vipId}`,
				workName: workName,
				label: vipId,
				timeSpentSeconds: timeSpentSeconds ? timeSpentSeconds : 0,
			})
		}
	}
}

export default trackProfileInteraction
