import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { loginAnonymously } from '../helpers/auth.js'
import firebase, { firebaseAuth } from '../config/constants'
import Home from '../PhotoCards'
import '../App.scss'
import { CircularProgress } from '@material-ui/core'
import { VIPContext } from '../../context/VIPContext'
import SEO from '../common/SEO'
import ETravelHostPreferences from '../../interfaces/enums/ETravelHostPreferences'

function TravelOnly(props) {
	const history = useHistory()
	const [, , globalVIPs] = useContext(VIPContext)
	const [selectedVIPs, setSelectedVIPs] = useState([])
	const [selectedCity] = useState(props.match.params.city)

	useEffect(() => {
		if (firebase) {
			firebase.auth().onAuthStateChanged((authUser) => {
				if (!authUser) {
					loginAnonymously()
				}
			})
		}
	}, [])

	useEffect(() => {
		const selectVIPs = (city) => {
			const meisies = globalVIPs.filter(
				(meisie) =>
					meisie.data.city === city &&
					(meisie.data.travelOnly || meisie.data.hostAndTravelPreference === ETravelHostPreferences.TravelOnly)
			)

			if (meisies.length > 0) {
				setSelectedVIPs([...meisies])

				firebase.analytics().logEvent('travel_only_view', {
					category: 'Travel-Only-View',
					action: 'Travel-only selected',
					label: 'travel_only',
				})
			} else {
				history.push('/discover/')
			}
		}
		if (globalVIPs.length > 0 && selectedCity && selectedVIPs.length === 0) {
			selectVIPs(selectedCity)
		}
	}, [selectedCity, globalVIPs, props, selectedVIPs, history])

	return (
		<>
			<SEO
				url={window.location.href}
				title={`Travel-Only ${selectedCity}`}
				image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'}
			/>
			<div className="App">
				{firebaseAuth().currentUser &&
					(selectedCity !== '' ? (
						<>
							<h5>{selectedCity} - Travel-only</h5>
							<Home allVIPs={selectedVIPs} />
						</>
					) : (
						<div id="loader">
							<CircularProgress color="secondary" />
						</div>
					))}
			</div>
		</>
	)
}

export default TravelOnly
