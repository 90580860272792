import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { Button, Rate } from 'antd'
import moment from 'moment'

import firebase from './config/constants'
import { loginAnonymously } from './helpers/auth.js'
import initialValues from '../constants/initialRatingValues'
import 'react-toastify/dist/ReactToastify.css'

toast.configure()

const db = firebase.firestore()

class ReviewBlock extends Component {
	state = {
		formValues: initialValues,
		reviewText: '',
		disabled: false,
		uid: null,
		username: null,
		profileId: this.props.profileId,
		rating: 0,
		likePhotosRating: 0,
		userRating: 0,
		userLikePhotosRating: 0,
		datePosted: '',
		dateEpoch: '',
		hasUsername: true,
		reviews: [],
	}

	componentDidMount() {
		if (firebase) {
			firebase.auth().onAuthStateChanged((authUser) => {
				if (authUser) {
					this.setState({
						uid: authUser.uid,
					})
					this.getReviews()
				} else {
					loginAnonymously()
				}
			})
		}

		this.setState({
			formValues: initialValues,
		})
	}

	updateInput = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		})
	}

	getReviews = () => {
		if (this.state.uid !== null) {
			if (this.props.role === 'king') {
				db.collection('users')
					.doc(this.state.profileId)
					.collection('allRatings')
					.where('dateEpoch', '>', 0)
					.orderBy('dateEpoch', 'desc')
					.onSnapshot((querySnapshot) => {
						let _reviews = []
						querySnapshot.forEach((doc) => {
							_reviews.push({ ...doc.data(), id: doc.id })
						})
						this.setState({ reviews: _reviews })
					})
			} else {
				db.collection('users')
					.doc(this.state.profileId)
					.collection('allRatings')
					.where('status', '==', 'live')
					.where('dateEpoch', '>', 0)
					.orderBy('dateEpoch', 'desc')
					.onSnapshot((querySnapshot) => {
						let _reviews = []
						querySnapshot.forEach((doc) => {
							_reviews.push({ ...doc.data(), id: doc.id })
						})
						this.setState({ reviews: _reviews })
					})
			}
		}
	}

	suspendReview = (id) => {
		const adRatingRef = db.collection('users').doc(this.state.profileId)
		if (id) {
			adRatingRef
				.collection('allRatings')
				.doc(id)
				.set(
					{
						status: 'suspended',
					},
					{ merge: true }
				)
				.catch((error) => {
					console.error('Error updating document: ', error)
				})
		}
	}

	unSuspendReview = (id) => {
		const adRatingRef = db.collection('users').doc(this.state.profileId)
		if (id) {
			adRatingRef
				.collection('allRatings')
				.doc(id)
				.set(
					{
						status: 'live',
					},
					{ merge: true }
				)
				.catch((error) => {
					console.error('Error updating document: ', error)
				})
		}
	}

	addRating = (postData) => {
		console.log('Values', postData)

		this.setState({
			datePosted: new Date().toISOString(),
			dateEpoch: Date.now(),
		})

		if (this.state.username) {
			db.collection('players').doc(this.state.uid).set(
				{
					username: this.state.username,
				},
				{ merge: true }
			)
		}

		// this.setState({ reviewText: postData.reviewText, rating: postData.rating })

		if (this.state.profileId && this.state.uid) {
			db.collection('players')
				.doc(this.state.uid)
				.collection('ratings')
				.doc(this.state.profileId)
				.get()
				.then((doc) => {
					if (doc.exists) {
						if (doc.data().rating > 0) {
							console.log('doc.data().rating', doc.data().rating)
							this.setState({ userRating: doc.data().rating })
						}
						if (doc.data().likePhotosRating > 0) {
							console.log('doc.data().likePhotosRating', doc.data().likePhotosRating)
							this.setState({ userLikePhotosRating: doc.data().likePhotosRating })
						}
					}
				})
				.then(() => {
					this.ratingCompleted()
				})
				.catch((error) => {
					// The document probably doesn't exist.
					console.error('Error updating document: ', error)
				})
		}
	}

	ratingCompleted = () => {
		this.handleUserRating(this.state.rating, this.state.likePhotosRating)
	}

	handleUserRating = (ratingValue, likePhotosRatingValue) => {
		const userReviewsRef = db.collection('players').doc(this.state.uid).collection('reviews').doc(this.state.profileId)
		const userRatingRef = db.collection('players').doc(this.state.uid).collection('ratings').doc(this.state.profileId)

		if (ratingValue) {
			userReviewsRef
				.set(
					{
						[this.state.dateEpoch]: {
							rating: ratingValue,
							likePhotosRating: likePhotosRatingValue,
							reviewText: this.state.reviewText,
							dateEpoch: this.state.dateEpoch,
							datePosted: this.state.datePosted,
						},
					},
					{ merge: true }
				)
				.then(() => {
					userRatingRef.set(
						{
							rating: likePhotosRatingValue,
							likePhotosRating: likePhotosRatingValue,
							reviewText: this.state.reviewText,
							dateEpoch: this.state.dateEpoch,
							datePosted: this.state.datePosted,
						},
						{ merge: true }
					)
				})
				.then(() => this.handleAdRating(ratingValue, likePhotosRatingValue))
				.catch((error) => {
					// The document probably doesn't exist.
					console.error('Error updating document: ', error)
				})
		}
	}

	getAgo = (date) => {
		// get time ago from moment.js
		return moment(date).fromNow()
	}

	handleAdRating = (ratingValue, likePhotosRatingValue) => {
		const adRatingRef = db.collection('users').doc(this.state.profileId)

		if (ratingValue) {
			if (this.state.userRating) {
				const difference = ratingValue - this.state.userRating
				console.log('ratingValue', ratingValue)
				console.log('this.state.userRating', this.state.userRating)
				console.log('difference', difference)

				adRatingRef
					.collection('rating')
					.doc('rateStats')
					.set(
						{
							sum: firebase.firestore.FieldValue.increment(difference),
						},
						{ merge: true }
					)
					.catch((error) => {
						// The document probably doesn't exist.
						console.error('Error updating document: ', error)
					})
			} else {
				console.log('ratingValue', ratingValue)

				adRatingRef
					.collection('rating')
					.doc('rateStats')
					.set(
						{
							sum: firebase.firestore.FieldValue.increment(ratingValue),
							rateCount: firebase.firestore.FieldValue.increment(1),
						},
						{ merge: true }
					)
					.catch((error) => {
						console.error('Error updating document: ', error)
					})
			}

			if (this.state.userLikePhotosRating) {
				const difference = likePhotosRatingValue - this.state.userLikePhotosRating
				adRatingRef
					.collection('likePhotosRating')
					.doc('rateStats')
					.set(
						{
							sum: firebase.firestore.FieldValue.increment(difference),
						},
						{ merge: true }
					)
					.catch((error) => {
						// The document probably doesn't exist.
						console.error('Error updating document: ', error)
					})
			} else {
				adRatingRef
					.collection('likePhotosRating')
					.doc('rateStats')
					.set(
						{
							sum: firebase.firestore.FieldValue.increment(likePhotosRatingValue),
							rateCount: firebase.firestore.FieldValue.increment(1),
						},
						{ merge: true }
					)
					.catch((error) => {
						console.error('Error updating document: ', error)
					})
			}
		}

		adRatingRef
			.collection('allRatings')
			.doc()
			.set(
				{
					uid: this.state.uid,
					rating: ratingValue,
					likePhotosRating: likePhotosRatingValue,
					dateEpoch: this.state.dateEpoch,
					datePosted: this.state.datePosted,
					username: this.state.username,
					reviewText: this.state.reviewText,
				},
				{ merge: true }
			)
			.catch((error) => {
				// The document probably doesn't exist.
				console.error('Error updating document: ', error)
			})
	}

	render() {
		// const formItemLayout = {
		// 	labelCol: {
		// 		xs: { span: 24 },
		// 		sm: { span: 8 },
		// 	},
		// 	wrapperCol: {
		// 		xs: { span: 24 },
		// 		sm: { span: 16 },
		// 	},
		// }
		// const tailFormItemLayout = {
		// 	wrapperCol: {
		// 		xs: {
		// 			span: 24,
		// 			offset: 0,
		// 		},
		// 		sm: {
		// 			span: 16,
		// 			offset: 8,
		// 		},
		// 	},
		// }

		return (
			this.state.uid && (
				<>
					{this.state.reviews && this.state.reviews.length > 0 && (
						<h3 style={{ color: '#ee1e3a', marginBottom: '30px', textAlign: 'left' }}>REVIEWS</h3>
					)}
					{this.state.reviews && this.state.reviews.length > 0 ? (
						this.state.reviews.map((review, index) => {
							return (
								<div key={index} className="review_block" id={review.id}>
									{review.status !== 'live' && (
										<div>
											<strong style={{ color: 'rgb(238, 30, 58)' }}>Status: {review.status}</strong>
										</div>
									)}
									<div className="reviewtext">
										<strong>Review:</strong> {review.reviewText}
									</div>
									<div>
										<strong>The experience:</strong>
										<Rate
											name="rating"
											value={review.rating}
											disabled={true}
											style={{ fontSize: '15px', marginLeft: '10px' }}
										/>
									</div>
									<div>
										<strong>Looks like the photos:</strong>
										<Rate
											name="likePhotosRating"
											value={review.likePhotosRating}
											disabled={true}
											style={{ fontSize: '15px', marginLeft: '10px' }}
										/>
									</div>
									<div>
										<strong style={{ color: 'rgb(238, 30, 58)' }}>{review.username}</strong>{' '}
										{this.getAgo(review.dateEpoch)}
									</div>

									<div>
										{this.props.role === 'king' &&
											(review.status === 'suspended' ? (
												<Button
													type="primary"
													htmlType="button"
													onClick={() => this.unSuspendReview(review.id)}
													style={{ marginTop: '10px' }}
												>
													Unsuspend
												</Button>
											) : (
												<Button
													type="button"
													htmlType="button"
													onClick={() => this.suspendReview(review.id)}
													style={{ marginTop: '10px' }}
												>
													Suspend this review
												</Button>
											))}
									</div>
								</div>
							)
						})
					) : !this.props.isMyProfile ? (
						<div>Be the first to review {this.props.workName}!</div>
					) : null}
				</>
			)
		)
	}
}
export default ReviewBlock
