import { useState, createContext, useEffect } from 'react'
import axios from 'axios'
import firebase from '../components/config/constants'
import { loginAnonymously } from '../components/helpers/auth'

export interface VIP {
	id: string;
	data: any;
}

interface VIPContextProviderProps {
	children: React.ReactNode;
}

export const VIPContext = createContext<
	[VIP[], React.Dispatch<React.SetStateAction<VIP[]>>, VIP[], React.Dispatch<React.SetStateAction<VIP[]>>, string]
>([[], () => {}, [], () => {}, ''])

const maxRetries = 5

export const VIPContextProvider: React.FC<VIPContextProviderProps> = (props) => {
	const [allVIPs, setAllVIPs] = useState<VIP[]>([])
	const [globalVIPs, setGlobalVIPs] = useState<VIP[]>([])
	const [countryCode, setCountryCode] = useState<string>('')
	const [authed, setAuthed] = useState<boolean>(false)
	const [retryCount, setRetryCount] = useState<number>(0)

	useEffect(() => {
		if (firebase) {
			firebase.auth().onAuthStateChanged((authUser) => {
				if (authUser) {
					setAuthed(true)
				} else {
					loginAnonymously().then((user) => {
						if (user !== null) {
							setAuthed(true)
						}
					})
				}
			})
		}
	}, [])

	useEffect(() => {
		let tooOld = true
		const storedDateStamp: string | null = localStorage.getItem('vipDataTimeStamp')
		const dataTimeStamp: string = storedDateStamp !== null ? JSON.parse(storedDateStamp) : null

		if (dataTimeStamp) {
			const vipDataTimeStamp = Date.parse(dataTimeStamp)
			const now = Date.parse(new Date().toString())
			const dataAge = Math.round((now - vipDataTimeStamp) / (1000 * 60 * 60 * 24))
			tooOld = dataAge >= 1
		}

		if (!localStorage.getItem('vipPosition') || tooOld) {
			axios
				.get(`https://api.ipdata.co?api-key=${process.env.REACT_APP_IPDATA_ACCESS_TOKEN}`)
				.then((res) => {
					let country_code = JSON.parse(localStorage.getItem('vipPosition') || '{}').country_code
					setCountryCode(country_code || '')
					localStorage.setItem('vipPosition', JSON.stringify(res.data))
					localStorage.setItem('vipDataTimeStamp', JSON.stringify(new Date()))
				})
				.catch((error) => {
					firebase.analytics().logEvent('store_vip_position_error', {
						category: 'Store VIP Position',
						action: error,
					})
				})
		} else {
			setCountryCode(JSON.parse(localStorage.getItem('vipPosition') || '{}').country_code || '')
		}
	}, [])

	useEffect(() => {
		if (globalVIPs && globalVIPs.length === 0 && authed) {
			let _ads: VIP[] = []
			let _globalAds: VIP[] = []

			firebase
				.firestore()
				.collection('users')
				.where('active', '==', true)
				.where('signupDate', '>', 0)
				.orderBy('signupDate', 'desc')
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						_globalAds.push({ id: doc.id, data: doc.data() })

						if (countryCode === 'ZA') {
							if (doc.data().country === 'ZA') {
								_ads.push({ id: doc.id, data: doc.data() })
							}
						} else if (countryCode === 'ZW') {
							if (doc.data().country === 'ZW') {
								_ads.push({ id: doc.id, data: doc.data() })
							}
						} else if (countryCode === 'BW') {
							if (doc.data().country === 'BW') {
								_ads.push({ id: doc.id, data: doc.data() })
							}
						} else if (countryCode === 'KE') {
							if (doc.data().country === 'KE') {
								_ads.push({ id: doc.id, data: doc.data() })
							}
						} else if (countryCode === 'TZ') {
							if (doc.data().country === 'TZ') {
								_ads.push({ id: doc.id, data: doc.data() })
							}
						} else {
							_ads.push({ id: doc.id, data: doc.data() })
						}
					})
				})
				.then(() => {
					setAllVIPs(_ads)
					setGlobalVIPs(_globalAds)
				})
				.catch((error) => {
					if (retryCount < maxRetries - 1) {
						const delay = 2 ** retryCount * 1000 // exponential backoff formula: 2^n * 1000
						setTimeout(() => {
							setRetryCount(retryCount + 1)
						}, delay)
					}
					firebase.analytics().logEvent('allvips_context_error', {
						category: 'AllVIPs-Context',
						action: 'FireStore Error',
						label: `Error getting VIPs: ${error}`,
						nonInteraction: true,
					})
				})
		}
	}, [authed, countryCode, allVIPs, retryCount, globalVIPs])

	return <VIPContext.Provider value={[allVIPs, setAllVIPs, globalVIPs, setGlobalVIPs, countryCode]}>{props.children}</VIPContext.Provider>
}
