import React, { useState, useEffect } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import {} from 'react-router-dom'

import { Button } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import SEO from './common/SEO'
import firebase from './config/constants'
import '../styles/styles.scss'
import '../styles/profile.scss'

function Disclaimer(): JSX.Element {
	const history = useHistory()
	const [user, setUser] = useState<{ authed: boolean, loading: boolean, countryCode: string }>({
		authed: false,
		loading: true,
		countryCode: '',
	})

	useEffect(() => {
		if (window.location.href.includes('za')) {
			setUser({ ...user, countryCode: 'ZA' })
		}
	}, [user])

	function trackAccept(): void {
		firebase.analytics().logEvent('disclaimer_view_accept', {
			category: 'Disclaimer',
			action: 'Accepts Disclaimer',
		})
		history.push('/discover/')
	}

	function trackLeave(): void {
		firebase.analytics().logEvent('disclaimer_view_leave', {
			category: 'Disclaimer',
			action: 'Rejects Disclaimer',
		})
		window.location.href = 'https://bing.com/'
	}

	function trackJoinWhatsApp(): void {
		firebase.analytics().logEvent('disclaimer_join_whatsapp_group', {
			category: 'Disclaimer',
			action: 'Join WhatsApp Group',
		})
		window.location.href = 'https://chat.whatsapp.com/FBYkl7etZEuG10EO7LNzCD'
	}

	return (
		<>
			<SEO url={window.location.href} title={'Disclaimer'} image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'} />
			<div className="disclaimer">
				<h4>WARNING: ADULT CONTENT!!!</h4>
				<p>
					This website contains pages with adult content targeted to a mature audience. If you proceed beyond this page you agree
					to the following: "I do not object to or find adult content distasteful. I confirm that I am over 18 years of age and
					have the legal right to possess and consume adult material. I fully understand the laws of my country, community, site
					and computer to which I am viewing this material, and am solely responsible for my actions. Under penalty of perjury. I
					am not accessing BootyVIP to obtain information that will be used by others or me in any capacity against the Webmaster
					of this website, its owners, employees or any other parties associated with BootyVIP". We provide advertisements and
					links to referral services for independent contractors. Listed advertisers are in no way employed by BootyVIP. BootyVIP
					does not provide access to sites which engage in illegal sexual activities, nor do we condone the same. This site
					provides referral services only and any violations of this declaration should be brought to our attention immediately.
				</p>
				<div>
					<Button
						onClick={trackAccept}
						type="primary"
						block
						htmlType="submit"
						size="large"
						style={{ background: '#ee1e3b', borderColor: '#ee1e3b', cursor: 'pointer', color: '#ffffff' }}
						aria-label="Enter"
					>
						<strong>Enter</strong> <RightOutlined />
					</Button>
					<br />
					<br />
					<Button
						onClick={trackJoinWhatsApp}
						type="primary"
						block
						htmlType="submit"
						size="large"
						style={{ background: 'transparent', borderColor: '#515156', cursor: 'pointer', color: '#ffffff' }}
					>
						Join WhatsApp Group <RightOutlined />
					</Button>
					<br />
					<br />
					<Button
						onClick={trackLeave}
						type="primary"
						block
						htmlType="submit"
						size="large"
						style={{ background: 'transparent', borderColor: '#515156', cursor: 'pointer', color: '#ffffff' }}
					>
						Leave <RightOutlined />
					</Button>
				</div>
			</div>
		</>
	)
}

export default withRouter(Disclaimer)
