function RandomQuotes(): JSX.Element {
	const quotes = [
		'You are the best!',
		'Hold on tight',
		'As necessary as air',
		'Love me tender',
		'Passion sets everything on fire',
		"So, let's dance",
		'Waiting for your call',
		'Reasonable madness',
		'Set love free',
		'Come close to me baby',
		'Your love is so WiFi',
		'Be my fairytale',
		'All that matters',
		"I just love when I'm with you",
		'Pump up the jam',
		'An endless flowering garden',
		'What happiness requires',
		'We cannot be lost',
		'Her legs wrapped around my waist',
		'The only thing that matters',
		'Love, and then love more',
		'Nothing was the same',
		'Believe in the impossible',
		"It's yours",
		"It's not love, but it's pretty close",
		'That moment',
		'Bad bitches is the only thing that I like',
		'She deserves an applause',
		'Forever curious',
		'You’re perfect for me',
		'You want it, I got it',
		'Want to eat you like a cookie',
		'Give it to me',
		'Baby my mind is blown',
		"Fantasies can run but they can't hide",
		'It’s like you read my mind',
		'Have you seen my fucks to give?',
		'Tell me what you wanna do',
	]
	const position = Math.round(Math.random() * (quotes.length - 1))
	return <div>{quotes[position]}</div>
}

export default RandomQuotes
