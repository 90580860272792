import React from 'react'
import firebase from '../config/constants'

function DiscoverFooter(): JSX.Element {
	return (
		<>
			<div style={{ marginTop: '35px', fontWeight: 300, fontSize: '12px', marginBottom: '40px', clear: 'both' }}>
				BootyVIP is a premium listing directory of escorts in beautiful South Africa, Kenya, Tanzania and Zimbabwe. We advertise
				services for escorts in the cities of Johannesburg, Pretoria, Cape Town, Durban, Port Elizabeth, East London, Welkom,
				Secunda, Rustenburg, Bulawayo, Gweru, Victoria Falls and Harare. Escorts listed on BootyVIP have various tastes in terms of
				entertainment, fetishes, hobbies and are of different ethnicities.
			</div>

			<div className="discover-footer">
				<div className="discover-footer-cell" onClick={() => firebase.analytics().logEvent('Link - whatsapp group')}>
					<a
						href="https://chat.whatsapp.com/FBYkl7etZEuG10EO7LNzCD"
						target="_blank"
						rel="noopener noreferrer"
						title="whatsapp group"
					>
						<img src="/static/images/join-us-whatsapp-group.jpg" alt="whatsapp group" width={150} />
					</a>
				</div>
				<div className="discover-footer-cell" onClick={() => firebase.analytics().logEvent('Link - worldescortindex')}>
					<a href="https://worldescortindex.com/" target="_blank" rel="noopener noreferrer" title="worldescortindex.com">
						<img src="https://www.worldescortindex.com/images/our-banners/120x60.jpg" alt="worldescortindex.com" />
					</a>
				</div>
				<div className="discover-footer-cell" onClick={() => firebase.analytics().logEvent('Link - openadultdirectory')}>
					<a href="https://openadultdirectory.com/escorts/" target="_blank" rel="noopener noreferrer">
						<img src="https://img.openadultdirectory.com/banner-img/escorts-md.jpg" alt="Open Adult Directory Escorts" />
					</a>
				</div>
			</div>
		</>
	)
}

export default DiscoverFooter
