import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { loginAnonymously } from '../helpers/auth.js'
import firebase, { firebaseAuth } from '../config/constants'
import PhotoCards from '../PhotoCards'
import '../App.scss'
import { VIPContext } from '../../context/VIPContext'
import { CurrentCityContext } from '../../context/CurrentCityContext.js'

import SEO from '../common/SEO'

function Suburb(props) {
	const [, , globalVIPs] = useContext(VIPContext)
	const [city] = useContext(CurrentCityContext)
	const [selectedVIPs, setSelectedVIPs] = useState([])
	const [selectedSuburb] = useState(props.match.params.suburb)

	useEffect(() => {
		if (firebase) {
			firebase.auth().onAuthStateChanged((authUser) => {
				if (!authUser) {
					loginAnonymously()
				}
			})
		}
	}, [selectedSuburb, city])

	useEffect(() => {
		const selectVIPs = (selectedSuburb) => {
			let meisies
			if (city) {
				meisies = globalVIPs.filter((meisie) => meisie.data.suburb === selectedSuburb && meisie.data.city === city)
			} else {
				meisies = globalVIPs.filter((meisie) => meisie.data.suburb === selectedSuburb)
			}

			if (meisies.length > 0) {
				setSelectedVIPs([...meisies])

				firebase.analytics().logEvent('suburb_view', {
					category: 'Suburb-View',
					action: 'Suburb selected',
					label: selectedSuburb,
				})
			}
		}
		if (globalVIPs.length > 0 && selectedSuburb && selectedVIPs.length === 0) {
			selectVIPs(selectedSuburb)
		}
	}, [selectedSuburb, globalVIPs, props, city, selectedVIPs])

	return (
		<>
			<SEO url={window.location.href} title={selectedSuburb} image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'} />
			<div className="App">
				{firebaseAuth().currentUser &&
					(selectedSuburb !== '' ? (
						<>
							{city ? (
								<Link to={encodeURI(`/browse/city/${city}`)}>
									<div className="tag">LIST ALL SUBURBS</div>
								</Link>
							) : (
								<Link to="/browse/cities">
									<p>{city}</p>
									<div className="tag">LIST CITIES</div>
								</Link>
							)}
							<h5>{selectedSuburb}</h5>
							<PhotoCards allVIPs={selectedVIPs} />
						</>
					) : (
						<Link to="/discover">
							<div className="tag" style={{ color: '#fff' }}>
								GO TO DISCOVER
							</div>
						</Link>
					))}
			</div>
		</>
	)
}

export default withRouter(Suburb)
