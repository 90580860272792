import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'antd'

interface DeletePictureModalProps {
	open: boolean
	handleDeletePopUp: (_:boolean) => void
	action: () => void
	type: string
}

function DeletePictureModal(props: DeletePictureModalProps): JSX.Element {
	const [showModal, setShowModal] = useState(false)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (props.open) {
			setShowModal(true)
		}
	}, [props.open])

	const handleDeletion = () => {
		setLoading(true)
		setShowModal(false)
		props.action()
		setLoading(false)
	}

	const handleCancel = () => {
		setShowModal(false)
		props.handleDeletePopUp(false)
	}

	return (
		<Modal
			open={showModal}
			onCancel={handleCancel}
			footer={[
				<Button key="back" onClick={handleCancel}>
					Cancel
				</Button>,
				<Button
					key="register"
					type="primary"
					loading={loading}
					onClick={handleDeletion}
					style={{ backgroundColor: '#EE1E3A', borderColor: '#EE1E3A' }}
				>
					Yes, Proceed
				</Button>,
			]}
		>
			<p>Are you sure you want to delete this {props.type}?</p>
		</Modal>
	)
}

export default DeletePictureModal
