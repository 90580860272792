import React, { useState, useEffect } from 'react'
import firebase from '../config/constants'
import { v5 as uuidv5 } from 'uuid'
import { toast } from 'react-toastify'
import logError from '../../utils/logError'

const db = firebase.firestore()
toast.configure()

interface IUploadToS3Props {
	profileId: string
	buttonLabel: string
	currentPics?: string[]
	currentVideos?: string[]
	currentVideo?: string
	type: string
}

function UploadToS3({ profileId, buttonLabel, currentPics, currentVideos, currentVideo, type }:IUploadToS3Props) {
	const [imageList, setImageList] = useState<string[]>([])
	const [videoList, setVideoList] = useState<string[]>([])
	const [selectedImageFiles, setSelectedImageFiles] = useState<File[]>([])
	const [uploadStatus, setUploadStatus] = useState('')
	const [selectedVideoFiles, setSelectedVideoFiles] = useState<File[]>([])

	useEffect(() => {
		if (videoList.length > 0 && videoList.length === selectedVideoFiles.length) {
			let oldVideoLink = []
			if(currentVideo){
			if (currentVideos && currentVideos.length === 0) {
				oldVideoLink.push(currentVideo)
			}}

			if (oldVideoLink.length > 0) {
				db.collection('users')
					.doc(profileId)
					.update({ videoLink: firebase.firestore.FieldValue.delete() })
					.catch((error) => {
						logError(`Error deleting videoLink field: ${error}`, 'UploadToS3', 'oldVideoLink')
					})
			}
		}
	}, [videoList, selectedVideoFiles, profileId, currentVideos, currentVideo])

	const handleImageFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files) {
			setSelectedImageFiles([...files])
		}
	}


	const handleVideoFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files) {
			setSelectedVideoFiles([...files]);
		}
	}

	const removeFiles = (type:string) => {
		if (type === 'images') {
			setSelectedImageFiles([])
			setImageList([])
		} else {
			setSelectedVideoFiles([])
			setVideoList([])
		}
	}

	const processUpload = async (media_path: string, file: any, type:string) => {
		try {
			const environment = process.env.REACT_APP_ENVIRONMENT ?? 'beta'
			const api_url = `https://pkimp1dwph.execute-api.us-east-1.amazonaws.com/${environment}/80074-mapikicha-${environment}-s3-af-south-1/${media_path}`

			const response = await fetch(api_url, {
				method: 'PUT',
				body: file,
			})

			if (response.status === 200) {
				setUploadStatus('Upload in process.')
				if (type === 'images') {
					db.collection('users')
						.doc(profileId)
						.update({
							pics: firebase.firestore.FieldValue.arrayUnion(`${process.env.REACT_APP_CLOUDFRONT_URL}${media_path}`),
						})
						.then(() => {
							setImageList([...imageList, `${process.env.REACT_APP_CLOUDFRONT_URL}${media_path}`])
							buttonLabel !== 'Add photos to advert' &&
								setUploadStatus('Picture(s) successfully uploaded, refresh page to see changes.')
							toast(`Photo uploaded successfully.`)
						})
						.catch((error) => {
							setUploadStatus('Error uploading picture.')
							logError(error, 'UploadToS3', 'processUpload')
						})
				} else {
					toast(`Video uploaded successfully.`)
					db.collection('users')
						.doc(profileId)
						.update({
							videoUrls: firebase.firestore.FieldValue.arrayUnion(`${process.env.REACT_APP_CLOUDFRONT_URL}${media_path}`),
						})
						.then(() => {
							setVideoList([...videoList, `${process.env.REACT_APP_CLOUDFRONT_URL}${media_path}`])
							buttonLabel !== 'Add photos to advert' &&
								setUploadStatus('Video(s) successfully uploaded, refresh page to see changes.')
							toast(`Video uploaded successfully`)
						})
						.catch((error) => {
							setUploadStatus('Error uploading video.')
							logError(`Error loading video: ${error}`, 'UploadToS3', 'processUpload')
						})
				}
			} else {
				toast(`Error uploading ${type}`)
				logError(`Error uploading ${type}`, 'UploadToS3', 'processUpload')
			}
		} catch (error) {
			logError(`Error uploading: ${error}`, 'UploadToS3', 'processUpload')
		}
	}

	const uploadFiles = (files: any[], type:string) => {
		profileId &&
			files.map((file) => {
				let fileExtension = file.name.split('.').pop()
				let fileName = `${uuidv5(file.name, uuidv5.DNS)}.${fileExtension}`

				if (type === 'images' && file.size > 4000000) {
					alert(`${file.name} is too big. Max size is 4MB.`)
					logError(`${file.name} is too big. Max size is 4MB.`, 'UploadToS3', 'uploadFiles')
					return false
				} else if (type === 'videos' && file.size > 15000000) {
					alert(`${file.name} is too big. Max size is 15MB.`)
					logError(`${file.name} is too big. Max size is 15MB.`, 'UploadToS3', 'uploadFiles')
					return false
				}
				const media_path = type === 'images' ? `imgs/${profileId}/${fileName}` : `videos/${profileId}/${fileName}`
				processUpload(media_path, file, type)
				return true
			})
	}

	return (
		<>
			{type === 'image' ? (
				<div style={{ width: '100%', padding: '16px 20px' }}>
					{buttonLabel && <p className="profile">{buttonLabel}</p>}
					<p>{uploadStatus}</p>
					<input type="file" accept="image/*" multiple={true} onChange={handleImageFileInput} />
					{selectedImageFiles.length > 0 && (
						<button type="button" onClick={() => uploadFiles(selectedImageFiles, 'images')}>
							{' '}
							Upload Image(s)
						</button>
					)}
					{selectedImageFiles.length > 0 && <button onClick={() => removeFiles('images')}> Remove Images</button>}
				</div>
			) : (
				<div style={{ width: '100%', padding: '16px 20px' }}>
					{buttonLabel && <p className="profile">{buttonLabel}</p>}
					<p>{uploadStatus}</p>
					<input type="file" accept="video/mp4,video/x-m4v,video/*" multiple={true} onChange={handleVideoFileInput} />
					{selectedVideoFiles.length > 0 && (
						<button type="button" onClick={() => uploadFiles(selectedVideoFiles, 'videos')}>
							{' '}
							Upload Video(s)
						</button>
					)}
					{selectedVideoFiles.length > 0 && <button onClick={() => removeFiles('videos')}> Remove Videos</button>}
				</div>
			)}
			<p />
		</>
	)
}

export default UploadToS3
