import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
// import { UserContext } from '../../context/UserContext.js'
// import { loginWithGoogle } from '../../components/helpers/auth.js'

function RegisterPop(props) {
	// const [registeredUser] = useContext(UserContext)
	const [showModal, setShowModal] = useState(false)
	const [loading, setLoading] = useState(false)
	const history = useHistory()
	const location = useLocation()

	useEffect(() => {
		if (props.open) {
			setShowModal(true)
		}
	}, [props.open])

	const handleLogin = () => {
		setLoading(true)
		sessionStorage.setItem('savedRoute', location.pathname)
		setShowModal(false)
		history.push('/login/')
		setLoading(false)
	}

	// const handleLoginGoogle = () => {
	// 	setLoading(true)
	// 	setShowModal(false)
	// 	loginWithGoogle()
	// 	history.push(location.pathname)
	// 	setLoading(false)
	// }

	const handleRegister = () => {
		setLoading(true)
		sessionStorage.setItem('savedRoute', location.pathname)
		setShowModal(false)
		history.push('/register/')
		setLoading(false)
	}

	const handleCancel = () => {
		setShowModal(false)
		props.handleRegisterModal(false)
	}

	return (
		<Modal
			open={showModal}
			// title="Title"
			// onOk={handleOk}
			onCancel={handleCancel}
			footer={[
				<Button key="back" onClick={handleCancel}>
					Ignore
				</Button>,
				// <Button
				// 	key="loginGoogle"
				// 	loading={loading}
				// 	onClick={handleLoginGoogle}
				// 	style={{ backgroundColor: '#ffffff', borderColor: '#EE1E3A', color: '#EE1E3A' }}
				// >
				// 	Login with Gmail
				// </Button>,
				<Button
					key="login"
					// type="primary"
					loading={loading}
					onClick={handleLogin}
					style={{ backgroundColor: '#ffffff', borderColor: '#EE1E3A', color: '#EE1E3A' }}
				>
					Login
				</Button>,
				<Button
					key="register"
					type="primary"
					loading={loading}
					onClick={handleRegister}
					style={{ backgroundColor: '#EE1E3A', borderColor: '#EE1E3A' }}
				>
					Register
				</Button>,
			]}
		>
			<p>Become a member to use this feature and much more. It's FREE!</p>
		</Modal>
	)
}

export default RegisterPop
