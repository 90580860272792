import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

import { loginAnonymously } from '../helpers/auth.js'
import firebase, { firebaseAuth } from '../config/constants'
import { VIPContext } from '../../context/VIPContext'
import { CurrentCityContext } from '../../context/CurrentCityContext.js'
import SEO from '../common/SEO'
import { CircularProgress } from '@material-ui/core'
import ETravelHostPreferences from '../../interfaces/enums/ETravelHostPreferences'
import '../App.scss'

function City(props) {
	const [, , globalVIPs] = useContext(VIPContext)
	const [city, setCity] = useContext(CurrentCityContext)
	const [numberedSuburbs, setNumberedSuburbs] = useState([])
	const [travelOnly, setTravelOnly] = useState([])
	const [selectedCity] = useState(props.match.params.city)

	useEffect(() => {
		if (firebase) {
			firebase.auth().onAuthStateChanged((authUser) => {
				if (!authUser) {
					loginAnonymously()
				}
			})
		}
	}, [])

	useEffect(() => {
		const selectCity = (newcity) => {
			const hostingMeisies = globalVIPs.filter(
				(meisie) =>
					!meisie.data.travelOnly &&
					meisie.data.hostAndTravelPreference !== ETravelHostPreferences.TravelOnly &&
					meisie.data.city === newcity.split(' (')[0]
			)
			activeSuburbs(hostingMeisies)
			const cityMeisies = globalVIPs.filter((meisie) => meisie.data.city === newcity.split(' (')[0])
			checkTravelOnlyVIPs(cityMeisies)

			if (newcity.split(' (')[0] !== city) {
				setCity(newcity.split(' (')[0])
			}

			firebase.analytics().logEvent('city_view', {
				category: 'City-View',
				action: 'City selected',
				label: selectedCity,
			})
		}
		selectCity(selectedCity)
	}, [selectedCity, globalVIPs, city, setCity])

	const activeSuburbs = (meisies) => {
		let suburbs = []
		let allSuburbs = []

		if (meisies.length) {
			meisies.map((meisie) => allSuburbs.push(meisie.data.suburb))
			allSuburbs.sort()

			let current = null
			let cnt = 0
			for (let i = 0; i < allSuburbs.length + 1; i++) {
				if (allSuburbs[i] !== current) {
					if (cnt > 0) {
						suburbs.push(`${current} (${cnt})`)
					}
					current = allSuburbs[i]
					cnt = 1
				} else {
					cnt++
				}
			}
			setNumberedSuburbs([...suburbs])
		}
	}

	const checkTravelOnlyVIPs = (meisies) => {
		if (meisies.length > 0) {
			const travellers = meisies.filter(
				(meisie) => meisie.data.travelOnly || meisie.data.hostAndTravelPreference === ETravelHostPreferences.TravelOnly
			)
			setTravelOnly([...travellers])
		}
	}

	return (
		<>
			<SEO url={window.location.href} title={selectedCity} image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'} />
			<div className="App">
				{firebaseAuth().currentUser && selectedCity ? (
					<>
						<Link to="/browse/cities">
							<div className="tag">LIST CITIES</div>
						</Link>
						<h5>{selectedCity}</h5>
						{travelOnly.length > 0 && (
							<Link to={encodeURI(`/browse/travel-only/${selectedCity}`)}>
								<div className="tag" style={{ border: '3px solid #397D02' }}>
									Travel Only ({travelOnly.length})
								</div>
							</Link>
						)}
						{numberedSuburbs.map((suburb, i) => (
							<Link to={encodeURI(`/browse/suburb/${suburb.split(' (')[0]}`)} key={i}>
								<div className="tag">{suburb}</div>
							</Link>
						))}
					</>
				) : (
					<div id="loader">
						<CircularProgress color="secondary" />
					</div>
				)}
			</div>
		</>
	)
}

export default City
