import React from 'react'
import { Link } from 'react-router-dom'
import { countries } from '../../constants/countries'
import '../App.scss'
import SEO from '../common/SEO'

function Countries() {
	return (
		<>
			<SEO
				url={window.location.href}
				title={'View by Country'}
				image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'}
			/>
			<div className="App">
				<>
					<Link to="/discover">
						<div className="tag" style={{ color: '#fff' }}>
							BACK TO DISCOVER
						</div>
					</Link>
					<h5>Countries</h5>
					{countries.map((country, i) => (
						<Link to={encodeURI(`/browse/cities/${country.code}`)} key={i}>
							<div className="tag">{country.name}</div>
						</Link>
					))}
				</>
			</div>
		</>
	)
}

export default Countries
