import React, { useState, createContext, useEffect } from 'react'
import firebase, { firebaseAuth } from '../components/config/constants'
import moment from 'moment'

export const MoreBootyContext = createContext()

export const MoreBootyContextProvider = (props) => {
	const [countryCode, setCountryCode] = useState('')
	const [moreBootyList, setMoreBootyList] = useState()
	const [user, setUser] = useState({ authed: false, loading: true })

	useEffect(() => {
		const country_code = localStorage.getItem('vipPosition') ? JSON.parse(localStorage.getItem('vipPosition')).country_code : 'ZA'
		setCountryCode(country_code)

		firebaseAuth().onAuthStateChanged((user) => {
			user &&
				setUser({
					authed: true,
					loading: false,
					uid: user.uid,
				})
		})
	}, [])

	useEffect(() => {
		if (user.authed && countryCode) {
			const _countryCode =
				countryCode === 'ZA' || countryCode === 'ZW' || countryCode === 'TZ' || countryCode === 'BW' || countryCode === 'KE'
					? countryCode
					: 'XX'
			const _array = []

			firebase
				.firestore()
				.collection('moreBootyList')
				.where('countryCode', '==', _countryCode)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						const age = moment().diff(moment(doc.data().created), 'hours')
						if (age <= 2) {
							_array.push({ id: doc.id, data: doc.data() })
						}
					})
				})
				.then(() => {
					if (_array.length > 0) {
						_array.sort((a, b) => {
							return b.data.created - a.data.created
						})
						setMoreBootyList(_array[0])
					}
				})
				.catch((error) => {
					firebase.analytics().logEvent('more_booty_list_context_error', {
						category: 'MoreBootyList-Context.js',
						action: 'FireStore Error',
						label: `Error getting document: ${error}`,
						nonInteraction: true,
					})
				})
		}
	}, [user.authed, countryCode])

	return <MoreBootyContext.Provider value={[moreBootyList, setMoreBootyList]}>{props.children}</MoreBootyContext.Provider>
}
