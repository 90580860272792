import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import firebase, { firebaseAuth } from '../config/constants'
import { postSearchTerm } from '../../utils/firestore_utils'
import Home from '../PhotoCards'
import SearchName from '../ui/SearchName'
import '../App.scss'
import { CircularProgress } from '@material-ui/core'
import { VIPContext } from '../../context/VIPContext'
import { IVip } from '../../../src/interfaces/IVip'
import { ISearchTerm } from '../../../src/interfaces/ISearchTerm'

const db = firebase.firestore()

function SearchByName() {
	const [uid, setUid] = useState<string | null>(null)
	const [, , globalVIPs] = useContext(VIPContext)
	const [selectedVIPs, setSelectedVIPs] = useState<IVip[]>([])
	const [uniqueNames, setUniqueNames] = useState<string[]>([])
	const [recentSearchTerms, setRecentSearchTerms] = useState<ISearchTerm[]>([])
	const [searchTerm, setSearchTerm] = useState<string>('')

	useEffect(() => {
		if (uid === null) {
			firebaseAuth().onAuthStateChanged((user) => {
				user && setUid(user.uid)
			})
		} else if (uid !== null) {
			let _recentSearchTerms: ISearchTerm[] = []
			db.collection('searches')
				.where('uid', '==', uid)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc: any) => {
						_recentSearchTerms.push(doc.data())
					})
				})
				.then(() => {
					setRecentSearchTerms(_recentSearchTerms)
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [uid])

	useEffect(() => {
		activeSuburbs(globalVIPs)
		firebase.analytics().logEvent('search_by_name_view')
	}, [globalVIPs])

	const activeSuburbs = (meisies: IVip[]) => {
		let allNames: string[] = []
		let _uniqueNames: string[] = []

		if (meisies.length) {
			meisies.map((meisie) => allNames.push(meisie.data.workName))
			allNames.sort()

			let current = null
			let cnt = 0

			for (let i = 0; i < allNames.length + 1; i++) {
				if (allNames[i] !== current) {
					if (cnt > 0) {
						_uniqueNames.push(`${current}`)
					}
					current = allNames[i]
					cnt = 1
				} else {
					cnt++
				}
			}

			setUniqueNames([..._uniqueNames])
		}
	}

	useEffect(() => {
		if (searchTerm.length > 1 && uid) {
			const meisies = globalVIPs.filter((meisie) => meisie.data.workName.toLowerCase().includes(searchTerm.toLowerCase()))
			setSelectedVIPs([...meisies])
			if (meisies.length > 0) {
				postSearchTerm({ searchTerm: meisies[0].data.workName, uid, date: new Date().toISOString() })
			}
		}
	}, [searchTerm, globalVIPs, uid])

	return (
		<div className="App">
			{uid && uniqueNames && uniqueNames.length > 0 ? (
				<>
					<Link to="/discover">
						<div className="tag" style={{ color: '#fff' }}>
							BACK TO DISCOVER
						</div>
					</Link>
					<h5>FIND BY NAME</h5>
					<SearchName setSearchTerm={setSearchTerm} recentSearchTerms={recentSearchTerms} />
					{selectedVIPs.length > 0 ? <Home allVIPs={selectedVIPs} /> : searchTerm !== '' ? <h5>:( No BootyVIPs found.</h5> : null}
				</>
			) : (
				<div id="loader">
					<CircularProgress color="secondary" />
				</div>
			)}
		</div>
	)
}

export default withRouter(SearchByName)
