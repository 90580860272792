import React from 'react'
import { Link } from 'react-router-dom'
import firebase from '../config/constants'
import { IVip } from '../../interfaces/IVip'

interface IViewByLinksProps {
	countryCode: string;
	allVIPs: IVip[];
	email: string;
	handleRegisterModal: (status: boolean) => void;
}

function ViewByLinks(props: IViewByLinksProps): JSX.Element {
	const trackCountryClick = () => {
		firebase.analytics().logEvent('discover_country_click', {
			category: 'Discover-Country-Click',
			action: 'Discover-Country',
			label: 'Discover-Country-Click',
		})
	}

	const trackCityClick = () => {
		firebase.analytics().logEvent('discover_city_click', {
			category: 'Discover-City-Click',
			action: 'Discover-City',
			label: 'Discover-City-Click',
		})
	}

	return (
		<div className="viewBy">
			{(props.countryCode === 'ZA' ||
				props.countryCode === 'ZW' ||
				props.countryCode === 'KE' ||
				props.countryCode === 'BW' ||
				props.countryCode === 'TZ') && (
				<Link
					className="tagLink"
					to={{
						pathname: '/browse/cities/',
						state: {
							allVIPs: [...props.allVIPs],
						},
					}}
				>
					<div className="tag" style={{ border: '3px solid #397D02' }} onClick={trackCityClick}>
						CITY
					</div>
				</Link>
			)}

			<Link
				className="tagLink"
				to={{
					pathname: '/browse/countries/',
					state: {
						allVIPs: [...props.allVIPs],
					},
				}}
			>
				<div className="tag" style={{ border: '3px solid #397D02' }} onClick={trackCountryClick}>
					COUNTRY
				</div>
			</Link>

			{props.email ? (
				<Link
					className="tagLink"
					to={{
						pathname: '/browse/bodies/',
						state: {
							allVIPs: [...props.allVIPs],
						},
					}}
				>
					<div className="tag">BODY TYPE</div>
				</Link>
			) : (
				<span className="tagLink">
					<div className="tag" onClick={() => props.handleRegisterModal(true)}>
						BODY TYPE
					</div>
				</span>
			)}

			{props.email ? (
				<Link
					className="tagLink"
					to={{
						pathname: '/browse/busts/',
						state: {
							allVIPs: [...props.allVIPs],
						},
					}}
				>
					<div className="tag">BUST</div>
				</Link>
			) : (
				<span className="tagLink">
					<div className="tag" onClick={() => props.handleRegisterModal(true)}>
						BUST
					</div>
				</span>
			)}
			<Link
				className="tagLink"
				to={{
					pathname: '/browse/males/',
					state: {
						allVIPs: [...props.allVIPs],
					},
				}}
			>
				<div className="tag">MEN</div>
			</Link>

			<Link
				className="tagLink"
				to={{
					pathname: '/search',
					state: {
						allVIPs: [...props.allVIPs],
					},
				}}
			>
				<div className="tag">SEARCH</div>
			</Link>
		</div>
	)
}

export default ViewByLinks
