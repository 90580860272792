export const languageOptions: string[] = [
	'English',
	'Swahili',
	'Afrikaans',
	'ChiShona',
	'isiNdebele',
	'Sesotho',
	'SiSwati',
	'Setswana',
	'Xitsonga',
	'Tshivenḓa',
	'isiXhosa',
	'isiZulu',
	'French',
	'Portuguese',
]
