import React from 'react'
import Photo from './Photo'
import { IVip } from '../interfaces/IVip'

interface IPhotoCardsProps {
	allVIPs?: IVip[];
	favVIPs?: IVip[];
	id?: string;
}

function PhotoCards(props: IPhotoCardsProps): JSX.Element {
	const { allVIPs, favVIPs } = props
	return (
		<div className="photoComponentHolder">
			{allVIPs && allVIPs.length
				? allVIPs.map((vip, index) => vip.data.workName && <Photo id={vip.id} key={index} data={vip.data} />)
				: null}
			{favVIPs && favVIPs.length > 0
				? favVIPs.map((vip, index) => vip && vip.data.workName && <Photo id={vip.id} key={index} data={vip.data} roster={true} />)
				: null}
		</div>
	)
}

export default React.memo(PhotoCards)
