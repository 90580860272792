import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { IVip } from '../../interfaces/IVip'
import firebase, { firebaseAuth } from '../config/constants'
import PhotoCards from '../PhotoCards'
import '../App.scss'
import { CircularProgress } from '@material-ui/core'
import { VIPContext } from '../../context/VIPContext'
import SEO from '../common/SEO'

function Bodies(): JSX.Element {
	const [allVIPs] = useContext(VIPContext)
	const [selectedVIPs, setSelectedVIPs] = useState<IVip[]>([])
	const [numberedSuburbs, setNumberedSuburbs] = useState<string[]>([])
	const [selectedSuburb, setSelectedSuburb] = useState<string>('')

	useEffect(() => {
		activeSuburbs(allVIPs)

		firebase.analytics().logEvent('bodies_view')
		const trace = firebase.performance().trace('Bodies')

		trace.incrementMetric('Bodies-View')
	}, [allVIPs])

	const activeSuburbs = (meisies: IVip[]) => {
		let suburbs = []
		let allSuburbs: string[] = []

		if (meisies.length) {
			meisies.map((meisie) => allSuburbs.push(meisie.data.bodyDescription))
			allSuburbs.sort()

			let current = null
			let cnt = 0
			for (let i = 0; i < allSuburbs.length + 1; i++) {
				if (allSuburbs[i] !== current) {
					if (cnt > 0) {
						suburbs.push(`${current} (${cnt})`)
					}
					current = allSuburbs[i]
					cnt = 1
				} else {
					cnt++
				}
			}
			setNumberedSuburbs([...suburbs])
		}
	}

	const selectVIPs = (suburbia: string) => {
		if (allVIPs.length) {
			let meisies = allVIPs.filter((meisie: IVip) => meisie.data.bodyDescription === suburbia.split(' (')[0])

			setSelectedVIPs([...meisies])
			setSelectedSuburb(suburbia.split(' (')[0])

			firebase.analytics().logEvent('body_view', {
				category: 'Body-View',
				action: 'Body selected',
				label: suburbia.split(' (')[0],
			})
		}
	}

	const clearVIPs = () => {
		setSelectedVIPs([])
		setSelectedSuburb('')
	}

	return (
		<>
			<SEO
				url={window.location.href}
				title={'View by Body type'}
				image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'}
			/>
			<div className="App">
				{firebaseAuth().currentUser && numberedSuburbs && numberedSuburbs.length > 0 ? (
					selectedSuburb !== '' ? (
						<>
							<div className="tag" onClick={clearVIPs}>
								LIST ALL BODY TYPES
							</div>
							<h5>{selectedSuburb}</h5>
							<PhotoCards allVIPs={selectedVIPs} />
						</>
					) : (
						<>
							<Link to="/discover">
								<div className="tag" style={{ color: '#fff' }}>
									BACK TO DISCOVER
								</div>
							</Link>
							<h5>Body Types</h5>
							{numberedSuburbs.map((suburbia, i) => (
								<div className="tag" key={i} onClick={() => selectVIPs(suburbia)}>
									{suburbia}
								</div>
							))}
						</>
					)
				) : (
					<div id="loader">
						<CircularProgress color="secondary" />
					</div>
				)}
			</div>
		</>
	)
}

export default Bodies
