import React, { useState, useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'
import { Button } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import firebase, { firebaseAuth } from './config/constants'
import Home from './PhotoCards'
import './App.scss'

interface ErrorMessage {
	title: string;
	body: string;
}

function HiddenAds(props: any): JSX.Element {
	const [allVIPs, setAllVIPs] = useState<any[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [errorMessage, setErrorMessage] = useState<ErrorMessage>({
		title: '',
		body: '',
	})

	useEffect(() => {
		try {
			setIsLoading(true)
			let _ads: any[] = []
			firebase
				.firestore()
				.collection('users')
				.where('active', '==', false)
				.where('verified', '==', true)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						_ads.push({ id: doc.id, data: doc.data() })
					})
				})
				.then(() => {
					setAllVIPs(_ads)
					_ads.length === 0 && setErrorMessage({ title: 'All clear..', body: 'No hidden ads found' })
				})
		} catch (error) {
			console.log(error)
			firebase.analytics().logEvent('allvips_hidden_ads', {
				category: 'AllVIPs',
				action: 'Error Getting AllVIPs: ',
				error,
			})
		} finally {
			setIsLoading(false)
		}
	}, [])

	const notFoundRedirectToDiscover = () => {
		firebase.analytics().logEvent('no_hidden_ads_redirect', {
			category: 'Redirect',
			action: 'Redirect Hidden Ads Not Found',
		})
		props.history.push('/discover/')
	}

	useEffect(() => {
		const trace = firebase.performance().trace('HiddenAds')
		trace.incrementMetric('Hidden-Ads-View')
	}, [])

	return (
		<div className="App">
			{firebaseAuth().currentUser && !isLoading && allVIPs.length ? (
				<Home id="Home" allVIPs={allVIPs} />
			) : allVIPs.length === 0 && !isLoading ? (
				<div className="profile" style={{ textAlign: 'center', marginTop: '70px' }}>
					<p style={{ fontSize: '36px' }}>{errorMessage.title}</p>
					<p>{errorMessage.body}</p>
					<Button
						onClick={notFoundRedirectToDiscover}
						type="primary"
						block
						htmlType="submit"
						size="large"
						style={{
							color: 'white',
							background: '#ee1e3b',
							borderColor: '#ee1e3b',
							cursor: 'pointer',
							width: '200px',
						}}
						aria-label="Go to discover"
					>
						<strong style={{ color: 'white' }}>Go to discover</strong> <RightOutlined />
					</Button>
				</div>
			) : (
				<div id="loader">
					<CircularProgress color="secondary" />
				</div>
			)}
		</div>
	)
}

export default HiddenAds
