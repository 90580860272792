import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Nav from './Nav'
import Main from './Main'
import './App.scss'

const App = (): JSX.Element => (
	<BrowserRouter>
		<Nav />
		<Main />
	</BrowserRouter>
)

export default App
