import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/browser'
import { HelmetProvider } from 'react-helmet-async'

import App from './components/App.tsx'
import { VIPContextProvider } from './context/VIPContext'
import { ReaderContextProvider } from './context/ReaderContext.js'
import { MoreBootyContextProvider } from './context/MoreBootyContext.js'
import { CurrentCityContextProvider } from './context/CurrentCityContext.js'
import reportWebVitals from './reportWebVitals.ts'

import './index.scss'

const history = createBrowserHistory()
const root = createRoot(document.getElementById('root'))

process.env.NODE_ENV === 'production' &&
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		release: '2.21.2',
		tracesSampleRate: 0.2,
		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,
		integrations: [
			new Sentry.Replay(),
			new BrowserTracing({
				tracingOrigins: ['bootyvip.co.za', 'bootyvip.com', /^\//],
				routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
			}),
		],
	})

root.render(
	<VIPContextProvider>
		<ReaderContextProvider>
			<MoreBootyContextProvider>
				<CurrentCityContextProvider>
					<BrowserRouter>
						<HelmetProvider>
							<App />
						</HelmetProvider>
					</BrowserRouter>
				</CurrentCityContextProvider>
			</MoreBootyContextProvider>
		</ReaderContextProvider>
	</VIPContextProvider>
)

reportWebVitals()
