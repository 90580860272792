import React from 'react'
import { withRouter, Route, Switch } from 'react-router-dom'
import Advertise from './Advertise'
import Disclaimer from './Disclaimer'
import Discover from './discover/Discover'
import Favourites from './Favourites'
import Feedback from './Feedback'
import PrivacyPolicy from './PrivacyPolicy'
import Profile from './Profile'
import Countries from './browse/Countries'
import Cities from './browse/Cities'
import City from './browse/City'
import TravelOnly from './browse/TravelOnly'
import Suburb from './browse/Suburb'
import Bodies from './browse/Bodies'
import Busts from './browse/Busts'
import Ethnicities from './browse/Ethnicities'
import Ethnicity from './browse/Ethnicity'
import Males from './browse/Males'
import SearchByName from './browse/SearchByName'
import Verify from './Verify'
import HiddenAds from './HiddenAds'
import Sitemap from './Sitemap'
import Login from './Login'
import Register from './Register'
import Dashboard from './Dashboard'

function Main(): JSX.Element {
	return (
		<main>
			<Switch>
				<Route path="/" exact component={Disclaimer} />
				<Route path="/browse/cities/:countrycode" component={Cities} />
				<Route path="/browse/cities" component={Cities} />
				<Route path="/browse/city/:city" component={City} />
				<Route path="/browse/countries" component={Countries} />
				<Route path="/browse/suburb/:suburb" component={Suburb} />
				<Route path="/browse/travel-only/:city" component={TravelOnly} />
				<Route path="/browse/ethnicities" component={Ethnicities} />
				<Route path="/browse/ethnicity:ethnicity" component={Ethnicity} />
				<Route path="/search" component={SearchByName} />
				<Route path="/browse/males" component={Males} />
				<Route path="/browse/busts" component={Busts} />
				<Route path="/browse/bodies" component={Bodies} />
				<Route path="/dashboard" component={Dashboard} />
				<Route path="/discover" component={Discover} />
				<Route path="/favourites" component={Favourites} />
				<Route path="/feedback" component={Feedback} />
				<Route path="/privacy-policy" component={PrivacyPolicy} />
				<Route path="/login" component={Login} />
				<Route path="/profile/:vipId" component={Profile} />
				<Route path="/register" component={Register} />
				<Route path="/advertise/:vipId" component={Advertise} />
				<Route path="/advertise" component={Advertise} />
				<Route path="/sitemap" component={Sitemap} />
				<Route path="/verify" exact component={Verify} />
				<Route path="/hidden" exact component={HiddenAds} />
				<Route component={Discover} />
			</Switch>
		</main>
	)
}

export default withRouter(Main)
