import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import firebase, { firebaseAuth } from '../config/constants'
import Home from '../PhotoCards'
import '../App.scss'
import { CircularProgress } from '@material-ui/core'
import { VIPContext } from '../../context/VIPContext'
import SEO from '../common/SEO'

const bustSizes = ['A', 'B', 'C', 'D', 'DD']

function Busts() {
	const [allVIPs] = useContext(VIPContext)
	const [selectedVIPs, setSelectedVIPs] = useState([])
	const [selectedSize, setSelectedSize] = useState('')

	useEffect(() => {
		const trace = firebase.performance().trace('Busts')

		trace.incrementMetric('Busts-View')
	}, [])

	const selectVIPs = (size) => {
		if (allVIPs.length) {
			let meisies = allVIPs.filter((meisie) => meisie.data.bust && meisie.data.bust.includes(size))

			setSelectedVIPs([...meisies])
			setSelectedSize(size)

			firebase.analytics().logEvent('bust_view', {
				category: 'Bust-View',
				action: 'Bust selected',
				label: `${size} cups`,
			})
		}
	}

	const clearVIPs = () => {
		setSelectedVIPs([])
		setSelectedSize('')
	}

	return (
		<>
			<SEO url={window.location.href} title={'View by Bust'} image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'} />
			<div className="App">
				{firebaseAuth().currentUser ? (
					selectedSize !== '' ? (
						<>
							<div className="tag" onClick={clearVIPs}>
								LIST ALL BUST SIZES
							</div>
							<h5>{selectedSize} cups</h5>
							<Home allVIPs={selectedVIPs} />
						</>
					) : (
						<>
							<Link to="/discover">
								<div className="tag" style={{ color: '#fff' }}>
									BACK TO DISCOVER
								</div>
							</Link>
							<h5>Busts</h5>
							{bustSizes.map((size, i) => (
								<div className="tag" key={i} onClick={() => selectVIPs(size)}>
									{size} cups
								</div>
							))}
						</>
					)
				) : (
					<div id="loader">
						<CircularProgress color="secondary" />
					</div>
				)}
			</div>
		</>
	)
}

export default Busts
