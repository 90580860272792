export const johannesburg: string[] = [
	'Alberton',
	'Alexandra',
	'Auckland Park',
	'Berea',
	'Bramley',
	'Bryanston',
	'Clearwater',
	'Constantia Kloof',
	'Discovery',
	'Duduza',
	'East Rand - Bedfordview',
	'East Rand - Benoni',
	'East Rand - Boksburg',
	'East Rand - Brakpan',
	'East Rand - Bruma',
	'East Rand - Croydon',
	'East Rand - Eastgate',
	'East Rand - Eastleigh',
	'East Rand - Edenglen',
	'East Rand - Edenvale',
	'East Rand - Germiston',
	'East Rand - Katlehong',
	'East Rand - Lambton',
	'East Rand - Kempton Park',
	'East Rand - OR Tambo',
	'Fairlands',
	'Florida Hills',
	'Fourways',
	'Fourways - Lonehill',
	'Glenhazel',
	'Glenvista',
	'Greenstone',
	'Hyde Park',
	'Illovo',
	'Johannesburg - CBD',
	'Kempton Park - Birchleigh',
	'Kensington',
	'Linden',
	'Linksfield',
	'Lonehill',
	'Lyndhurst',
	'Melrose Arch',
	'Midrand',
	'Midrand - Carlswald',
	'Midrand - Glen Austin',
	'Midrand - Halfway House',
	'Midrand - Kyalami',
	'Midrand - Noordwyk',
	'Midrand - President Park',
	'Midrand - Vorna Valley',
	'Mondeor',
	'Norwood',
	'Orange Grove',
	'Olievenhoutbosch',
	'Ormonde',
	'Parkhurst',
	'Parkmore',
	'Primrose',
	'Radiokop',
	'Randburg',
	'Randburg - Blairgowrie',
	'Randburg - Boskruin',
	'Randburg - Cresta',
	'Randburg - Ferndale',
	'Randburg - Fontainebleau',
	'Randburg - Kensington B',
	'Randburg - Malanshof',
	'Randburg - North Riding',
	'Randburg - Northcliff',
	'Randburg - Northgate',
	'Randburg - Olivedale',
	'Randburg - Randpark',
	'Randburg - Sonneglans',
	'Randpark Ridge',
	'Robertsham',
	'Roodepoort',
	'Roodepoort - Honeydew',
	'Roodepoort - Kloofendal',
	'Rosebank',
	'Rosettenville',
	'Sandown',
	'Sandton',
	'Sandton - Benmore',
	'Sandton - Bryanston',
	'Sandton - Buccleuch',
	'Sandton - Fourways',
	'Sandton - Gallo Manor',
	'Sandton - Marlboro',
	'Sandton - Morningside',
	'Sandton - Parkmore',
	'Sandton - Paulshof',
	'Sandton - Petervale',
	'Sandton - Rivonia',
	'Sandton - Sandhurst',
	'Sandton - Sandown',
	'Sandton - Wendywood',
	'Sandton - Woodmead',
	'Sandton - Wynberg',
	'Southdale',
	'Southgate',
	'Soweto',
	'Soweto - Meadowlands Zone 6',
	'Sunninghill',
	'Sydenham',
	'Tembisa',
	'Tembisa - Ivory',
	'Tembisa - Kaalfontein',
	'Tembisa - Station',
	'The Glen',
	'Tsakane',
	'Turffontein',
	'Vaal',
	'Vaal - Vanderbijlpark',
	'Vaal - Evaton',
	'Vaal - Sebokeng',
	'Vaal - Vereeniging',
	'Vaal - Orange Farm',
	'Vosloorus',
	'Weltevreden Park',
	'West Rand',
	'West Rand - Florida',
	'West Rand - Horizon View',
	'West Rand - Krugersdorp',
	'West Rand - Randfontein',
	'Witfield',
	'Yeoville',
]

export const pretoria: string[] = [
	'Akasia',
	'Annlin',
	'Arcadia',
	'Brooklyn',
	'Brummeria',
	'Centurion',
	'Centurion - Clubview',
	'Centurion - Die Hoewes',
	'Centurion - Eldoraigne',
	'Centurion - Heuweloord',
	'Centurion - Highveld',
	'Centurion - Irene',
	'Centurion - Lyttelton',
	'Centurion - Lyttelton Manor',
	'Centurion - Rooihuiskraal',
	'Centurion - The Reeds',
	'Centurion - Wierda Park',
	'Colbyn',
	'Constantia Park',
	'Doornpoort',
	'Dorandia',
	'Elardus Park',
	'Equestria',
	'Erasmuskloof',
	'Faerie Glen',
	'Garsfontein',
	'Gezina',
	'Hatfield',
	'Kilner Park',
	'La Montagne',
	'Lynnwood',
	'Lynnwood Glen',
	'Lynnwood Manor',
	'Lynnwood - Wilgers',
	'Mabopane',
	'Mamelodi',
	'Mayville',
	'Menlo Park',
	'Menlyn',
	'Meyers Park',
	'Montana',
	'Montana Park',
	'Monument Park',
	'Mooikloof',
	'Moot',
	'Moreleta Park',
	'Mountain View',
	'Murrayfield',
	'Newlands',
	'Pretoria Central',
	'Pretoria East',
	'Pretoria Gardens',
	'Pretoria North',
	'Orchards',
	'Pretoria West',
	'Queenswood',
	'Rietfontein',
	'Rietondale',
	'Silver Lakes',
	'Silverton',
	'Soshanguve',
	'Villeria',
	'Waterkloof',
	'Waterkloof Glen',
	'Waterkloof Ridge',
	'Waverley',
	'Wingate Park',
	'Wonder Park',
	'Wonderboom',
	'Wonderboom South',
]

export const capeTown: string[] = [
	'Bellville',
	'Big Bay',
	'Canal Walk',
	'Cape Town CBD',
	'Century City',
	'Claremont',
	'Clifton',
	'Durbanville',
	'Gardens',
	'Goodwood',
	'Green Point',
	'Hermanus',
	'Kenilworth',
	'Maitland',
	'Milnerton',
	'Mouille Point',
	'N1 City',
	'Parklands',
	'Parow North',
	'Plattekloof',
	'Rondebosch',
	'Rosebank',
	'Sea Point',
	'Somerset West',
	'Southern Suburbs',
	'Strand',
	'Sunningdale',
	'Table View',
	'Thornton',
	'Tyger Valley',
	'Waterfront',
	'Woodstock',
]

export const durban: string[] = [
	'Amanzimtoti',
	'Ballito',
	'Bluff',
	'Durban CBD',
	'Durban North',
	'Gateway',
	'La Lucia',
	'Malvern',
	'Margate',
	'Morningside',
	'Newcastle',
	'North Beach',
	'Pinetown',
	'Port Shepstone',
	'Umgeni',
	'Umhlanga',
	'Umhlanga Ridge',
	'Westville',
]

export const bloemfontein: string[] = ['Bayswater', 'Bloemfontein CBD', 'Grassland', 'Waverley']

export const secunda: string[] = ['Embalenhle']

export const rustenburg: string[] = ['Protea Park']

export const portElizabeth: string[] = ['Summerstrand']

export const eastLondon: string[] = ['Quighney']

export const welkom: string[] = ['Flamingo']

export const newcastle: string[] = ['Newcastle CBD']

export const gaborone: string[] = ['UB', 'Partial', 'Gaborone CBD']

export const nairobi: string[] = ['Nairobi CBD']

export const daressalaam: string[] = ['Dar es Salaam CBD']

export const beitbridge: string[] = ['Beitbridge CBD']

export const gweru: string[] = ['Gweru CBD']

export const victoriaFalls: string[] = ['Victoria Falls CBD']

export const mutare: string[] = [
	'Mutare CBD',
	'Murambi',
	'Fairbridge Park',
	'Morningside',
	"Tiger's Kloof",
	'Palmerston',
	'Avenues',
	'Utopia',
	'Darlington',
	'Greenside',
	'Greenside Extension',
	'Yeovil',
	'Westlea',
	'Florida',
	'Toronto',
	'Bordervale',
	'Chikanga',
	'Sakubva',
	'Dangamvura',
	'St Josephs Park',
	'Chikanga Extension',
	'Weirmouth',
	'Fern Valley',
	'Zimunya',
	'Hobhouse Extension',
	'Natview Park',
	'Garikai',
]

export const chitungwiza: string[] = ['Chitungwiza CBD', 'Zengeza 3', 'Zengeza 4', 'Unit M']

export const kwekwe: string[] = ['Ngezi']
export const ngezi: string[] = ['Ngezi']

export const harare: string[] = [
	'Adylinn',
	'Alexandra Park',
	'Amby',
	'Arcadia',
	'Ardbennie',
	'Ashbrittle',
	'Ashdown Park',
	'Aspindale Park',
	'Athlone',
	'Avenues',
	'Avondale',
	'Avondale West',
	'Avonlea',
	'Ballantyne Park',
	'Belgravia',
	'Belvedere',
	'Belvedere North',
	'Belvedere South',
	'Bloomingdale',
	'Bluff Hill',
	'Borrowdale',
	'Borrowdale Brooke',
	'Borrowdale West',
	'Braeside',
	'Breeze Valley',
	'Brooke Ridge',
	'Brookeview',
	'Budiriro',
	'Chadcombe',
	'Chiremba Park',
	'Chisipite',
	'Cold Comfort',
	'Colne Valley',
	'Colray',
	'Cranborne Park',
	'Crowborough',
	'Dzivaresekwa',
	'Eastlea',
	'Emerald Hill',
	'Epworth',
	'Glaudina',
	'Glen Forest',
	'Glen Lorne',
	'Glen Norah',
	'Glen View',
	'Gletwyn',
	'Graniteside',
	'Greencroft',
	'Greendale',
	'Greengrove',
	'Greystone Park',
	'Grobbie Park',
	'Groombridge',
	'Gunhill',
	'Haig Park',
	'Harare CBD',
	'Hatfield',
	'Helensvale',
	'Highfield',
	'Highlands',
	'Hillside',
	'Hogerty Hill',
	'Hopley',
	'Houghton Park',
	'Induna',
	'Kambanji',
	'Kambuzuma',
	'Kensington',
	'Kuwadzana',
	'Lewisham',
	'Lichendale',
	'Lincoln Green',
	'Little Norfolk',
	'Lochinvar',
	'Logan Park',
	'Luna',
	'Mabelreign',
	'Mabvuku',
	'Mainway Meadows',
	'Malvern',
	'Mandara',
	'Manresa',
	'Marimba Park',
	'Marlborough',
	'Matidora Park',
	'Mayfield Park',
	'Mbare',
	'Meyrick Park',
	'Midlands',
	'Milton Park',
	'Monovale',
	'Mount Hampden',
	'Mount Pleasant',
	'Mount Pleasant Heights',
	'Msasa',
	'Msasa Park',
	'Mufakose',
	'New Alexandra Park',
	'New Ardbennie',
	'New Marlborough',
	'Newlands',
	'Nkwisi Park',
	'Northwood',
	'Old Forest',
	'Park Meadowlands',
	'Parktown',
	'Philadelphia',
	'Pomona',
	'Prospect',
	'Prospect Park',
	'Queensdale',
	'Quinnington',
	'Reitfontain',
	'Rhodesville',
	'Ridgeview',
	'Rolfe Valley',
	'Rugare',
	'Runiville',
	'Ryelands',
	'Seke',
	'Sentosa',
	'Shawasha Hills',
	'Sherwood Park',
	'Shortson',
	'Southerton',
	'St. Martins',
	'Strathaven',
	'Sugar Loaf Hill',
	'Sunningdale',
	'Sunridge',
	'Sunrise',
	'Sunway City',
	'Tafara',
	'The Grange',
	'Tynwald',
	'Umwinsidale',
	'Uplands',
	'Vainona',
	'Warren Park',
	'Waterfalls',
	'Westgate',
	'Westlea',
	'Westwood',
	'Willowvale',
	'Wilmington Park',
]

export const bulawayo: string[] = [
	'Ascot',
	'Barbour Fields',
	'Barham Green',
	'Beacon Hill',
	'Bellevue',
	'Belmont',
	'Belmont Industrial',
	'Bradfield',
	'Bulawayo CBD',
	'Burnside',
	'Cement',
	'Cowdray Park',
	'Donnington',
	'Donnington West',
	'Douglasdale',
	'Eloana',
	'Emakhandeni',
	'Emganwini',
	'Enqameni',
	'Enqotsheni',
	'Entumbane',
	'Fagadola',
	'Famona',
	'Fortunes Gate',
	'Four Winds',
	'Glencoe',
	'Glengarry',
	'Glenville',
	'Granite Park',
	'Greenhill',
	'Gwabalanda',
	'Harrisvale',
	'Helenvale',
	'Highmont',
	'Hillcrest',
	'Hillside',
	'Hume Park',
	'Hyde Park',
	'Ilanda',
	'Iminyela',
	'Intini',
	'Jacaranda',
	'Kelvin Industrial',
	'Kenilworth',
	'Kilallo',
	'Killarney',
	'Kingsdale',
	'Kumalo',
	'Kumalo North',
	'Lakeside',
	'Lobengula',
	'Lobenvale',
	'Lochview',
	'Luveve',
	'Mabutweni',
	'Magwegwe',
	'Magwegwe North',
	'Magwegwe West',
	'Makhandeni B',
	'Mahatshula',
	'Makokoba',
	'Malindela',
	'Manningdale',
	'Marlands',
	'Matsheumhlope',
	'Matshobana',
	'Montgomery',
	'Montrose',
	'Morningside',
	'Mpopoma',
	'Munda',
	'Mzilikazi',
	'New Luveve',
	'Newsmansford',
	'Newton',
	'Newton West',
	'Nguboyenja',
	'Njube',
	'Nketa',
	'Nkulumane',
	'North End',
	'North Lynne',
	'North Trenance',
	'Northlea',
	'Northvale',
	'Ntaba Moyo',
	'Orange Grove',
	'Paddonhurst',
	'Parklands',
	'Parkview',
	'Pelandaba',
	'Pumula',
	'Pumula South',
	'Queens Park',
	'Queens Park East',
	'Queens Park West',
	'Queensdale',
	'Rangemore',
	'Raylton',
	'Richmond',
	'Riverside',
	'Romney Park',
	'Sauerstown',
	'Selbourne Park',
	'Sizinda',
	'Southdale',
	'Southwold',
	'Steeldale',
	'Suburbs',
	'Sunninghill',
	'Sunnyside',
	'Tegela',
	'The Jungle',
	'Thorngrove',
	'Trenance',
	'Tshabalala',
	'Tshabalala Extension',
	'Umguza Estate',
	'Upper Rangemore',
	'Waterford',
	'Waterlea',
	'West Somerton',
	'Westgate',
	'Westondale',
	'Willsgrove',
	'Windsor Park',
	'Woodlands',
	'Woodville',
]
