import React, { useRef, useState, useContext } from 'react'
import { Form } from 'antd'
import moment from 'moment'
import firebase from './config/constants'
import { login, resetPassword } from './helpers/auth.js'
import { Link, useHistory } from 'react-router-dom'
import { ReaderContext } from '../context/ReaderContext'
import { toast } from 'react-toastify'
import SEO from './common/SEO'
import { isEmailValid } from '../utils/emailTest'
import 'react-toastify/dist/ReactToastify.css'
import '../styles/styles.scss'
import '../styles/profile.scss'

toast.configure()
const db = firebase.firestore()
const FormItem = Form.Item

function Login() {
	const history = useHistory()
	const [reader, setReader] = useContext(ReaderContext)
	const [loginMessage, setLoginMessage] = useState<string | null>(null)
	const emailRef = useRef<HTMLInputElement | null>(null)
	const passRef = useRef<HTMLInputElement | null>(null)

	const alignRole = async (uid: string | undefined) => {
		// set reader's role
		setReader({ ...reader, uid, role: 'guest' })

		if (uid) {
			await db
				.collection('kings')
				.doc(uid)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setReader({ ...reader, uid, role: doc.data()?.role })
					}
				})
		} else {
			await db
				.collection('users')
				.doc(uid)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setReader({ ...reader, uid, role: 'stock' })
					}
				})
		}
	}

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		const email = emailRef.current?.value.trim() ?? ''
		const password = passRef.current?.value ?? ''

		if (!isEmailValid(email)) {
			setLoginMessage('Invalid email format. Please refresh and try again.')
			return
		}

		login(email, password)
			.then((result) => {
				if (result.user) {
					const savedRoute = sessionStorage.getItem('savedRoute')
					firebase.analytics().logEvent('login_success', {
						uid: result.user.uid,
					})
					firebase.analytics().setUserProperties({ uid: result.user.uid, last_login: moment().format('LLLL') })
					alignRole(result.user.uid)
					sessionStorage.removeItem('savedRoute')
					history.push(savedRoute ? savedRoute : '/dashboard')
				}
			})
			.catch((error) => {
				setLoginMessage(error.message)
				firebase.analytics().logEvent('login_error', {
					uid: reader.uid,
					category: error.message,
					action: error,
				})
			})
	}

	const resetMyPassword = async () => {
		const email = emailRef.current?.value.trim() ?? ''
		if (!isEmailValid(email)) {
			setLoginMessage('Invalid email format. Please refresh and try again.')
			return
		}
		await resetPassword(email)
	}

	return (
		<>
			<SEO url={window.location.href} title={'Login'} image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'} />
			<div className="col-sm-6 col-sm-offset-3">
				<h1 style={{ marginLeft: '5rem' }}>Login</h1>
				<form onSubmit={handleSubmit}>
					<div className="form-group">
						<FormItem label="Email address" style={{ marginLeft: '5rem', marginBottom: '0', display: 'block' }} />
						<FormItem style={{ marginLeft: '5rem', marginBottom: '20px' }}>
							<input
								className="form-control"
								ref={emailRef}
								placeholder="Email"
								style={{ marginLeft: 0, maxWidth: '300px' }}
								data-test="email-input"
							/>
						</FormItem>
					</div>
					<div className="form-group">
						<FormItem label="Password" style={{ marginLeft: '5rem', marginBottom: '0', display: 'block' }} />
						<FormItem style={{ marginLeft: '5rem', marginBottom: '16px' }}>
							<input
								type="password"
								className="form-control"
								placeholder="Password"
								ref={passRef}
								style={{ marginLeft: 0, maxWidth: '300px' }}
								autoComplete="on"
								data-test="password-input"
							/>
						</FormItem>
					</div>
					{loginMessage && (
						<div className="alert alert-danger" role="alert" style={{ marginLeft: '3rem', marginBottom: '3rem' }}>
							<span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true" />
							<span className="sr-only">Error:</span>
							&nbsp;{loginMessage}{' '}
						</div>
					)}

					<button type="submit" className="btn btn-primary psych-btn" style={{ marginLeft: '5rem' }} data-test="login-button">
						Login →
					</button>
				</form>
				<div className="alert alert-danger" role="alert" style={{ marginLeft: '3rem', marginTop: '3rem' }}>
					<Link to="/register/" className="alert-link" style={{ textDecoration: 'underline' }}>
						Create A New Account →
					</Link>{' '}
					or
					<Link to="/login/" onClick={resetMyPassword} className="alert-link">
						Forgot Password?
					</Link>
				</div>
			</div>
		</>
	)
}

export default Login
