import { IVipData } from '../interfaces/IVip'
import ETravelHostPreferences from '../interfaces/enums/ETravelHostPreferences'

const initialSignupValues: IVipData = {
	active: false,
	available: 0,
	expiryDate: 0,
	id: '',
	iHate: '',
	iLike: '',
	hostAndTravelPreference: ETravelHostPreferences.HostAndTravel,
	lastSeen: 0,
	likes: 0,
	workName: '',
	ethnicity: 'Black',
	mobile: '',
	suburb: '',
	city: '',
	country: 'ZA',
	tagline: '',
	eyeColour: 'Brown',
	hairColour: 'Black',
	bust: '',
	starSign: 'Pisces',
	tattoos: false,
	piercings: false,
	gender: 'Female',
	bodyDescription: 'Medium',
	age: 23,
	yearOfBirth: 1999,
	height: 1.6,
	uid: '',
	languages: ['English'],
	travelOnly: false,
	hostOnly: false,
	whatsappOnly: false,
	rating: 0,
	registered: false,
	series: '',
	signupDate: 0,
	song: '',
	whatsApp: '',
}
export default initialSignupValues
