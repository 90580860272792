import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import firebase, { firebaseAuth } from '../config/constants'
import Home from '../PhotoCards'
import '../App.scss'
import { CircularProgress } from '@material-ui/core'
import { VIPContext } from '../../context/VIPContext'
import SEO from '../common/SEO'

function Ethnicities() {
	const [allVIPs] = useContext(VIPContext)
	const [selectedVIPs, setSelectedVIPs] = useState([])
	const [numberedSuburbs, setNumberedSuburbs] = useState([])
	const [selectedSuburb, setSelectedSuburb] = useState('')

	useEffect(() => {
		activeSuburbs(allVIPs)
		const trace = firebase.performance().trace('Ethnicities')

		trace.incrementMetric('Ethnicities-View')
	}, [allVIPs])

	const activeSuburbs = (meisies) => {
		let suburbs = []
		let allSuburbs = []

		if (meisies.length) {
			meisies.map((meisie) => allSuburbs.push(meisie.data.ethnicity))
			allSuburbs.sort()

			let current = null
			let cnt = 0
			for (let i = 0; i < allSuburbs.length + 1; i++) {
				if (allSuburbs[i] !== current) {
					if (cnt > 0) {
						suburbs.push(`${current} (${cnt})`)
					}
					current = allSuburbs[i]
					cnt = 1
				} else {
					cnt++
				}
			}
			setNumberedSuburbs([...suburbs])
		}
	}

	const selectVIPs = (suburbia) => {
		let meisies = allVIPs.filter((meisie) => meisie.data.ethnicity === suburbia.split(' (')[0])

		setSelectedVIPs([...meisies])
		setSelectedSuburb(suburbia.split(' (')[0])

		firebase.analytics().logEvent('ethnicity_view', {
			category: 'Ethnicity-View',
			action: 'Ethnicity selected',
			label: suburbia.split(' (')[0],
		})
	}

	const clearVIPs = () => {
		setSelectedVIPs([])
		setSelectedSuburb('')
	}

	return (
		<>
			<SEO
				url={window.location.href}
				title={'View by Ethnicity'}
				image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'}
			/>
			<div className="App">
				{firebaseAuth().currentUser && numberedSuburbs && numberedSuburbs.length > 1 ? (
					selectedSuburb !== '' ? (
						<>
							<div className="tag" onClick={clearVIPs}>
								LIST ALL ETHNICITIES
							</div>
							<h5>{selectedSuburb}</h5>
							<Home allVIPs={selectedVIPs} />
						</>
					) : (
						<>
							<Link to="/discover">
								<div className="tag" style={{ color: '#fff' }}>
									BACK TO DISCOVER
								</div>
							</Link>
							<h5>Ethnicities</h5>
							{numberedSuburbs.map((suburbia, i) => (
								<div className="tag" key={i} onClick={() => selectVIPs(suburbia)}>
									{suburbia}
								</div>
							))}
						</>
					)
				) : (
					<div id="loader">
						<CircularProgress color="secondary" />
					</div>
				)}
			</div>
		</>
	)
}

export default Ethnicities
