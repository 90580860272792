import { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Formik } from 'formik'
import DOMPurify from 'dompurify'
import moment from 'moment'
import { Button, Select, Input, Form, InputNumber, Switch } from 'antd'
import { CircularProgress } from '@material-ui/core'
import firebase from './config/constants'
import logError from '../utils/logError'
import UploadToS3 from './common/UploadToS3'
import 'react-toastify/dist/ReactToastify.css'

import initialSignupValues from '../constants/initialSignUpValues'
import {
	johannesburg,
	pretoria,
	capeTown,
	durban,
	bloemfontein,
	portElizabeth,
	eastLondon,
	newcastle,
	secunda,
	rustenburg,
	welkom,
	bulawayo,
	harare,
	beitbridge,
	gweru,
	chitungwiza,
	ngezi,
	mutare,
	gaborone,
	daressalaam,
	nairobi,
	victoriaFalls,
} from '../constants/suburbs'
import { bodyOptions } from '../constants/bodyOptions'
import { genderOptions } from '../constants/genderOptions'
import { ethnicOptions } from '../constants/ethnicOptions'
import { languageOptions } from '../constants/languageOptions'
import { premiseOptions } from '../constants/premiseOptions'
import { parkingOptions } from '../constants/parkingOptions'
// import { venueOptions } from '../constants/venueOptions'
import ETravelHostPreferences from '../interfaces/enums/ETravelHostPreferences'

import { starSigns } from '../constants/starSigns'
import SEO from './common/SEO'

toast.configure()

const FormItem = Form.Item
const { Option } = Select
const { TextArea } = Input

const db = firebase?.firestore()

const validationSchema = Yup.object().shape({
	workName: Yup.string().required('Work name is required'),
	tagline: Yup.string().required('Quick description is required'),
	mobile: Yup.string().required('Mobile number is required'),
	suburb: Yup.string().required('Suburb is required'),
	country: Yup.string().required('Country is required'),
	city: Yup.string().required('City is required'),
	age: Yup.number().required('Age is required'),
	ethnicity: Yup.string().required('Ethnicity is required'),
	languages: Yup.array(),
	bedroomSong: Yup.string(),
	song: Yup.string(),
	series: Yup.string(),
	bust: Yup.string(),
	iHate: Yup.string(),
	iLike: Yup.string(),
	hairColour: Yup.string(),
	eyeColour: Yup.string(),
	height: Yup.number(),
	uid: Yup.string(),
	// imageUrls: Yup.array().required('Images are required')
})

class Advertise extends Component {
	state = {
		imageLoading: false,
		images: [],
		imageUrls: [],
		message: '',
		disabled: false,
		suburbLabel: true,
		zacities: [
			'Johannesburg',
			'Pretoria',
			'Cape Town',
			'Durban',
			'Port Elizabeth',
			'East London',
			'Secunda',
			'Welkom',
			'Rustenburg',
			'Bloemfontein',
		],

		zwcities: ['Bulawayo', 'Harare', 'Gweru', 'Chitungwiza', 'Ngezi', 'Beitbridge', 'Mutare', 'Victoria Falls'],

		bwcities: ['Gaborone'],
		tzcities: ['Dar es Salaam', 'Mwanza', 'Arusha', 'Zanzibar', 'Dodoma', 'Tanga', 'Mbeya', 'Morogoro'],
		kecities: ['Nairobi', 'Mombasa', 'Kisumu', 'Nakuru', 'Eldoret', 'Machakos', 'Nyeri', 'Meru'],
		timeStamp: '',
		progress: 0,
		totalProfiles: 0,
		adsLimit: 3,
		uid: '',
		vipId: '',
		arrivalTime: '',
		editing: false,
		role: 'guest',
		selectedVIP: null,
	}

	componentWillUnmount() {
		// if(!this.state.vipId)
		const departureTime = new Date().getTime()
		const duration = Math.round((departureTime - this.state.arrivalTime) / 1000)
		// this.sendSnippet(this.snippetWorkName.current, this.snippetWhatsAppNumber.current, departureTime)

		firebase.analytics().logEvent('post_advert_initiate', {
			timeOnPostAdvert: duration,
			uid: this.state.uid ?? '',
			category: 'PostAdvert',
			action: 'Initiate PostAdvert',
		})
	}

	async componentDidUpdate(prevProps, prevState) {
		const { uid, role } = this.state

		if (uid !== '' && uid !== prevState.uid) {
			this.setState({
				formValues: initialSignupValues,
				timeStamp: Date.now(),
			})

			await db
				.collection('kings')
				.doc(uid)
				.get()
				.then((doc) => {
					if (doc.exists) {
						this.setState({
							role: doc.data().role,
							adsLimit: 10000,
						})
					}
				})
				.catch((err) => {
					console.log(err)
				})

			if (role !== 'king') {
				await db
					.collection(`users/${uid}/credentials`)
					.get()
					.then((querySnapshot) => {
						querySnapshot.forEach((doc) => {
							if (doc.data().adsLimit !== undefined) {
								this.setState({
									adsLimit: doc.data().adsLimit,
								})
							}
						})
					})
					.catch((err) => {
						console.log(err)
					})

				await db
					.collection(`players/${uid}/credentials`)
					.get()
					.then((querySnapshot) => {
						querySnapshot.forEach((doc) => {
							if (doc.data().adsLimit !== undefined) {
								this.setState({
									adsLimit: doc.data().adsLimit,
								})
							}
						})
					})
					.catch((err) => {
						console.log(err)
					})
			}

			let count = 0

			await db
				.collection('users')
				.where('uid', '==', uid)
				.where('signupDate', '>', 0)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach(() => {
						count++
					})
				})
				.then(() => {
					this.setState({ totalProfiles: count })
					if (count < this.state.adsLimit) {
						db.collection('users')
							.add({
								uid,
								verified: false,
								active: false,
								created: firebase.firestore.FieldValue.serverTimestamp(),
								pics: [],
							})
							.then((data) => {
								this.setState({
									vipId: data.id,
								})
							})
							.catch((err) => {
								console.log(err)
							})
					}
				})
				.catch((err) => {
					console.log(err)
				})
		}

		if (role !== prevState.role) {
			if (this.props.match.params.vipId && role === 'king') {
				this.setState({
					editing: true,
				})

				const id = this.props.match.params.vipId

				let adDetails = {}

				const docRef = db.collection('users').doc(id)
				await docRef
					.get()
					.then((doc) => {
						if (doc.exists) {
							adDetails = doc.data()
							if (adDetails.hostAndTravelPreference === undefined) {
								if (
									(adDetails.hostOnly === undefined || adDetails.hostOnly === true) &&
									(adDetails.travelOnly === undefined || adDetails.travelOnly === true)
								) {
									adDetails.hostAndTravelPreference = ETravelHostPreferences.HostAndTravel
								} else if (adDetails.hostOnly === undefined || adDetails.travelOnly === false) {
									adDetails.hostAndTravelPreference = ETravelHostPreferences.HostAndTravel
								}
								if (adDetails.hostOnly === false || adDetails.travelOnly === undefined) {
									adDetails.hostAndTravelPreference = ETravelHostPreferences.HostAndTravel
								} else if (adDetails.travelOnly === true) {
									adDetails.hostAndTravelPreference = ETravelHostPreferences.TravelOnly
								} else if (adDetails.hostOnly === true) {
									adDetails.hostAndTravelPreference = ETravelHostPreferences.HostOnly
								}
							}
							this.setState({
								selectedVIP: adDetails,
							})

							firebase.analytics().logEvent('profile_edit', {
								category: 'Profile-Edit',
								action: `edit - ${adDetails.workName} - ${id}`,
								workName: adDetails.workName,
							})
						} else {
							firebase.analytics().logEvent('profile_edit', {
								category: 'Profile-Edit',
								action: 'No such profile',
								nonInteraction: true,
							})
						}
					})
					.catch((error) => {
						toast(error.message)
						firebase.analytics().logEvent('profile_edit', {
							category: 'Profile-Edit',
							action: 'Firebase Error',
							label: `Error getting profile: ${error}`,
							nonInteraction: true,
							vipId: id,
						})
						logError(`Error getting profile: ${error}`, 'Advertise', 'componentDidUpdate')
					})
			}
		}
	}

	async componentDidMount() {
		sessionStorage.removeItem('savedRoute')
		firebase.auth().onAuthStateChanged((authUser) => {
			if (authUser?.email) {
				this.setState({
					uid: authUser.uid,
					email: authUser.email,
				})
			} else {
				sessionStorage.setItem('savedRoute', window.location.pathname)
				this.props.history.push('/login')
			}
		})

		firebase.analytics().logEvent('post_advert', {
			category: 'PostAdvert',
			action: 'Initiate PostAdvert',
		})

		const trace = firebase.performance().trace('Advertise')
		trace.incrementMetric('PostAdvert')

		this.setState({
			arrivalTime: new Date().getTime(),
		})
	}

	disabledDate = (current) => {
		return current && current < moment().endOf('day')
	}

	disabledDateTime = () => {
		return {
			disabledMinutes: () => [],
			disabledSeconds: () => [],
		}
	}

	updateInput = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		})
	}

	handleImageChange = (e) => {
		if (e.target.files[0]) {
			this.setState({ pics: [...e.target.files] })
		}
	}

	removeImages = () => {
		this.setState({ pics: [] })
		this.setState({ imageUrls: [] })
		this.setState({ progress: 0 })
	}

	sendAdNotification = (postData, vipId) => {
		let emailList = []
		if (process.env.REACT_APP_80074_MAILLIST) {
			if (process.env.REACT_APP_80074_MAILLIST.includes(',')) {
				emailList = process.env.REACT_APP_80074_MAILLIST.split(',')
			} else {
				emailList.push(process.env.REACT_APP_80074_MAILLIST)
			}
		}
		if (emailList.length > 0) {
			try {
				db.collection('feedback')
					.add({
						to: emailList,
						message: {
							subject: 'New Advert',
							html: `Check new advert for verification:<br> 
						<b>Name:</b> ${postData.workName}<br> 
						<b>Suburb:</b> ${postData.suburb}<br> 
						<b>City:</b> ${postData.city}<br>
						<b>Advert Link:</b> https://bootyvip.com/profile/${vipId}<br>
						<b>Verify Page:</b> https://bootyvip.com/verify`,
						},
					})
					.then(() => {
						this.props.history.push('/dashboard')
					})
			} catch (error) {
				firebase.analytics().logEvent('post_feedback', {
					category: 'PostFeedback',
					action: 'Error PostFeedback: ',
					error,
				})
				logError(`Error PostFeedback: ${error}`, 'Advertise', 'sendAdNotification')
			}
		} else {
			logError(`Error PostFeedback: Empty email list`, 'Advertise', 'sendAdNotification')
		}
	}

	addVIP = (postData) => {
		postData.lastSeen = moment().utc().valueOf()

		if (postData.mobile && postData.mobile.length > 0) {
			postData.mobile = DOMPurify.sanitize(postData.mobile.replaceAll(/\s/g, ''))
		}

		// if (postData.whatsApp && postData.whatsApp.length > 0) {
		// 	postData.whatsApp = postData.whatsApp.replaceAll(/\s/g, '').replaceAll('+', '')
		// }

		postData.bust = postData.bust ? DOMPurify.sanitize(postData.bust.toUpperCase()) : ''
		postData.yearOfBirth = new Date().getFullYear() - postData.age

		if (this.state.editing) {
			postData.active = this.state.selectedVIP.active
			postData.authority = this.props.match.params.vipId
			postData.lastUpdate = this.state.timeStamp
			postData.uid = this.state.selectedVIP.uid

			let firestoreData = postData

			db.collection('users')
				.doc(this.props.match.params.vipId)
				.set(firestoreData, { merge: true })
				.then(toast('Profile updated successfully'))
				.catch((error) => {
					toast(`Error. ${error.message}`)
					logError(`Error adding VIP: ${error}`, 'Advertise', 'addVIP')
				})
		} else {
			postData.signupDate = moment().utc().valueOf()
			postData.registered = true

			postData.active = false
			postData.verified = false

			db.collection('users')
				.doc(this.state.vipId)
				.update(postData)
				.then(() => {
					this.sendAdNotification(postData, this.state.vipId)
					toast('Submitted for review. We will be in touch with you shortly.')
					toast(<>Submitted for review. We will be in touch with you shortly.</>)
					firebase.analytics().logEvent('post_advert_success', {
						category: 'PostAdvert',
						action: 'PostAdvert - Success',
					})
				})
				.catch((error) => {
					firebase.analytics().logEvent('post_advert_error', {
						category: 'PostAdvert',
						action: 'PostAdvert - Error',
						error,
					})
					toast('An error occured. Please try again.')
					logError(`Error posting Ad: ${error}`, 'Advertise', 'addVIP')
				})
		}
	}

	render() {
		const { suburbLabel, uid, totalProfiles, role, adsLimit } = this.state

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 16 },
			},
		}
		const tailFormItemLayout = {
			wrapperCol: {
				xs: {
					span: 24,
					offset: 0,
				},
				sm: {
					span: 16,
					offset: 8,
				},
			},
		}

		return role !== 'king' && totalProfiles >= adsLimit ? (
			<>
				<SEO url={window.location.href} title={'Advertise'} image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'} />
				<div className="maxedProfiles">
					Oops! You have reached your maximum number of {adsLimit} adverts. Visit the feedback page to report a problem.
				</div>
			</>
		) : uid ? (
			<>
				<SEO url={window.location.href} title={'Advertise'} image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'} />
				<h5 style={{ marginLeft: '20%' }}>Create Advert</h5>
				<h4 style={{ color: '#fff', marginBottom: '30px', textAlign: 'center' }}>
					Fill in the form below with your advert details.
					<br />
					<br />
					If you face problems you can
					<u>
						<Link to="/feedback">click here</Link>
					</u>
					to fill the advert details on our feedback form.
					<br />
					Alternatively, you can email us the details on
					<a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</a> <br />
				</h4>
				<Formik
					enableReinitialize={true}
					initialValues={this.state.selectedVIP ?? this.state.formValues}
					validationSchema={validationSchema}
					onSubmit={(values, { setSubmitting }) => {
						values.uid = uid
						this.addVIP(values)

						setTimeout(() => {
							// alert(JSON.stringify(values))
							setSubmitting(false)
						}, 500)
					}}
				>
					{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, submitForm }) => (
						<form onSubmit={handleSubmit}>
							<FormItem label="Name *" {...formItemLayout}>
								<Input
									// ref={this.snippetWorkName}
									style={{ width: 300 }}
									name="workName"
									placeholder="Enter your work name"
									type="text"
									value={values.workName}
									onChange={handleChange}
									// onChange={value => this.setState({ snippetWorkName: value })}
									onBlur={handleBlur}
									autoComplete="off"
									data-test="workname-input"
								/>
								<div className="error">{errors.workName && touched.workName && errors.workName}</div>
							</FormItem>

							<FormItem label="Cell number *" {...formItemLayout}>
								<Input
									// ref={this.snippetMobile}
									style={{ width: 300 }}
									name="mobile"
									placeholder="0820001111"
									value={values.mobile}
									onChange={handleChange}
									// autoComplete="tel"
									type="tel"
									// onChange={value => this.setState({ snippetMobile: value })}
									onBlur={handleBlur}
									autoComplete="off"
									data-test="mobile-input"
								/>
								<div className="error">{errors.mobile && touched.mobile && errors.mobile}</div>
							</FormItem>

							<FormItem label="WhatsApp number" {...formItemLayout}>
								<Input
									// ref={this.snippetWhatsAppNumber}
									style={{ width: 300 }}
									name="whatsApp"
									placeholder="082000111"
									value={values.whatsApp}
									onChange={handleChange}
									// autoComplete="tel"
									type="tel"
									// onChange={value => this.setState({ snippetMobile: value })}
									onBlur={handleBlur}
									autoComplete="off"
									data-test="whatsapp-input"
								/>
								<div className="error">{errors.whatsApp && touched.whatsApp && errors.whatsApp}</div>
							</FormItem>

							{!this.props.match.params.vipId && (
								<div style={{ margin: 'auto auto' }}>
									<FormItem label="Photos" {...formItemLayout}>
										<UploadToS3
											userId={uid}
											profileId={this.state.vipId}
											buttonLabel={'Add photos to advert'}
											currentPics={[]}
											type={'image'}
										/>
									</FormItem>

									<FormItem label="Videos" {...formItemLayout}>
										<UploadToS3
											userId={uid}
											profileId={this.state.vipId}
											buttonLabel={'Add videos to advert'}
											currentVideos={[]}
											type={'video'}
										/>
									</FormItem>
								</div>
							)}

							<FormItem label="Race *" {...formItemLayout}>
								<Select
									name="ethnicity"
									style={{ width: 300 }}
									value={values.ethnicity ? values.ethnicity : ''}
									onChange={(value) => setFieldValue('ethnicity', value)}
									autoComplete="off"
									data-test="ethnicity-input"
								>
									{ethnicOptions.map((ethnicity, index) => (
										<Option key={index} value={ethnicity}>
											{ethnicity === 'Ebony' ? 'Black' : ethnicity}
										</Option>
									))}
								</Select>
								<div className="error">{errors.ethnicity && touched.ethnicity && errors.ethnicity}</div>
							</FormItem>

							<FormItem label="Languages *" {...formItemLayout}>
								<Select
									name="languages"
									mode="multiple"
									style={{ width: 300 }}
									value={values.languages ? values.languages : ''}
									onChange={(value) => setFieldValue('languages', value)}
									autoComplete="off"
									data-test="languages-input"
								>
									{languageOptions.map((languages, index) => (
										<Option key={index} value={languages}>
											{languages}
										</Option>
									))}
								</Select>
								<div className="error">{errors.languages && touched.languages && errors.languages}</div>
							</FormItem>

							<FormItem label="Describe your offer *" {...formItemLayout}>
								<TextArea
									placeholder="I'm the hottest in Joburg!"
									value={values.tagline}
									onChange={handleChange}
									onBlur={handleBlur}
									autoSize={{ minRows: 2, maxRows: 6 }}
									style={{ width: 300 }}
									name="tagline"
									data-test="tagline-input"
								/>
								<div className="error">{errors.tagline && touched.tagline && errors.tagline}</div>
							</FormItem>

							<FormItem label="Country *" {...formItemLayout}>
								<Select
									name="country"
									onBlur={handleBlur}
									style={{ width: 300 }}
									value={values.country ? values.country : ''}
									onChange={(value) => setFieldValue('country', value)}
								>
									<Option value="ZA">South Africa</Option>
									<Option value="ZW">Zimbabwe</Option>
									<Option value="BW">Botswana</Option>
									<Option value="KE">Kenya</Option>
									<Option value="TZ">Tanzania</Option>
								</Select>
								<div className="error">{errors.country && touched.country && errors.country}</div>
							</FormItem>
							<FormItem label="City *" {...formItemLayout}>
								<Select
									name="city"
									onBlur={handleBlur}
									style={{ width: 300 }}
									value={values.city ? values.city : ''}
									onChange={(value) => setFieldValue('city', value)}
								>
									{values.country === 'ZA'
										? this.state.zacities.map((city, index) => (
												<Option key={index} value={city}>
													{city}
												</Option>
										  ))
										: values.country === 'ZW'
										? this.state.zwcities.map((city, index) => (
												<Option key={index} value={city}>
													{city}
												</Option>
										  ))
										: values.country === 'BW'
										? this.state.bwcities.map((city, index) => (
												<Option key={index} value={city}>
													{city}
												</Option>
										  ))
										: values.country === 'TZ'
										? this.state.tzcities.map((city, index) => (
												<Option key={index} value={city}>
													{city}
												</Option>
										  ))
										: values.country === 'KE'
										? this.state.kecities.map((city, index) => (
												<Option key={index} value={city}>
													{city}
												</Option>
										  ))
										: null}
								</Select>
								<div className="error">{errors.city && touched.city && errors.city}</div>
							</FormItem>

							<FormItem label={suburbLabel ? 'Suburb' : 'I travel from'} {...formItemLayout}>
								<Select
									name="suburb"
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									style={{ width: 300 }}
									value={values.suburb ? values.suburb : ''}
									onChange={(value) => setFieldValue('suburb', value)}
									onBlur={handleBlur}
									autoComplete="off"
								>
									{values.city === 'Johannesburg' ? (
										johannesburg.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Pretoria' ? (
										pretoria.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Cape Town' ? (
										capeTown.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Durban' ? (
										durban.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Bloemfontein' ? (
										bloemfontein.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Port Elizabeth' ? (
										portElizabeth.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'East London' ? (
										eastLondon.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Newcastle' ? (
										newcastle.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Secunda' ? (
										secunda.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Rustenburg' ? (
										rustenburg.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Welkom' ? (
										welkom.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Harare' ? (
										harare.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Bulawayo' ? (
										bulawayo.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Beitbridge' ? (
										beitbridge.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Gweru' ? (
										gweru.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Chitungwiza' ? (
										chitungwiza.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Mutare' ? (
										mutare.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Ngezi' ? (
										ngezi.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Nairobi' ? (
										nairobi.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Dar es Salaam' ? (
										daressalaam.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Gaborone' ? (
										gaborone.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city === 'Victoria Falls' ? (
										victoriaFalls.map((suburb, index) => (
											<Option key={index} value={suburb}>
												{suburb}
											</Option>
										))
									) : values.city ? (
										<Option value={values.city}>{values.city}</Option>
									) : null}
								</Select>
								<div className="error">{errors.suburb && touched.suburb && errors.suburb}</div>
							</FormItem>

							{suburbLabel && (
								<FormItem label="Premises" {...formItemLayout}>
									<Select
										name="premise"
										style={{ width: 300 }}
										value={values.premise ? values.premise : ''}
										onChange={(value) => setFieldValue('premise', value)}
									>
										{premiseOptions.map((type, index) => (
											<Option key={index} value={type}>
												{type}
											</Option>
										))}
									</Select>
									<div className="error">{errors.premise && touched.premise && errors.premise}</div>
								</FormItem>
							)}

							{suburbLabel && (
								<FormItem label="Parking" {...formItemLayout}>
									<Select
										name="parking"
										style={{ width: 300 }}
										value={values.parking ? values.parking : ''}
										onChange={(value) => setFieldValue('parking', value)}
									>
										{parkingOptions.map((type, index) => (
											<Option key={index} value={type}>
												{type}
											</Option>
										))}
									</Select>
									<div className="error">{errors.parking && touched.parking && errors.parking}</div>
								</FormItem>
							)}

							<FormItem label="Preference *" {...formItemLayout}>
								<Select
									name="hostAndTravelPreference"
									style={{ width: 300 }}
									value={values.hostAndTravelPreference}
									onChange={(value) => setFieldValue('hostAndTravelPreference', value)}
								>
									{Object.values(ETravelHostPreferences).map((preference, index) => (
										<Option key={index} value={preference}>
											{preference}
										</Option>
									))}
								</Select>
								<div className="error">
									{errors.hostAndTravelPreference && touched.hostAndTravelPreference && errors.hostAndTravelPreference}
								</div>
							</FormItem>

							<FormItem label="I have tattoos" {...formItemLayout}>
								<Switch name="tattoos" checked={values.tattoos} onChange={(checked) => setFieldValue('tattoos', checked)} />
								<div className="error">{errors.tattoos && touched.tattoos && errors.tattoos}</div>
							</FormItem>

							<FormItem label="I have piercings" {...formItemLayout}>
								<Switch
									name="piercings"
									checked={values.piercings}
									onChange={(value) => setFieldValue('piercings', value)}
								/>
								<div className="error">{errors.piercings && touched.piercings && errors.piercings}</div>
							</FormItem>
							<FormItem label="Age *" {...formItemLayout}>
								<InputNumber
									name="age"
									value={values.age}
									placeholder="25"
									min={21}
									max={99}
									onChange={(value) => setFieldValue('age', value)}
								/>
								<div className="error">{errors.age && touched.age && errors.age}</div>
							</FormItem>
							<FormItem label="Height (metres)" {...formItemLayout}>
								<InputNumber
									name="height"
									placeholder="1.7"
									min={1}
									max={3}
									step={0.5}
									value={values.height}
									style={{ width: 200 }}
									onChange={(value) => setFieldValue('height', value)}
								/>
								<div className="error">{errors.height && touched.height && errors.height}</div>
							</FormItem>
							<FormItem label="Gender" {...formItemLayout}>
								<Select
									name="gender"
									style={{ width: 300 }}
									value={values.gender ? values.gender : ''}
									onChange={(value) => setFieldValue('gender', value)}
								>
									{genderOptions.map((gender, index) => (
										<Option key={index} value={gender}>
											{gender}
										</Option>
									))}
								</Select>
								<div className="error">{errors.gender && touched.gender && errors.gender}</div>
							</FormItem>
							<FormItem label="Bust size" {...formItemLayout}>
								<Input
									name="bust"
									placeholder="34D"
									type="text"
									value={values.bust}
									onChange={handleChange}
									onBlur={handleBlur}
									style={{ width: 200 }}
								/>
								<div className="error">{errors.bust && touched.bust && errors.bust}</div>
							</FormItem>
							<FormItem label="Eye colour" {...formItemLayout}>
								<Input
									name="eyeColour"
									placeholder="Brown"
									type="text"
									value={values.eyeColour}
									onChange={handleChange}
									onBlur={handleBlur}
									style={{ width: 200 }}
								/>
								<div className="error">{errors.eyeColour && touched.eyeColour && errors.eyeColour}</div>
							</FormItem>
							<FormItem label="Body description *" {...formItemLayout}>
								<Select
									name="bodyDescription"
									style={{ width: 300 }}
									value={values.bodyDescription ? values.bodyDescription : ''}
									onChange={(value) => setFieldValue('bodyDescription', value)}
								>
									{bodyOptions.map((body, index) => (
										<Option key={index} value={body}>
											{body}
										</Option>
									))}
								</Select>
								<div className="error">{errors.bodyDescription && touched.bodyDescription && errors.bodyDescription}</div>
							</FormItem>
							<FormItem label="Star sign" {...formItemLayout}>
								<Select
									name="starSign"
									style={{ width: 300 }}
									value={values.starSign ? values.starSign : ''}
									onChange={(value) => setFieldValue('starSign', value)}
								>
									{starSigns.map((star, index) => (
										<Option key={index} value={star.name}>
											{star.name} - {star.dates}
										</Option>
									))}
								</Select>
								<div className="error">{errors.starSign && touched.starSign && errors.starSign}</div>
							</FormItem>
							<FormItem label="What do you dislike?" {...formItemLayout}>
								<Input
									name="iHate"
									placeholder="What do you dislike?"
									type="text"
									value={values.iHate}
									onChange={handleChange}
									onBlur={handleBlur}
									style={{ width: 300 }}
								/>
								<div className="error">{errors.iHate && touched.iHate && errors.iHate}</div>
							</FormItem>
							<FormItem label="What do you like?" {...formItemLayout}>
								<Input
									name="iLike"
									placeholder="What do you like?"
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.iLike}
									style={{ width: 300 }}
								/>
								<div className="error">{errors.iLike && touched.iLike && errors.iLike}</div>
							</FormItem>
							<FormItem label="Favourite drink" {...formItemLayout}>
								<Input
									name="drink"
									placeholder="Enter your favourite drink"
									type="text"
									value={values.drink}
									onChange={handleChange}
									onBlur={handleBlur}
									style={{ width: 300 }}
								/>
								<div className="error">{errors.drink && touched.drink && errors.drink}</div>
							</FormItem>
							<FormItem label="Bedroom song" {...formItemLayout}>
								<Input
									name="bedroomSong"
									placeholder="Your song for that moment"
									type="text"
									value={values.bedroomSong}
									onChange={handleChange}
									onBlur={handleBlur}
									style={{ width: 300 }}
								/>
								<div className="error">{errors.bedroomSong && touched.bedroomSong && errors.bedroomSong}</div>
							</FormItem>
							<FormItem label="Favourite TV series" {...formItemLayout}>
								<Input
									name="series"
									placeholder="Enter your favourite series"
									type="text"
									value={values.series}
									onChange={handleChange}
									onBlur={handleBlur}
									style={{ width: 300 }}
								/>
								<div className="error">{errors.series && touched.series && errors.series}</div>
							</FormItem>
							<FormItem label="Where did you hear about us" {...formItemLayout}>
								<Input
									name="advert"
									placeholder=""
									type="text"
									value={values.advert}
									onChange={handleChange}
									onBlur={handleBlur}
									style={{ width: 300 }}
								/>
								<div className="error">{errors.advert && touched.advert && errors.advert}</div>
							</FormItem>

							<FormItem {...tailFormItemLayout}>
								<Button type="submit" htmlType="submit" disabled={isSubmitting} size="large">
									{isSubmitting ? 'Processing' : 'Send Advert Now'}
								</Button>
								<p></p>
							</FormItem>
						</form>
					)}
				</Formik>
			</>
		) : (
			<div id="loader">
				<CircularProgress color="secondary" />
			</div>
		)
	}
}

export default withRouter(Advertise)
