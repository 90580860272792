export const starSigns: { name: string, dates: string }[] = [
	{ name: 'Aquarius', dates: '(January 20 - February 18)' },
	{ name: 'Pisces', dates: '(February 19 - March 20)' },
	{ name: 'Aries', dates: '(March 21 - April 19)' },
	{ name: 'Taurus', dates: '(April 20 - May 20)' },
	{ name: 'Gemini', dates: '(May 21 - June 20)' },
	{ name: 'Cancer', dates: '(June 21 - July 22)' },
	{ name: 'Leo', dates: '(July 23 - August 22)' },
	{ name: 'Virgo', dates: '(August 23 - September 22)' },
	{ name: 'Libra', dates: '(September 23 - October 22)' },
	{ name: 'Scorpio', dates: '(October 23 - November 21)' },
	{ name: 'Sagittarius', dates: '(November 22 - December 21)' },
	{ name: 'Capricorn', dates: '(December 22 - January 19)' },
]
