const reportWebVitals: (onPerfEntry?: (metric: any) => void) => void = (onPerfEntry) => {
	if (onPerfEntry && onPerfEntry instanceof Function) {
		import('web-vitals').then(({ onCLS, onFCP, onLCP, onTTFB }) => {
			onCLS(onPerfEntry)
			onFCP(onPerfEntry)
			onLCP(onPerfEntry)
			onTTFB(onPerfEntry)
		})
	}
}

export default reportWebVitals
