import React from 'react'

function ViewByMobileText({ totalAdverts }: { totalAdverts: number }): JSX.Element {
	return (
		<div
			style={{
				color: 'rgb(103, 104, 106)',
				textTransform: 'uppercase',
				fontWeight: 500,
				fontSize: '1.1rem',
				padding: '0 0 10px 10px',
				textAlign: 'left',
			}}
		>
			<strong>{totalAdverts}</strong> ESCORTS, VIEW BY:
		</div>
	)
}

export default ViewByMobileText
