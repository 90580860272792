import { IVip } from '../interfaces/IVip'

function shuffle(meisies: IVip[]): IVip[] {
	let ctr = meisies.length
	let temp
	let index

	while (ctr > 0) {
		index = Math.floor(Math.random() * ctr)
		ctr--
		temp = meisies[ctr]
		meisies[ctr] = meisies[index]
		meisies[index] = temp
	}
	return meisies
}

export default shuffle
