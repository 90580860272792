import React, { Component } from 'react'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Formik } from 'formik'
import { Button, Input, Form, Rate } from 'antd'

import firebase from './config/constants'
import { loginAnonymously } from './helpers/auth.js'
import 'react-toastify/dist/ReactToastify.css'
import initialValues from '../constants/initialRatingValues'

toast.configure()

const FormItem = Form.Item
const { TextArea } = Input
const db = firebase.firestore()

const validationSchema = Yup.object().shape({
	username: Yup.string().required('A username is required'),
	reviewText: Yup.string().required('Review text is required'),
	rating: Yup.number().required('Rating is required'),
})

class ReviewBlock extends Component {
	state = {
		formValues: initialValues,
		reviewText: '',
		disabled: false,
		uid: null,
		username: null,
		profileId: this.props.profileId,
		rating: 0,
		likePhotosRating: 0,
		userRating: 0,
		userLikePhotosRating: 0,
		datePosted: '',
		dateEpoch: '',
		hasUsername: true,
		showWriteReview: false,
	}

	componentDidMount() {
		if (firebase) {
			firebase.auth().onAuthStateChanged((authUser) => {
				if (authUser) {
					this.setState({
						uid: authUser.uid,
					})
					this.updateUsername()
				} else {
					loginAnonymously()
				}
			})
		}

		this.setState({
			formValues: initialValues,
		})
	}

	updateInput = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		})
	}

	updateUsername = () => {
		if (this.state.username === null && this.state.uid !== null) {
			db.collection('players')
				.doc(this.state.uid)
				.get()
				.then((doc) => {
					if (doc.exists) {
						// console.log('doc exists', doc.data())
						if (doc.data().username) {
							this.setState({ username: doc.data().username, hasUsername: true })
							this.setState({ formValues: { ...this.state.formValues, username: doc.data().username } })
						} else {
							this.setState({ username: null, hasUsername: false })
						}
					}
				})
				.catch((error) => {
					console.error('Error updating document: ', error)
				})
		}
	}

	addRating = (postData) => {
		// console.log('Values', postData)

		this.setState({
			datePosted: new Date().toISOString(),
			dateEpoch: Date.now(),
		})

		if (this.state.username) {
			db.collection('players').doc(this.state.uid).set(
				{
					username: this.state.username,
				},
				{ merge: true }
			)
		}

		// this.setState({ reviewText: postData.reviewText, rating: postData.rating })

		if (this.state.profileId && this.state.uid) {
			db.collection('players')
				.doc(this.state.uid)
				.collection('ratings')
				.doc(this.state.profileId)
				.get()
				.then((doc) => {
					if (doc.exists) {
						if (doc.data().rating > 0) {
							// console.log('doc.data().rating', doc.data().rating)
							this.setState({ userRating: doc.data().rating })
						}
						if (doc.data().likePhotosRating > 0) {
							// console.log('doc.data().likePhotosRating', doc.data().likePhotosRating)
							this.setState({ userLikePhotosRating: doc.data().likePhotosRating })
						}
					}
				})
				.then(() => {
					this.ratingCompleted()
				})
				.catch((error) => {
					// The document probably doesn't exist.
					console.error('Error updating document: ', error)
				})
		}
	}

	ratingCompleted = () => {
		this.handleUserRating(this.state.rating, this.state.likePhotosRating)
	}

	handleUserRating = (ratingValue, likePhotosRatingValue) => {
		const userReviewsRef = db.collection('players').doc(this.state.uid).collection('reviews').doc(this.state.profileId)
		const userRatingRef = db.collection('players').doc(this.state.uid).collection('ratings').doc(this.state.profileId)

		if (ratingValue) {
			userReviewsRef
				.set(
					{
						[this.state.dateEpoch]: {
							rating: ratingValue,
							likePhotosRating: likePhotosRatingValue,
							reviewText: this.state.reviewText,
							dateEpoch: this.state.dateEpoch,
							datePosted: this.state.datePosted,
						},
					},
					{ merge: true }
				)
				.then(() => {
					userRatingRef.set(
						{
							rating: likePhotosRatingValue,
							likePhotosRating: likePhotosRatingValue,
							reviewText: this.state.reviewText,
							dateEpoch: this.state.dateEpoch,
							datePosted: this.state.datePosted,
						},
						{ merge: true }
					)
				})
				.then(() => this.handleAdRating(ratingValue, likePhotosRatingValue))
				.catch((error) => {
					// The document probably doesn't exist.
					console.error('Error updating document: ', error)
				})
		}
	}

	handleAdRating = (ratingValue, likePhotosRatingValue) => {
		const adRatingRef = db.collection('users').doc(this.state.profileId)

		if (ratingValue) {
			if (this.state.userRating) {
				const difference = ratingValue - this.state.userRating
				// console.log('ratingValue', ratingValue)
				// console.log('this.state.userRating', this.state.userRating)
				// console.log('difference', difference)

				adRatingRef
					.collection('rating')
					.doc('rateStats')
					.set(
						{
							sum: firebase.firestore.FieldValue.increment(difference),
						},
						{ merge: true }
					)
					.catch((error) => {
						// The document probably doesn't exist.
						console.error('Error updating document: ', error)
					})
			} else {
				// console.log('ratingValue', ratingValue)

				adRatingRef
					.collection('rating')
					.doc('rateStats')
					.set(
						{
							sum: firebase.firestore.FieldValue.increment(ratingValue),
							rateCount: firebase.firestore.FieldValue.increment(1),
						},
						{ merge: true }
					)
					.catch((error) => {
						console.error('Error updating document: ', error)
					})
			}

			if (this.state.userLikePhotosRating) {
				const difference = likePhotosRatingValue - this.state.userLikePhotosRating
				adRatingRef
					.collection('likePhotosRating')
					.doc('rateStats')
					.set(
						{
							sum: firebase.firestore.FieldValue.increment(difference),
						},
						{ merge: true }
					)
					.catch((error) => {
						// The document probably doesn't exist.
						console.error('Error updating document: ', error)
					})
			} else {
				adRatingRef
					.collection('likePhotosRating')
					.doc('rateStats')
					.set(
						{
							sum: firebase.firestore.FieldValue.increment(likePhotosRatingValue),
							rateCount: firebase.firestore.FieldValue.increment(1),
						},
						{ merge: true }
					)
					.catch((error) => {
						console.error('Error updating document: ', error)
					})
			}
		}

		adRatingRef
			.collection('allRatings')
			.doc()
			.set(
				{
					uid: this.state.uid,
					rating: ratingValue,
					likePhotosRating: likePhotosRatingValue,
					dateEpoch: this.state.dateEpoch,
					datePosted: this.state.datePosted,
					username: this.state.username,
					reviewText: this.state.reviewText,
					status: 'live',
				},
				{ merge: true }
			)
			.then(() => {
				this.setState({ showWriteReview: false })
			})
			.catch((error) => {
				// The document probably doesn't exist.
				console.error('Error updating document: ', error)
			})

		db.collection('feedback')
			.add({
				to: process.env.REACT_APP_80074_MAILLIST,
				message: {
					subject: 'VIP Advert Review',
					html: `<b>Work Name</b> ${this.props.workName}<br>
					<b>Review text</b> ${this.state.reviewText}<br>
					<b>Reviewer</b> ${this.state.username}<br>
					<b>Link</b> ${`https://bootyvip.com/profile/${this.state.profileId}`}<br>
					`,
				},
			})
			.catch((error) => {
				toast('Error occurred.')
			})
	}

	render() {
		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 16 },
			},
		}
		const tailFormItemLayout = {
			wrapperCol: {
				xs: {
					span: 24,
					offset: 0,
				},
				sm: {
					span: 16,
					offset: 8,
				},
			},
		}

		return (
			this.state.uid && (
				<>
					<Formik
						enableReinitialize={true}
						initialValues={this.state.formValues}
						validationSchema={validationSchema}
						onSubmit={(values, { setSubmitting }) => {
							values.uid = this.state.uid
							values.profileId = this.state.profileId
							values.rating = this.state.rating
							values.likePhotosRating = this.state.likePhotosRating
							if (this.state.reviewText.length < 101) {
								setSubmitting(false)
								toast('Review text needs to be at least 100 characters.')
							} else if (this.state.likePhotosRating === 0 || this.state.rating === 0) {
								setSubmitting(false)
								toast('Please star-rate the ad before submitting.')
							} else {
								this.addRating(values)
								setTimeout(() => {
									setSubmitting(false)
								}, 500)
							}
						}}
					>
						{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, submitForm }) =>
							!this.state.showWriteReview ? (
								<Button
									type="button"
									htmlType="button"
									color="success"
									style={{ marginLeft: '20px', fontWeight: 'bold' }}
									onClick={() => this.setState({ showWriteReview: !this.state.showWriteReview })}
								>
									Write a review
								</Button>
							) : (
								<form onSubmit={handleSubmit}>
									<h3 style={{ color: '#ee1e3a', marginBottom: '30px', textAlign: 'left' }}>WRITE A REVIEW</h3>
									{this.state.hasUsername ? (
										<FormItem label="">
											<Input
												// style={{ width: 300 }}
												name="username"
												placeholder="Create a username"
												type="hidden"
												value={values.username}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</FormItem>
									) : (
										<FormItem label="Your username *" {...formItemLayout}>
											<Input
												// style={{ width: 300 }}
												name="username"
												placeholder="Create a username"
												type="text"
												value={values.username}
												onChange={(e) => {
													handleChange(e)
													this.setState({ username: e.target.value })
												}}
												onBlur={handleBlur}
											/>
											<div className="error">{errors.username && touched.username && errors.username}</div>
										</FormItem>
									)}

									<FormItem label="Tell us your experience *" {...formItemLayout}>
										<TextArea
											name="reviewText"
											placeholder=""
											value={values.reviewText}
											onChange={(e) => {
												handleChange(e)
												this.setState({ reviewText: e.target.value })
											}}
											onBlur={handleBlur}
											autoSize={{ minRows: 2, maxRows: 6 }}
											style={{ width: 300 }}
											maxLength={1000}
										/>
										<div className="error">{errors.reviewText && touched.reviewText && errors.reviewText}</div>
									</FormItem>
									<FormItem label="The experience" {...formItemLayout}>
										<Rate name="rating" onChange={(value) => this.setState({ rating: value })} />
										<div className="error">{errors.rating && touched.rating && errors.rating}</div>
									</FormItem>
									<FormItem label="Looks like the photos" {...formItemLayout}>
										<Rate name="likePhotosRating" onChange={(value) => this.setState({ likePhotosRating: value })} />
										<div className="error">
											{errors.likePhotosRating && touched.likePhotosRating && errors.likePhotosRating}
										</div>
									</FormItem>
									<FormItem {...tailFormItemLayout}>
										<Button type="primary" htmlType="submit" disabled={isSubmitting}>
											{isSubmitting ? 'Processing' : 'Send my review'}
										</Button>{' '}
										<Button
											type="button"
											disabled={isSubmitting}
											htmlType="button"
											onClick={() => this.setState({ showWriteReview: !this.state.showWriteReview })}
										>
											Cancel
										</Button>
									</FormItem>
								</form>
							)
						}
					</Formik>
				</>
			)
		)
	}
}
export default ReviewBlock
