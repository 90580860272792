import React, { useEffect } from 'react'
import firebase from './config/constants'
import SEO from './common/SEO'

function PrivacyPolicy(): JSX.Element {
	useEffect(() => {
		firebase.analytics().logEvent('privacy_policy')
		const trace = firebase.performance().trace('PrivacyPolicy')
		trace.incrementMetric('PrivacyPolicy')
	}, [])

	return (
		<>
			<SEO url={window.location.href}  title={'Privacy Policy'} image={'https://80074.s3.eu-west-2.amazonaws.com/static/favicon.png'} />
			<div style={{ margin: '0vw 10vw' }}>
				<h1>Privacy Policy</h1>
				<p>
					<strong>Last updated:</strong> February 20, 2022
				</p>
				<p>
					BootyVIP ("us", "we", or "our") operates www.{window.location.hostname} (the "Site(s)"). This page informs you of our
					policies regarding the collection, use and disclosure of Personal Information we receive from users of these Sites.
				</p>
				<p>
					We use your Personal Information only for providing and improving the Sites. By using the Site, you agree to the
					collection and use of information in accordance with this policy.
				</p>
				<p>
					<strong>Information Collection And Use</strong>
				</p>
				<p>
					While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to
					contact or identify you. Personally identifiable information may include, but is not limited to your email ("Personal
					Information").
				</p>
				<p>
					<strong>Log Data</strong>
				</p>
				<p>Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data").</p>
				<p>
					This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser
					version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other
					statistics.
				</p>
				<p>In addition, we may use third party services such as Google Analytics that collect, monitor and analyse this data.</p>
				<p>
					<strong>Communications</strong>
				</p>
				<p>
					We may use your Personal Information to provide you with a customised experience and to develop and promote our products
					and services. Personal information is not shared with third parties.
				</p>
				<p>
					<strong>Cookies</strong>
				</p>
				<p>
					Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your
					browser from a web site and stored on your computer's hard drive.
				</p>
				<p>
					Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to
					indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of
					our Site.
				</p>
				<p>
					<strong>Security</strong>
				</p>
				<p>
					The security of your Personal Information is important to us, but remember that no method of transmission over the
					Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to
					protect your Personal Information, we cannot guarantee its absolute security.
				</p>
				<p>
					<strong>Changes To This Privacy Policy</strong>
				</p>
				<p>
					This Privacy Policy is effective as of January 1, 2021 and will remain in effect except with respect to any changes in
					its provisions in the future, which will be in effect immediately after being posted on this page.
				</p>
				<p>
					We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy
					periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will
					constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy
					Policy.
				</p>
				<p>We reserve the right to change our Privacy Policy at any time by posting a new Privacy Policy on our website.</p>
				<p>
					<strong>Contact Us</strong>
				</p>
				<p>
					If you have any questions about this Privacy Policy, please{' '}
					<a href={`${process.env.PUBLIC_URL}/feedback`}>contact us</a>.
				</p>
				<p>&nbsp;</p>
			</div>
		</>
	)
}

export default PrivacyPolicy
