import { createContext, useState, useEffect } from 'react'

export const CurrentCityContext = createContext(null)

export const CurrentCityContextProvider = (props) => {
	const [city, setCity] = useState(null)

	useEffect(() => {
		if (props && props.city) {
			setCity(props.city)
		}
	}, [props, props.city])

	return <CurrentCityContext.Provider value={[city, setCity]}>{props.children}</CurrentCityContext.Provider>
}
