import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IVipData } from '../interfaces/IVip'
import ETravelHostPreferences from '../interfaces/enums/ETravelHostPreferences'

interface IPhotoProps {
	roster?: boolean;
	id: string;
	key: number;
	data: IVipData;
}

function Photo(props: IPhotoProps): JSX.Element {
	const [isFavourite, setIsFavourite] = useState<boolean>(false)

	const { pics } = props?.data
	const { roster } = props

	useEffect(() => {
		if (props.id) {
			const storedFavs = localStorage.getItem('favourites')
			if (storedFavs && storedFavs.length > 0 && JSON.parse(storedFavs)?.includes(props.id)) {
				setIsFavourite(true)
			}
		}
	}, [props.id])

	return (
		<div className="photoComponent">
			<Link to={`/profile/${props.id}`} className="highpic">
				{pics && pics[0] ? (
					<img
						src={
							pics[pics.length - 1].includes('static')
								? process.env.REACT_APP_S3_BASE_URL + pics[pics.length - 1]
								: pics[pics.length - 1]
						}
						alt={`BootyVIP - ${props.data.workName}`}
						width="310px"
						loading="lazy"
					/>
				) : (
					<img
						src={`${process.env.REACT_APP_S3_BASE_URL}static/profiles/images/person.png`}
						alt={`BootyVIP - ${props.data.workName}`}
						width="310px"
						height="310px"
						loading="lazy"
					/>
				)}

				<div className="image-gradient-layer" />

				<div className="meta">
					<p className="performer">{props.data.workName}</p>
					{props.roster && <p className="mobile">{props.data.mobile.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')}</p>}
					<p className="location" id="location">
						{props.data.travelOnly || props.data.hostAndTravelPreference === ETravelHostPreferences.TravelOnly
							? `Travel Only - ${props.data.city}`
							: props.data.suburb}
					</p>
				</div>

				<span className="vip-footer">
					{props.data.videoUrls && props.data.videoUrls.length > 0 && (
						<img
							src={`${process.env.REACT_APP_S3_BASE_URL}static/icons/icon-video-camera.svg`}
							alt="video inside"
							loading="lazy"
						/>
					)}
					{isFavourite && !roster && (
						<img src={`${process.env.REACT_APP_S3_BASE_URL}static/icons/icon-like.svg`} alt="favourite" loading="lazy" />
					)}
				</span>
			</Link>
		</div>
	)
}

export default Photo
